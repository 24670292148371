import '../Components/ContactPage.css'

function ContactPage() {

  return (
    <div className="container pt-md-4 pb-5 pt-5 px-3 px-md-4 px-lg-5 p-sm-5">
      <h2 className='fs-3 custom-font-bold'>Contact Us</h2>
    <div className="row custom-bg">
      <div className="col-md-6">
        <div className="location-details">
          <h2 className="add-head">Ticketistan – Corporate Office</h2>
          <p>Islamabad, Pakistan ( Corporate Head Office) </p>
          <p>address: Office No. 3070A, World Trade Center, Giga Mall, Sector F DHA Phase II, Islamabad</p>
          <p>Islamabad,Pakistan.</p>
          <p>For any assistance please contact us 24/7 Customer Support Service </p>
          <p className="phone-no-con"> UAN Number <a href="tel:03041110636" className='aclr'> 0304 111 0636</a></p>
          <p className="phone-no-con"> WhatsApp us at <a href="https://wa.me/923041110636" target='blank' className='aclr'> +92 304 111 0636</a></p>
        </div>
      </div>
      
      <div className="col-md-6">
        <div className="mapouter">
          <div className="gmap_canvas">
          <iframe
        title="Google Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.725762146541!2d73.15498451521065!3d33.52122868075444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed58ee7c290d%3A0xa75fb5607ad954fb!2sTicketistan!5e0!3m2!1sen!2s!4v1685973850733!5m2!1sen!2s"
        width="100%"
        height="300"
        allowFullScreen=""
        loading="lazy"
      ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>  
  );
}

export default ContactPage;
