import React, { useState } from 'react';
import { Container } from "react-bootstrap";

import '../assets/css/events.css'
import MobileApp from '../Components/MobileApp';
  // import 'bootstrap/dist/css/bootstrap.min.css';
  // import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Events = () => {
  // const events = [
  //   {
  //     id: 504,
  //     image: '',
  //     title: 'Islamabad Night Race',
  //     location: 'Trail 5 Parking, Islamabad',
  //     date: 'Sat, 25 May 2024',
  //     description: `Night race (5km and 10km) followed by a musical night and food stalls. Time: 8:30 PM. Please note that the tickets are only for those who will be taking part in the race, either 5km or 10km. Also note that the participants need to fill the following registration form: `,
  //     registrationLink: 'https://forms.gle/rVnwhKU5yfdZH7Pi9',
  //     tickets: [
  //       { name: 'T-Shirt (Male) S', price: 2500, id: 181126 },
  //       { name: 'T-Shirt (Male) M', price: 2500, id: 181127 },
  //       { name: 'T-Shirt (Male) L', price: 2500, id: 181128 },
  //       { name: 'T-Shirt (Male) XL', price: 2500, id: 181129 },
  //       { name: 'T-Shirt (Female) XS', price: 2500, id: 181130 },
  //       { name: 'T-Shirt (Female) S', price: 2500, id: 181131 },
  //       { name: 'T-Shirt (Female) M', price: 2500, id: 181132 },
  //       { name: 'T-Shirt (Female) L', price: 2500, id: 181133 },
  //       { name: 'T-Shirt (Female) XL', price: 2500, id: 181134 },
  //       { name: '5 KM Race', price: 3500, id: 181559 },
  //       { name: '10 KM Race', price: 3500, id: 181560 }
  //     ]
  //   },
  //   {
  //     id: 512,
  //     image: '',
  //     title: 'Rawalpindi to Attock Safari Tourist Train',
  //     location: 'Rawalpindi',
  //     date: 'Sun, 18 April 2024',
  //     description: 'Enjoy a scenic train journey from Rawalpindi to Attock with beautiful views and a delightful experience.',
  //     registrationLink: '',
  //     tickets: [
  //       { name: 'Economy Class', price: 1500, id: 181201 },
  //       { name: 'Business Class', price: 2500, id: 181202 },
  //     ]
  //   },
  //   {
  //     id: 559,
  //     image: '',
  //     title: 'Kaavish - Live in Concert',
  //     location: 'Lahore',
  //     date: 'Tue, 14 May 2024',
  //     description: 'Experience the mesmerizing live performance by Kaavish in Lahore.',
  //     registrationLink: '',
  //     tickets: [
  //       { name: 'General Admission', price: 3000, id: 181301 },
  //       { name: 'VIP Admission', price: 5000, id: 181302 },
  //     ]
  //   },
  //   {
  //     id: 267,
  //     image: '',
  //     title: 'Scuba Diving',
  //     location: 'Karachi',
  //     date: 'Various Dates',
  //     description: 'Dive into the depths of the ocean and explore the underwater world with our Scuba Diving event in Karachi.',
  //     registrationLink: '',
  //     tickets: [
  //       { name: 'Single Dive', price: 4000, id: 181401 },
  //       { name: 'Double Dive', price: 7000, id: 181402 },
  //     ]
  //   },
  //   {
  //     id: 266,
  //     image: '',
  //     title: 'Paragliding in Pakistan',
  //     location: 'Pir Chinasi',
  //     date: 'Various Dates',
  //     description: 'Experience the thrill of paragliding over the scenic landscapes of Pakistan.',
  //     registrationLink: '',
  //     tickets: [
  //       { name: 'Single Flight', price: 5000, id: 181501 },
  //       { name: 'Double Flight', price: 9000, id: 181502 },
  //     ]
  //   },
  //   {
  //     id: 255,
  //     image: '',
  //     title: 'Malam Jabba Chair Lift, Zipline',
  //     location: 'Malam Jabba',
  //     date: 'Various Dates',
  //     description: 'Enjoy the scenic chair lift and exhilarating zipline at Malam Jabba.',
  //     registrationLink: '',
  //     tickets: [
  //       { name: 'Chair Lift Ticket', price: 1500, id: 181601 },
  //       { name: 'Zipline Ticket', price: 2500, id: 181602 },
  //     ]
  //   },
  // ];

  // const [selectedEvent, setSelectedEvent] = useState(null);
  // const [searchTerm, setSearchTerm] = useState('');
  // const [ticketCounts, setTicketCounts] = useState({});

  // const handleCardClick = (event) => {
  //   setSelectedEvent(event);
  //   setTicketCounts({});
  // };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleTicketCountChange = (ticketId, delta) => {
  //   setTicketCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [ticketId]: Math.max(0, (prevCounts[ticketId] || 0) + delta),
  //   }));
  // };

  // const calculateTotal = () => {
  //   if (!selectedEvent) return 0;
  //   return selectedEvent.tickets.reduce((total, ticket) => {
  //     const count = ticketCounts[ticket.id] || 0;
  //     return total + count * ticket.price;
  //   }, 0);
  // };

  // const filteredEvents = events.filter((event) =>
  //   event.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  return (
    <>

<Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundColor: "#282c34",
        color: "white",
        fontSize: "3rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          animation: "pulse 2s infinite",
          margin: 0,
        }}
      >
        Launching Soon
      </h1>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.05);
              opacity: 0.8;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          h1 {
            animation: pulse 2s infinite;
          }
        `}
      </style>
    </Container>
      {/* <section >
        <div className="container mb-3 px-3 px-md-4 px-lg-5">
          <div className="row g-2 align-items-center">
          <div className="event_bg">
            
          </div>
          </div>
          </div>
          </section>

      <section className="event-listing-section">
        <div className="container pt-4 mb-3 px-3 px-md-4 px-lg-5">
          <div className="row g-2 align-items-center">
            <div className="col-md-6">
              <h1 className="h2 font-weight-600 mb-3">Events</h1>
              <p className="lead h5 font-weight-400">Book the ticket of ongoing events</p>
            </div>
            <div className="col-md-6">
              <div className="row g-2">
                <div className="col-md-4 col-6">
                  <select className="form-control form-select" name="city" id="city">
                    <option value="0">All</option>
                    <option value="1">Lahore</option>
                    <option value="2">Karachi</option>
                    <option value="3">Islamabad</option>
                    <option value="11">Rawalpindi</option>
                    <option value="48">Pir Chinasi</option>
                    <option value="55">Malam Jabba</option>
                  </select>
                </div>
                <div className="col-md-8 col-6">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {filteredEvents.map((event, index) => (
              <div className="col-md-4" key={index} id={event.id}>
                <div className="card event-item rounded-0 border-0 text-reset">
                  <div className="card-img rounded-sm">
                    <img
                      src={event.image}
                      className="card-img-top img-hover-zoom rounded-sm"
                      alt={event.title}
                    />
                  </div>
                  <div className="card-body px-0">
                    <a
                      className="stretched-link"
                      href="#"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#sidePanel"
                      onClick={() => handleCardClick(event)}
                    >
                      <h5 className="font-weight-500">{event.title}</h5>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="offcanvas offcanvas-end p-0"
            tabIndex="-1"
            id="sidePanel"
            aria-labelledby="sidePanelLabel"
          >
            <div className="offcanvas-header p-lg-4 p-3 border-bottom">
              <h5 className="offcanvas-title font-weight-600" id="sidePanelLabel">
                Event Preview
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body bg-gray-100 p-lg-4 p-3 pb-0 pb-lg-0">
              {selectedEvent && (
                <>
                  <div className="card p-3 my-3">
                    <div className="row g-3">
                      <div className="col-md-3">
                        <img
                          className="card-img-top img-hover-zoom rounded-sm"
                          alt={selectedEvent.title}
                          src={selectedEvent.image}
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="card-body py-0 px-0">
                          <div className="d-flex justify-content-between">
                            <h1 className="h5 font-weight-600 mb-3 me-3">{selectedEvent.title}</h1>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="text-dark font-weight-600 mb-2">{selectedEvent.location}</h6>
                          </div>
                          <p className="text-dark mb-1">Date: {selectedEvent.date}</p>
                          <p className="text-dark mb-0">
                            Note: {selectedEvent.description} <a href={selectedEvent.registrationLink} target="_blank" rel="noopener noreferrer">{selectedEvent.registrationLink}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 px-lg-4 px-3 shadow-sm my-3">
                    <h5 className="font-weight-600 mb-3">Ticket Details</h5>
                    <div className="row g-3 align-items-center">
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="date">Date</label>
                        <select className="form-control form-select" id="date" required="">
                          <option value="4142">25 May, 2024</option>
                        </select>
                      </div>
                      {selectedEvent.tickets.map((ticket, index) => (
                        <div className="row gx-2 my-3" key={index}>
                          <div className="col-5">
                            <label>
                              <strong className="text-dark">{ticket.name}</strong>
                              <p className="mb-0"> Rs {ticket.price}</p>
                              <input type="hidden" value={ticket.id} />
                            </label>
                          </div>
                          <div className="col-7 d-flex align-items-start justify-content-end">
                            <button
                              className="value-button btn decrease"
                              onClick={() => handleTicketCountChange(ticket.id, -1)}
                            >
                              <i className="fa fa-minus text-gray-500 fa-md"></i>
                            </button>
                            <input
                              type="number"
                              id="number"
                              value={ticketCounts[ticket.id] || 0}
                              readOnly
                            />
                            <button
                              className="value-button btn increase"
                              onClick={() => handleTicketCountChange(ticket.id, 1)}
                            >
                              <i className="fa fa-plus text-gray-500 fa-md"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card p-3 px-lg-4 px-3 shadow-sm my-3">
                    <h5 className="font-weight-600 mb-3">Event Details</h5>
                    <p>{selectedEvent.description}</p>
                  </div>
                  <div className="alert cancellation-policy my-3">
                    <div className="d-flex flex-column flex-md-row align-items-start">
                      <span className="me-2 mb-2 mb-md-0">Cancellation Policy</span>
                      <p className="mb-0"> Cancellation Policy </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row align-items-center bg-white mx-0 p-3 border-top sticky-bottom">
              <div className="col-6">
                <p className="text-muted mb-0">Total</p>
                <h5 className="text-primary font-weight-600 fs-4 mb-0">Rs {calculateTotal()}</h5>
              </div>
              <div className="col-6 text-end">
                <button className="btn btn-primary">Continue</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-route-section">
      <div className="container py-4 px-3 px-md-4 px-lg-5">
        <div className="border-top border-bottom">
          <div className="accordion accordion-flush" id="route">
            <div className="accordion-item">
              <h2 className="accordion-header" id="route-headingOne">
                <button
                  className="accordion-button px-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#route-collapseOne"
                  aria-expanded="true"
                  aria-controls="route-collapseOne"
                >
                  <h4 className="font-weight-600 text-capitalize">Bookme Event Services</h4>
                </button>
              </h2>
              <div
                id="route-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="route-headingOne"
                data-bs-parent="#route"
              >
                <div className="accordion-body px-0">
                  <ul className="row gy-3 list-unstyled">
                    <li className="col-6 col-md-4 col-lg-3">
                      <a href="/events-in-lahore" className="text-primary">Events in Lahore</a>
                    </li>
                    <li className="col-6 col-md-4 col-lg-3">
                      <a href="/events-in-karachi" className="text-primary">Events in Karachi</a>
                    </li>
                    <li className="col-6 col-md-4 col-lg-3">
                      <a href="/events-in-islamabad" className="text-primary">Events in Islamabad</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <MobileApp/>

      <section className="home-content-section">
      <div className="container py-4 px-3 px-md-4 px-lg-5">
        <hr />
        <h1 className="h5 font-weight-600">Book Events Tickets online Through Ticketistan</h1>
        <p>
          Wo  May you never be tired and welcome to Ticketestan.com for non-stop destination for events booking tickets? What you wish, what you will, what you desire and what not, come on and take entry / browsing for moreconvenient way of tickets booking. Round the clock services offers ample ofopportunities for upcoming events get one and enjoy your trip hassle-free. Allin one place and make it a memory for life.
        </p>
        <hr />
        <h2 className="h5 font-weight-600">Method of Payment to Get Online Event Tickets</h2>
        <p> We offer following simple method of payment:</p>
        <ul>
          <li>Jazz Cash</li>
          <li>Easy Paisa</li>
          <li>Credit/Debit Cards</li>
        </ul>
        <hr />
        <h2 className="h5 font-weight-600">How to Book Your Event Ticket Online Through Ticketestan?</h2>
        <ol>
          <li>Go to web / mobile app.</li>
          <li>Select your choice of event.</li>
          <li>Number of tickets you require.</li>
          <li>Provide contact / personal information</li>
          <li>Your method of payment.</li>
          <li>Confirm booking by clicking only.</li>
        </ol>
      </div>
    </section> */}
    </>
  );
};

export default Events;
