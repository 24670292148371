// FlightOffersSection.js
import React from 'react';
import FlightCard from './FlightCard';
// import './FlightOffersSection.css';

const FlightOffersSection = ({ flightOffers }) => {
  return (
    <>
    <section className="flight-offers-section">
      {flightOffers.length > 0 && (
        <div className="mt-4">
          <h2 className='container px-3 px-md-4 px-lg-5'>Flight Offers</h2>
          <div className="list-group">
            {flightOffers.map((offer) => (
              <FlightCard key={offer.id} flightOffer={offer} />
            ))}
          </div>
        </div>
      )}
    </section>
    </>
  );
};

export default FlightOffersSection;
