import React, { useState } from 'react';
import "../assets/css/tour.css";
import MobileApp from '../Components/MobileApp';
import BennerImage from "../assets/imgs/tourImages/hunzabanner.png";
import { Container } from "react-bootstrap";

const Tours = () => {
  // const allTours = [
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/4daykashmir.jpg',
  //     title: '4 Days Kashmir (Arang Kel Special)',
  //     duration: '4 Days & 3 Nights',
  //     price: 'Rs 24,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/6dayhunzanultar.jpg',
  //     title: '6 Days Trip to Hunza & Naltar Valley',
  //     duration: '6 Days & 5 Nights',
  //     price: 'Rs 33,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/6daysskardu.jpg',
  //     title: '6 Days Trip to Majestic Skardu Valley',
  //     duration: '6 Days & 5 Nights',
  //     price: 'Rs 36,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/8dayshunzaskardu.jpg',
  //     title: '8 Days Trip to Hunza & Skardu Valley',
  //     duration: '8 Days & 7 Nights',
  //     price: 'Rs 47,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/swatkalam-global_normal-281223.png?v3',
  //     title: '3 Days Trip to Swat Kalam',
  //     duration: '3 Days & 2 Nights',
  //     price: 'Rs 19,500',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/5daysskardutournew_cus12-1113-1.png?v3',
  //     title: 'Customized By Air Tour For 05 Days Hunza (02-04 PX)',
  //     duration: '5 Days & 4 Nights',
  //     price: 'Rs 375,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/3dayneelamvalley2-1113.png?v3',
  //     title: '03 Days Trip to Neelum - Arang Kel',
  //     duration: '3 Days & 2 Nights',
  //     price: 'Rs 19,500',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/7dayssakurdu-new2-1113.png?v3',
  //     title: '07 Days Tour to Skardu,Khaplu, Basho, Shangrila & Shiger',
  //     duration: '7 Days & 6 Nights',
  //     price: 'Rs 35,500',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/8dayshunza_new2-1113.png?v3',
  //     title: '08 Days Tour to Skardu,Khaplu, Basho, Shangrila & Shiger',
  //     duration: '8 Days & 7 Nights',
  //     price: 'Rs 39,500',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/5daysskardutournew_cus12-281223-1.png?v3',
  //     title: 'Customized By Air Tour For 05 Days Skardu (02-04 PX)',
  //     duration: '5 Days & 4 Nights',
  //     price: 'Rs 375,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/9daysskarduhunzatournew_cus12-1113-1.png?v3',
  //     title: 'Customized By Air Tour For 09 Days Skardu Hunza (02-04 PX)',
  //     duration: '9 Days & 8 Nights',
  //     price: 'Rs 540,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/5dayshunzanewattabad2-1113.png?v3',
  //     title: '05 Days Tour to Beautiful Naran Hunza Valley, Attabad Lake and Khunjerab Pass',
  //     duration: '5 Days & 4 Nights',
  //     price: 'Rs 29,500',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/5dayshunzanewcouple2-1113.png?v3',
  //     title: '06 Days Group Tour Only For Couples Premium Package',
  //     duration: '6 Days & 5 Nights',
  //     price: 'Rs 179,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/5dayshunzanewcoupleexect2-1113.png?v3',
  //     title: '06 Days Group Tour Only For Couples Executive Package',
  //     duration: '6 Days & 5 Nights',
  //     price: 'Rs 209,000',
  //     category: 'Pakistan Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/sc-cruise1.jpg',
  //     title: 'Dhow cruise dinner Dubai Creek',
  //     duration: '1 Days & 0 Nights',
  //     price: 'Rs 12,750',
  //     category: 'International Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/halfdaycitytour1.jpg',
  //     title: 'Half Day Dubai City Tour',
  //     duration: '1 Days & 0 Nights',
  //     price: 'Rs 12,750',
  //     category: 'International Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/Bab-al-shams1.png',
  //     title: 'Desert Safari with Bab Al Shams Dinner Dubai',
  //     duration: '1 Days & 0 Nights',
  //     price: 'Rs 76,500',
  //     category: 'International Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/staycationdesrt1.jpg',
  //     title: 'Evening Desert Safari Dubai',
  //     duration: '1 Days & 0 Nights',
  //     price: 'Rs 15,300',
  //     category: 'International Tour'
  //   },
  //   {
  //     image: 'https://storage.googleapis.com/bookmepk/static/custom/upload/tours/V5/staycationdesrt1.jpg',
  //     title: 'Evening Desert Safari Dubai',
  //     duration: '1 Days & 0 Nights',
  //     price: 'Rs 15,300',
  //     category: 'Religious Tour'
  //   },
  // ];

  // const [selectedTour, setSelectedTour] = useState(null);
  // const [searchTerm, setSearchTerm] = useState('');
  // const [counts, setCounts] = useState({ adult: 0, children: 0, infant: 0, privateRooms: 0 });
  // const [filter, setFilter] = useState('All');

  // const handleCardClick = (tour) => {
  //   setSelectedTour(tour);
  //   setCounts({ adult: 0, children: 0, infant: 0, privateRooms: 0 });
  // };

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleCountChange = (type, delta) => {
  //   setCounts((prevCounts) => ({
  //     ...prevCounts,
  //     [type]: Math.max(0, prevCounts[type] + delta),
  //   }));
  // };

  // const calculateTotal = () => {
  //   if (!selectedTour) return 0;
  //   const basePrice = parseInt(selectedTour.price.replace(/[^0-9]/g, ''), 10);
  //   const total = counts.adult * basePrice +
  //                 counts.children * (basePrice * 0.75) + // Assuming children pay 75% of the price
  //                 counts.infant * (basePrice * 0.5) + // Assuming infants pay 50% of the price
  //                 counts.privateRooms * (basePrice * 1.5); // Assuming private room costs 1.5x the base price
  //   return total;
  // };

  // const filteredTours = allTours.filter((tour) =>
  //   tour.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
  //   (filter === 'All' || tour.category === filter)
  // );

  return (
    <>
     <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundColor: "#282c34",
        color: "white",
        fontSize: "3rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          animation: "pulse 2s infinite",
          margin: 0,
        }}
      >
        Launching Soon
      </h1>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.05);
              opacity: 0.8;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          h1 {
            animation: pulse 2s infinite;
          }
        `}
      </style>
    </Container>
      {/* <section>
        <div className="container mb-3 px-3 px-md-4 px-lg-5">
          <div className="row g-2 align-items-center">
            <div className="tour_bg">
            
            </div>
          </div>
        </div>
      </section>

      <section className="tour-listing-section">
        <div className="container pt-4 mb-3 px-3 px-md-4 px-lg-5">
          <div className="row g-2 align-items-center">
            <div className="col-md-6">
              <h1 className="h2 font-weight-600 mb-3">Tours</h1>
              <p className="lead h5 font-weight-400">Book the ticket of ongoing tour.</p>
            </div>
            <div className="col-md-6">
              <div className="row g-2 justify-content-end">
                <div className="col-md-8 col-12">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-2 mt-4">
            <div className="col-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className={`nav-link ${filter === 'All' ? 'active' : ''}`} onClick={() => setFilter('All')}>All Tours</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${filter === 'International Tour' ? 'active' : ''}`} onClick={() => setFilter('International Tour')}>International Tour</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${filter === 'Pakistan Tour' ? 'active' : ''}`} onClick={() => setFilter('Pakistan Tour')}>Pakistan Tour</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${filter === 'Religious Tour' ? 'active' : ''}`} onClick={() => setFilter('Religious Tour')}>Religious Tour</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row g-4 mt-4">
            {filteredTours.map((tour, index) => (
              <div className="col-md-4" key={index}>
                <div className="card h-100 event-item rounded-0 border-0 text-reset">
                  <div className="card-img rounded-sm">
                    <img
                      src={tour.image}
                      className="card-img-top img-hover-zoom rounded-sm"
                      alt={tour.title}
                    />
                  </div>
                  <div className="card-body px-0">
                    <a
                      href="#"
                      className="stretched-link"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      onClick={() => handleCardClick(tour)}
                    >
                      <div className="row h-100">
                        <div className="col-12">
                          <h5 className="font-weight-500">{tour.title}</h5>
                        </div>
                        <div className="col-6 align-self-end">
                          <p className="text-muted mb-0">{tour.duration}</p>
                        </div>
                        <div className="col-6 text-end align-self-end">
                          <h5 className="font-weight-600 text-primary mb-0">{tour.price}</h5>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="offcanvas offcanvas-end p-0"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="sidePanelLabel"
          >
            <div className="offcanvas-header p-lg-4 p-3 border-bottom">
              <h5 className="offcanvas-title font-weight-600" id="sidePanelLabel">
                Tour Preview
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body bg-gray-100 p-lg-4 p-3 pb-0 pb-lg-0">
              {selectedTour && (
                <>
                  <div className="card p-3 my-3">
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          className="card-img-top img-hover-zoom rounded-sm"
                          src={selectedTour.image}
                          alt={selectedTour.title}
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="card-body px-0">
                          <h1 className="h5 font-weight-600">{selectedTour.title}</h1>
                          <p className="mb-3">{selectedTour.duration}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 px-lg-4 px-3 shadow-sm my-3">
                    <h5 className="font-weight-600 mb-3">Book Now</h5>
                    <div className="row g-3 align-items-center">
                      <div className="col-12">
                        <label className="form-label" htmlFor="depCity">Departure City</label>
                        <select className="form-control form-select" id="depCity" required="">
                          <option value="3">Islamabad</option>
                        </select>
                      </div>
                      <div className="col-12 mb-3">
                        <label className="form-label" htmlFor="date">Date</label>
                        <select className="form-control form-select" id="date" required="">
                          <option value="2887">26 May, 2024 - 31 May, 2024</option>
                        </select>
                      </div>
                      {['adult', 'children', 'infant', 'privateRooms'].map((type, index) => (
                        <div className="row gx-2 my-3" key={index}>
                          <div className="col-4">
                            <label>
                              <strong className="text-dark">{type.charAt(0).toUpperCase() + type.slice(1)}</strong>
                              <p className="mb-0">{type === 'adult' ? '10 > years' : (type === 'children' ? '7 - 10 years' : (type === 'infant' ? '< 3 years' : ''))}</p>
                              <input type="hidden" value="0" />
                            </label>
                          </div>
                          <div className="col-8 d-flex align-items-start justify-content-end">
                            <button
                              className="value-button btn decrease"
                              onClick={() => handleCountChange(type, -1)}
                            >
                              <i className="fa fa-minus text-gray-500 fa-md"></i>
                            </button>
                            <input
                              type="number"
                              id="number"
                              value={counts[type]}
                              readOnly
                            />
                            <button
                              className="value-button btn increase"
                              onClick={() => handleCountChange(type, 1)}
                            >
                              <i className="fa fa-plus text-gray-500 fa-md"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card p-3 px-lg-4 px-3 shadow-sm my-3">
                    <h5 className="font-weight-600 mb-3">Detailed Itinerary</h5>
                    <p className="tour-detail">
                      {selectedTour.description}
                    </p>
                  </div>
                  <div className="alert cancellation-policy my-3">
                    <div className="d-flex flex-column flex-md-row align-items-start">
                      <span className="me-2 mb-2 mb-md-0">Cancellation Policy</span>
                      <p className="mb-0"> Cancellation Policy </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row align-items-center bg-white mx-0 p-3 border-top sticky-bottom">
              <div className="col-6">
                <p className="text-muted mb-0">Total</p>
                <h5 className="text-primary font-weight-600 fs-4 mb-0">Rs {calculateTotal()}</h5>
              </div>
              <div className="col-6 text-end">
                <button className="btn btn-primary">Continue</button>
              </div>
            </div>
          </div>
          <div className="modal fade" id="galleryModal" tabIndex="-1" aria-labelledby="galleryModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="galleryModalLabel">Gallery</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='pt-5'>
        <MobileApp />
      </div>

      <section className="tour-listing-section">
        <div className="container pt-4 mb-3 px-3 px-md-4 px-lg-5">
          <div className="row g-2 align-items-center">
            <div className="col-12 p-0">
              <h2 className="mb-4 fs-5 custom-font-bold">How to Book your Tour online through Ticketistan?</h2>
              <ol className="fclr">
                <li>Visit web / mobile app.</li>
                <li>Choose your kind of tour.</li>
                <li>Select your package.</li>
                <li>Provide personal detail.</li>
                <li>Method of payment.</li>
                <li>Confirm by clicking only.</li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Tours;
