import React from 'react';
import { Card, Button } from 'react-bootstrap';

const AskUs = () => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Ask us</Card.Title>
        <p>If you have a question or encounter a problem, we can help you any time.</p>
        <Button variant="outline-primary" className="w-100">Ask a question</Button>
      </Card.Body>
    </Card>
  );
};

export default AskUs;
