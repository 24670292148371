import React from 'react';
import TransactionFailed from '../../Components/payments/TransactionFailed';
import TransactionSuccessful from '../../Components/payments/TransactionSuccessful'

const PaymentConfirm = () => {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const orderId = urlParams.get('order_id');
    const ts = urlParams.get('TS');
    const rd = urlParams.get('RD');
    const rc = urlParams.get('RC');

    // Store the parameters in local storage
    localStorage.setItem('success', success);
    localStorage.setItem('orderId', orderId);
    localStorage.setItem('TS', ts);
    localStorage.setItem('RD', rd);
    localStorage.setItem('RC', rc);

    // Render different content based on the value of "success"
    if (success === 'true') {
        return <div><TransactionSuccessful/></div>;
    } else {
        return <div><TransactionFailed/></div>;
    }
};

export default PaymentConfirm;