import React from 'react';

export const AirportData = ({ query, onSelect }) => {
  const airports = [
        "Zurich, Zurich Airport [ZRH], Switzerland",
        "Zouerate, Zouerate Arpt [OUZ], Mauritania",
        "Zilina, Zilina Arpt [ILZ], Slovakia",
        "Zihuatanejo, Zihuatanejo Intl [ZIH], Mexico",
        "Ziguinchor, Ziguinchor Arpt [ZIG], Senegal",
        "Zielona Gora, Babimost Arpt [IEG], Poland",
        "Zhuhai, Zhuhai Arpt [ZUH], China",
        "Zhoushan, Zhoushan Arpt [HSN], China",
        "Zhengzhou, Zhengzhou Arpt [CGO], China",
        "Zhanjiang, Zhanjiang Airport [ZHA], China",
        "Zhangjiajie City, Dayong Arpt [DYG], China",
        "Zaragoza, Zaragoza Airport [ZAZ], Spain",
        "Zaporozhe, Zaporozhye Arpt [OZH], Ukraine",
        "Zanzibar, Kisauni Arpt [ZNZ], Tanzania",
        "Zanjan, Zanjan Arpt [JWN], Iran",
        "Zanesville, Zanesville Arpt [ZZV], United States of America",
        "Zakynthos Town, Zakinthos Arpt [ZTH], Greece",
        "Zagreb, Zagreb Arpt [ZAG], Croatia",
        "Zadar, Zadar Arpt [ZAD], Croatia",
        "Zacatecas, Zacatecas Airport [ZCL], Mexico",
        "Yuzhno Sakhalinsk, Yuzhno Sakhalinsk Arpt [UUS], Russian Federation",
        "Yuma, Yuma Intl [YUM], United States of America",
        "Youngstone, Youngstown Municipal [YNG], United States of America",
        "Young, Young Arpt [NGA], Australia",
        "Yotvata, Yotvata Arpt [YOT], Israel",
        "Yorkton, Yorkton Airport [YQV], Canada",
        "York (PA), York Arpt [THV], United States of America",
        "Yonago, Miho Arpt [YGJ], Japan",
        "Yogjakarta, Adisutjipto Arpt [JOG], Indonesia",
        "Yliveska, Ylivieska Arpt [YLI], Finland",
        "Yiwu, Yiwu Arpt [YIW], China",
        "Yinchuan, Yinchuan Arpt [INC], China",
        "Yichang, Yichang Arpt [YIH], China",
        "Yerevan, Yerevan Arpt [EVN], Armenia",
        "Yeovilton, Yeovilton [YEO], United Kingdom",
        "Yeosu, Yeosu Arpt [RSU], Korea, Republic of",
        "Yellowknife, Yellowknife Arpt [YZF], Canada",
        "Yekaterinburg, Sverdolovsk Arpt [SVX], Russian Federation",
        "Yazd, Yazd Arpt [AZD], Iran",
        "Yateley, Blackbushe Airport [BBS], United Kingdom",
        "Yaroslavl, Yaroslavl Arpt [IAR], Russian Federation",
        "Yarmouth, Yarmouth Municipal Arpt [YQI], Canada",
        "Yap, Yap Arpt [YAP], Micronesia",
        "Yaounde, Yaounde Airport [YAO], Cameroon",
        "Yaounde, Nsimalen Arpt [NSI], Cameroon",
        "Yantai, Laishan Arpt [YNT], China",
        "Yankton, Chan Gurney Municipal [YKN], United States of America",
        "Yanji, Yanji Arpt [YNJ], China",
        "Yangon, Mingaladon [RGN], Myanmar",
        "Yandicoogina, Yandi Arpt [YNN], Australia",
        "Yanbo, Yanbo Arpt [YNB], Saudi Arabia",
        "Yamoussouro, Yamoussoukro Arpt [ASK], Cote D'Ivoire",
        "Yamagata, Yamagata Airport [GAJ], Japan",
        "Yakutsk, Yakutsk Arpt [YKS], Russian Federation",
        "Yakutat, Yakutat Arpt [YAK], United States of America",
        "Yakima, Yakima Terminal Arpt [YKM], United States of America",
        "Xuzhou, Xuzhou Arpt [XUZ], China",
        "Xining, Xining Arpt [XNN], China",
        "Xian (Sian), Xiguan Airport [SIA], China",
        "Xian (Sian), Xianyang Arpt [XIY], China",
        "Xiamen (Hsia-Men), Xiamen Intl Airport [XMN], China",
        "Wyndham, Wyndham Arpt [WYN], Australia",
        "Wuyishan, Wuyishan Arpt [WUS], China",
        "Wuxi, Wuxi Arpt [WUX], China",
        "Wuhan, Wuhan Arpt [WUH], China",
        "Wroclaw, Strachowice [WRO], Poland",
        "Wrangell, Wrangell Seaplane Base [WRG], United States of America",
        "Worthington (MN), Worthington Arpt [OTG], United States of America",
        "Worland, Worland Municipal [WRL], United States of America",
        "Worcester (MA), Worcester Arpt [ORH], United States of America",
        "Woodford, Woodford [WFD], United Kingdom",
        "Woodbridge, Bentwaters St [BWY], United Kingdom",
        "Wollongong, Wollongong Arpt [WOL], Australia",
        "Wiscosin Rapids, Alexander Field [ISW], United States of America",
        "Wiscasset, Wiscasset Arpt [ISS], United States of America",
        "Winter Haven, Gilbert Fld [GIF], United States of America",
        "Winston-Salem, Smith Reynolds Arpt [INT], United States of America",
        "Winslow, Winslow Municipal [INW], United States of America",
        "Winnipeg, Winnipeg Intl Arpt [YWG], Canada",
        "Winnemucca, Winnemucca Municipal Arpt [WMC], United States of America",
        "Windsor, Windsor Intl Arpt [YQG], Canada",
        "Windorah, Windorah Arpt [WNR], Australia",
        "Windhoek, Hosea Kutako International Arpt [WDH], Namibia",
        "Windhoek, Eros Arpt [ERS], Namibia",
        "Wilmington, Greater Wilmington New Castle [ILG], United States of America",
        "Wilmington (NC), New Hanover Cty Arpt [ILM], United States of America",
        "Williston, Sloulin Field Intl Arpt [ISN], United States of America",
        "Williamsport, Williamsport Lycoming Municipal [IPT], United States of America",
        "Williams Lake, Williams Lake Municipal [YWL], Canada",
        "Wilhemshaven, Wilhelmshaven Arpt [WVN], Germany",
        "Wick, Wick [WIC], United Kingdom",
        "Wichita, Mid Continent Arpt [ICT], United States of America",
        "Wichita Falls, Wichita Falls Municipal Arpt [SPS], United States of America",
        "Whyalla, Whyalla Arpt [WYA], Australia",
        "Whitehorse, Whitehorse Arpt [YXY], Canada",
        "Whistler, Whistler Arpt [YWS], Canada",
        "Whangarei, Whangarei Arpt [WRE], New Zealand",
        "Whalsay, Whalsay [WHS], United Kingdom",
        "Whale Cove, Whale Cove Arpt [YXN], Canada",
        "Whakatane, Whakatane Arpt [WHK], New Zealand",
        "Westport, Westport Airport [WSZ], New Zealand",
        "Westhampton, Suffolk County Arpt [FOK], United States of America",
        "Westerly, Westerly Municipal [WST], United States of America",
        "Westerland, Westerland Airport [GWT], Germany",
        "Westchester Country, Westchester County Arpt [HPN], United States of America",
        "West Yellowstone, West Yellowstone Arpt [WYS], United States of America",
        "West Palm Beach APT, Palm Beach Intl Arpt [PBI], United States of America",
        "West Palm Beach APT, Palm Beach County Arpt [LNA], United States of America",
        "West Malling, West Malling [WEM], United Kingdom",
        "West Bend, West Bend Arpt [ETB], United States of America",
        "Wenzhou, Wenzhou Arpt [WNZ], China",
        "Wenatchee, Pangborn Memorial Fld [EAT], United States of America",
        "Wellington, Wellington Arpt [WLG], New Zealand",
        "Weipa, Weipa Arpt [WEI], Australia",
        "Weihai, Weihai Arpu [WEH], China",
        "Wee Waa, Wee Waa Airport [WEW], Australia",
        "Waycross, Ware County Arpt [AYS], United States of America",
        "Wawa, Wawa Municipal Arpt [YXZ], Canada",
        "Waukegan, Memorial Arpt [UGN], United States of America",
        "Watsonville, Watsonville Municipal Arpt [WVI], United States of America",
        "Waterville, Robert La Fleur Arpt [WVL], United States of America",
        "Watertown (SD), Watertown Municipal [ATY], United States of America",
        "Watertown (NY), Watertown Arpt [ART], United States of America",
        "Waterloo, Livingston Betsworth Fld [ALO], United States of America",
        "Waterford, Waterford Arpt [WAT], Ireland",
        "Waston Lake, Watson Lake Arpt [YQH], Canada",
        "Waskaganish, Waskaganish Arpt [YKQ], Canada",
        "Washington, Washington Dulles Intl [IAD], United States of America",
        "Washington, Ronald Reagan National Arpt [DCA], United States of America",
        "Warsaw, Warsaw Intl Arpt [WAW], Poland",
        "Warrnambool, Warrnambool Arpt [WMB], Australia",
        "Wapakoneta, Neil Armstrong Arpt [AXV], United States of America",
        "Wanzhou, Wanxian Arpt [WXN], China",
        "Wangerooge, Flugplatz Arpt [AGE], Germany",
        "Wanganui, Wanganui Arpt [WAG], New Zealand",
        "Wanaka, Wanaka Arpt [WKA], New Zealand",
        "Walvis Bay, Rooikop Arpt [WVB], Namibia",
        "Walterboro, Walterboro Municipal Arpt [RBW], United States of America",
        "Walla Walla, Walla Walla City County [ALW], United States of America",
        "Walgett, Walgett Arpt [WGE], Australia",
        "Wainwright, Wainwright Municipal Arpt [AIN], United States of America",
        "Wagga Wagga, Forest Hill Arpt [WGA], Australia",
        "Waco, Madison Cooper Arpt [ACT], United States of America",
        "Wabush, Wabush Municipal Arpt [YWK], Canada",
        "Volos, Nea Anchialos Arpt [VOL], Greece",
        "Vologda, Vologda Arpt [VGD], Russian Federation",
        "Volgograd, Volgograd Arpt [VOG], Russian Federation",
        "Vladivostok, Vladivostok Arpt [VVO], Russian Federation",
        "Vitoria, Vitoria Arpt [VIT], Spain",
        "Vitoria Da Conquista, Vitoria Da Conquista Arpt [VDC], Brazil",
        "Vishakhapatnam, Vishakhapatnam [VTZ], India",
        "Visby, Visby Airport [VBY], Sweden",
        "Visalia, Visalia Municipal [VIS], United States of America",
        "Virgin Gorda, Virgin Gorda Arpt [VIJ], Virgin Islands (British)",
        "Vinnitsa, Vinnitsa Arpt [VIN], Ukraine",
        "Vinh City, Vinh City Arpt [VII], Vietnam",
        "Vincennes, Oneal Arpt [OEA], United States of America",
        "Vina Del Mar, Vina Del Mar Arpt [KNA], Chile",
        "Vilnius, Vilnius Arpt [VNO], Lithuania",
        "Villa Mercedes, Villa Mercedes Arpt [VME], Argentina",
        "Villa Gesell, Villa Gesell Arpt [VLG], Argentina",
        "Villa Constitucion, Villa Constitucion Arpt [VIB], Mexico",
        "Vilhena, Vilhena Arpt [BVH], Brazil",
        "Vilhelmina, Vilhelmina Arpt [VHM], Sweden",
        "Vilanculos, Vilanculos Arpt [VNX], Mozambique",
        "Vila Real, Vila Real Arpt [VRL], Portugal",
        "Vijayawada  , Vijayawada Airport [VGA], India",
        "Vigo, Vigo Airport [VGO], Spain",
        "Vieux Fort St Lucia, Hewanorra Arpt [UVF], St.Lucia",
        "Vieste, Vieste Arpt [VIF], Italy",
        "Vieques, Vieques Arpt [VQS], Puerto Rico",
        "Vientiane, Wattay Arpt [VTE], Laos",
        "Vienna, Vienna Intl Arpt [VIE], Austria",
        "Viedma, Viedma Arpt [VDM], Argentina",
        "Vidalia, Vidalia Municipal Arpt [VDI], United States of America",
        "Victorville, George AFB [VCV], United States of America",
        "Victoria, Victoria Regional Arpt [VCT], United States of America",
        "Victoria, Victoria Intl Arpt [YYJ], Canada",
        "Victoria, Inner Harbor Sea Plane Arpt [YWH], Canada",
        "Victoria, Eurico Sales Arpt [VIX], Brazil",
        "Victoria Falls, Victoria Falls Arpt [VFA], Zimbabwe",
        "Vicksburg, Vicksburg Arpt [VKS], United States of America",
        "Vichy, Charmeil Arpt [VHY], France",
        "Vicenza, Vicenza Arpt [VIC], Italy",
        "Vestmannaeyjar, Vestmannaeyjar Arpt [VEY], Iceland",
        "Verona, Verona Airport [VRN], Italy",
        "Verona, Montichiari Arpt [VBS], Italy",
        "Vero Beach, Vero Beach Municipal Arpt [VRB], United States of America",
        "Vernon, Vernon Municipal Arpt [YVE], Canada",
        "Vernal, Vernal Municipal [VEL], United States of America",
        "Vermilion, Vermilion Arpt [YVG], Canada",
        "Veracruz, Las Bajadas General Heriberto Jara [VER], Mexico",
        "Venice, Marco Polo Arpt [VCE], Italy",
        "Vejle, Vejle Arpt [VEJ], Denmark",
        "Vaxjo, Vaxjo Airport [VXO], Sweden",
        "Vava U, Lupepau U Arpt [VAV], Tonga",
        "Varna, Varna [VAR], Bulgaria",
        "Varginha, Maj Brig Trompowsky Arpt [VAG], Brazil",
        "Vardoe, Vardoe Luftan [VAW], Norway",
        "Varanasi, Babatpur Airport [VNS], India",
        "Varakus, Varkaus [VRK], Finland",
        "Varadero, Juan Gualberto Gomez Arpt [VRA], Cuba",
        "Vannes, Muecon Arpt [VNE], France",
        "Vanimo, Vanimo Arpt [VAI], Papua New Guinea",
        "Vancouver, Vancouver Intl Arpt [YVR], Canada",
        "Vancouver, Coal Harbor Sea Plane Arpt [CXH], Canada",
        "Van Horn, Culberson Cty Arpt [VHN], United States of America",
        "Valverde, Hierro Arpt [VDE], Spain",
        "Valparaiso, Porter County [VPZ], United States of America",
        "Valparaiso, Ft Walton Beach Arpt [VPS], United States of America",
        "Valparaiso, Duke Field [EGI], United States of America",
        "Vallemi, Inc Arpt [VMI], Paraguay",
        "Vallejo, Stolport Arpt [VLO], United States of America",
        "Valladolid, Valladolid Arpt [VLL], Spain",
        "Valera, Carvajal Arpt [VLV], Venezuela",
        "Valencia, Valenica Arpt [VLN], Venezuela",
        "Valencia, Valencia Arpt [VLC], Spain",
        "Valence, Chabeuil Airport [VAF], France",
        "Valdosta, Valdosta Regional [VLD], United States of America",
        "Valdivia, Pichoy [ZAL], Chile",
        "Valdez, Valdez Municipal Arpt [VDZ], United States of America",
        "Val D Or, Val d Or Municipal Arpt [YVO], Canada",
        "Vadso, Vadso [VDS], Norway",
        "Vadodara (Baroda), Vadodara Arpt [BDQ], India",
        "Vaasa, Vaasa Arpt [VAA], Finland",
        "VAN, Van Ferit Melen Airport [VAN], Turkey",
        "Uyuni, Uyuni Airport [UYU], Bolivia",
        "Uttarakhand, Pantnagar Airport [PGH], India",
        "Utapao, U Tapao Arpt [UTP], Thailand",
        "Ust Kamenogorsk, Ust Kamenogorsk Arpt [UKK], Kazakhstan",
        "Usinsk, Usinsk Arpt [USK], Russian Federation",
        "Ushuaia, Islas Malvinas Arpt [USH], Argentina",
        "Useless Loop, Useless Loop Arpt [USL], Australia",
        "Urumqi, Urumqi Arpt [URC], China",
        "Uruguaina, Ruben Berta Arpt [URG], Brazil",
        "Uruapan, Uruapan Arpt [UPN], Mexico",
        "Urgench, Urgench Arpt [UGC], Uzbekistan",
        "Uralsk, Uralsk Arpt [URA], Kazakhstan",
        "Upington, Upington Airport [UTN], South Africa",
        "Upavon, Upavon [UPV], United Kingdom",
        "Unst Shetland Is, Baltasound [UNT], United Kingdom",
        "Union Island, Union Island Arpt [UNI], St. Vincent And Grenadines",
        "Unalakleet, Unalakleet Arpt [UNK], United States of America",
        "Una Br, Una Airport [UNA], Brazil",
        "Umuarama, Ernesto Geisel Arpt [UMU], Brazil",
        "Umtata, K D Matanzima Arpt [UTT], South Africa",
        "Umiujaq, Umiujaq Arpt [YUD], Canada",
        "Umeaa, Umea Airport [UME], Sweden",
        "Ulyanovsk, Ulyanovsk Airport [ULY], Russian Federation",
        "Ulsan, Ulsan Arpt [USN], Korea, Republic of",
        "Ulan Ude, Ulan Ude Arpt [UUD], Russian Federation",
        "Ulaanbatar, Buyant Uhaa Airport [ULN], Mongolia",
        "Ukunda, Ukunda Airport [UKA], Kenya",
        "Ukiah, Ukiah Arpt [UKI], United States of America",
        "Ujung Pandang, Hasanudin Arpt [UPG], Indonesia",
        "Uherske Hradiste, Uherske Hradiste Kunovice Arpt [UHE], Czech Republic",
        "Ufa Ru, Ufa Arpt [UFA], Russian Federation",
        "Udon Thani, Udon Thani Arpt [UTH], Thailand",
        "Udine, Campoformido Arpt [UDN], Italy",
        "Uden, Volkel Arpt [UDE], Netherlands",
        "Udaipur, Dabok Airport [UDR], India",
        "Ubon Ratchath, Muang Ubon Arpt [UBP], Thailand",
        "Uberlandia, Eduardo Gomes Airprt [UDI], Brazil",
        "Uberaba, Uberaba Airport [UBA], Brazil",
        "Ube, Ube Airport [UBJ], Japan",
        "Tzaneen, Letaba Aprt [LTA], South Africa",
        "Tyumen, Tyumen Arpt [TJM], Russian Federation",
        "Tyler, Pounds Field [TYR], United States of America",
        "Twin Hills, Twin Hills Arpt [TWA], United States of America",
        "Twin Falls, Twin Falls City County Arpt [TWF], United States of America",
        "Tver, Migalovo Arpt [KLD], Russian Federation",
        "Tuzla, Tuzla Intl Arpt [TZL], Bosnia Hercegovina",
        "Tuy, Turaif Arpt [TUI], Spain",
        "Tuy Hoa, Tuy Hoa Arpt [TBB], Vietnam",
        "Tuxtla Gutierrez, Llano San Juan Arpt [TGZ], Mexico",
        "Tuticorin, Tuticorin [TCR], India",
        "Tuscaloosa, Van De Graff Arpt [TCL], United States of America",
        "Turku, Turku Arpt [TKU], Finland",
        "Turkmenabad, Turkmenabad Arpt [CRZ], Turkmenistan",
        "Turkmanbashi, Turkmanbashi Arpt [KRW], Turkmenistan",
        "Turin, Citta Di Torino Arpt [TRN], Italy",
        "Turbat, Turbat Arpt [TUK], Pakistan",
        "Tupelo, C D Lemons Municipal Arpt [TUP], United States of America",
        "Tunxi(huangshan shi), Tunxi Arpt [TXN], China",
        "Tunis, Carthage Arpt [TUN], Tunisia",
        "Tumbes, Tumbes Arpt [TBP], Peru",
        "Tulum, Tulum Arpt [TUY], Mexico",
        "Tulsa, Tulsa Intl [TUL], United States of America",
        "Tuli Block, Limpopo Valley Arpt [TLD], Botswana",
        "Tulear, Tulear Arpt [TLE], Madagascar",
        "Tulare, Mefford Field [TLR], United States of America",
        "Tucurui, Tucurui Arpt [TUR], Brazil",
        "Tucuman, Benjamin Matienzo Airport [TUC], Argentina",
        "Tucson Int'l Airport, Tucson Intl Arpt [TUS], United States of America",
        "Tububil, Tabubil Arpt [TBG], Papua New Guinea",
        "Tsushima, Tsushima Arpt [TSJ], Japan",
        "Truk (Chuuk) Island, Truk Arpt [TKK], Micronesia",
        "Trujillo, Trujillo Arpt [TRU], Peru",
        "Truckee, Truckee Arpt [TKF], United States of America",
        "Troyes Barberey, Brussel Midi  [QYR], Belgium",
        "Troutdale, Troutdale Arpt [TTD], United States of America",
        "Trondheim, Trondheim Vaernes Arpt [TRD], Norway",
        "Tromso, Tromso Langnes Arpt [TOS], Norway",
        "Trombetas, Trombetas Arpt [TMT], Brazil",
        "Trollhattan, Trollhattan Arpt [THN], Sweden",
        "Trois Rivieres, Three Rivers Arpt [YRQ], Canada",
        "Tripoli, Tripoli Intl Arpt [TIP], Libyan Arab Jamahiriya",
        "Tripoli, Mitiga [MJI], Libyan Arab Jamahiriya",
        "Trinidad, Trinidad CU Arpt [TND], Cuba",
        "Trinidad, Trinidad Arpt [TDD], Bolivia",
        "Trieste, Ronchi Dei Legionari Arpt [TRS], Italy",
        "Treviso, Treviso Arpt [TSF], Italy",
        "Tres Arroyos, Tres Arroyos Arpt [OYO], Argentina",
        "Trenton, Trenton Municipal Arpt [YTR], Canada",
        "Trenton, Trenton Mercer Arpt [TTN], United States of America",
        "Trelew, Trelew Arpt [REL], Argentina",
        "Treasure Cay, Treasure Cay Arpt [TCB], Bahamas",
        "Traverse City, Cherry Capital Arpt [TVC], United States of America",
        "Trat, Trat Arpt [TDX], Thailand",
        "Traralgon, La Trobe Traralgon Arpt [TGN], Australia",
        "Trapani, Birgi Arpt [TPS], Italy",
        "Trang, Trang Arpt [TST], Thailand",
        "Trabzon, Trabzon Arpt [TZX], Turkey",
        "Tozeur, Tozeur Arpt [TOE], Tunisia",
        "Toyama, Toyama Arpt [TOY], Japan",
        "Townsville, Townsville Arpt [TSV], Australia",
        "Toussus Le Noble, Toussus Le Noble Arpt [TNF], France",
        "Tours, Saint Symphorien Arpt [TUF], France",
        "Toulouse, Blagnac Arpt [TLS], France",
        "Toulon, Le Palyvestre Arpt [TLN], France",
        "Tottori, Tottori Arpt [TTJ], Japan",
        "Tortuquero, Tortuquero Arpt [TTQ], Costa Rica",
        "Tortoli (Sardinia), Arbatax Arpt [TTB], Italy",
        "Tortola-Road Town, Road Town Arpt [RAD], Virgin Islands (British)",
        "Torrington (WY), Torrington Minicipal Arpt [TOR], United States of America",
        "Torreon, Francisco Sarabia Arpt [TRC], Mexico",
        "Toronto, Toronto City Centre Airport [YTZ], Canada",
        "Toronto, Lester B Pearson Intl [YYZ], Canada",
        "Toronto, Buttonville Arpt [YKZ], Canada",
        "Toronto, All Airports [YTO], Canada",
        "Topeka, Phillip Billard Arpt [TOP], United States of America",
        "Topeka, Forbes Field [FOE], United States of America",
        "Toowoomba, Toowoomba Arpt [TWB], Australia",
        "Tonopah, Tonopah Arpt [TPH], United States of America",
        "Tongren, Tongren Arpt [TEN], China",
        "Tomsk, Tomsk Arpt [TOF], Russian Federation",
        "Tom Price, Tom Price [TPR], Australia",
        "Toluca, Morelos Arpt [TLC], Mexico",
        "Toledo, Toledo Express Arpt [TOL], United States of America",
        "Toledo, Toledo Arpt [TOW], Brazil",
        "Tokyo, Narita [NRT], Japan",
        "Tokyo, Haneda Arpt [HND], Japan",
        "Tokyo, All Airports [TYO], Japan",
        "Tokushima, Tokushima Arpt [TKS], Japan",
        "Tokunoshima, Tokunoshima Arpt [TKN], Japan",
        "Tok (AK), Tok Arpt [TKJ], United States of America",
        "Togiak, Togiak Village Arpt [TOG], United States of America",
        "Tocumwal, Tocumwal Arpt [TCW], Australia",
        "Tobruk, Tobruk Arpt [TOB], Libyan Arab Jamahiriya",
        "Tobolsk, Tobolsk Arpt [TOX], Russian Federation",
        "Tobago Island, Crown Point Arpt [TAB], Trinidad And Tobago",
        "Tivat, Tivat Arpt [TIV], Serbia and Montenegro",
        "Titusville, Space Center Executive Arpt [TIX], United States of America",
        "Tirupati, Tirupati Airport [TIR], India",
        "Tiruchirapalli, Tiruchirapally Civil Arpt [TRZ], India",
        "Tirgu Mures, Tirgu Mures Arpt [TGM], Romania",
        "Tiree, Tiree [TRE], United Kingdom",
        "Tirana, Rinas Arpt [TIA], Albania",
        "Tioman, Tioman Arpt [TOD], Malaysia",
        "Tinian, Tinian Arpt [TIQ], Northern Mariana Islands",
        "Tingwon, Tingwon Arpt [TIG], Papua New Guinea",
        "Timmins, Timmins Municipal Arpt [YTS], Canada",
        "Timisoara, Timisoara Arpt [TSR], Romania",
        "Timika, Timika Arpt [TIM], Indonesia",
        "Timaru, Timaru Arpt [TIU], New Zealand",
        "Tikehau Atoll, Tikehau Arpt [TIH], French Polynesia",
        "Tikal, El Peten Arpt [TKM], Guatemala",
        "Tijuana, Gen Abelardo Rodriguez [TIJ], Mexico",
        "Tifton, Henry Tift Myers Arpt [TMA], United States of America",
        "Tianjin (Tientsin), Tianjin Airport [TSN], China",
        "Thursday Island, Thursday Island Arpt [TIS], Australia",
        "Thunder Bay, Thunder Bay Arpt [YQT], Canada",
        "Thompson, Thompson Arpt [YTH], Canada",
        "Thisted, Thisted Arpt [TED], Denmark",
        "Thiruvananthapuram, Thiruvananthapuram Arpt [TRV], India",
        "Thira Island, Santorini Arpt [JTR], Greece",
        "Thief River Falls, Thief River Falls Numicipal [TVF], United States of America",
        "Thessaloniki, Makedonia Arpt [SKG], Greece",
        "Thermopolis, Hot Springs [THP], United States of America",
        "The Pas, The Pas Municipal Arpt [YQD], Canada",
        "The Dalles, The Dalles Municipal Arpt [DLS], United States of America",
        "Thangool, Thangool Arpt [THG], Australia",
        "Thames, Thames Arpt [TMZ], New Zealand",
        "Thaba Nchu, Thaba Nchu Arpt [TCU], South Africa",
        "Tezpur, Tezpur Salonibari Airport [TEZ], India",
        "Texarkana (AR), Texarkana Municipal [TXK], United States of America",
        "Teterboro, Teterboro Arpt [TEB], United States of America",
        "Tete, Matunda Arpt [TET], Mozambique",
        "Tete A La Baleine, Tete A La Baleine Arpt [ZTB], Canada",
        "Terrell, Terrell Field Arpt [TRL], United States of America",
        "Terre Haute, Hulman Field [HUF], United States of America",
        "Terrace, Terrace Municipal Arpt [YXT], Canada",
        "Terrace Bay, Terrace Bay Municipal Arpt [YTJ], Canada",
        "Teresina, Teresina Arpt [THE], Brazil",
        "Terceira Island (Azores), Lajes Arpt [TER], Portugal",
        "Tepic, Tepic Arpt [TPQ], Mexico",
        "Tennant Creek, Tennant Creek Arpt [TCA], Australia",
        "Tenerife, Tenerife Norte Los Rodeos Arpt [TFN], Spain",
        "Tenerife, Reina Sofia Arpt [TFS], Spain",
        "Temuco, Manquehue Arpt [ZCO], Chile",
        "Temple, Temple Arpt [TPL], United States of America",
        "Telluride, Telluride Municipal Arpt [TEX], United States of America",
        "Telemaco Borba, Telemaco Borba Arpt [TEC], Brazil",
        "Tela, Tela Arpt [TEA], Honduras",
        "Tel Aviv, Ben Gurion Intl Arpt [TLV], Israel",
        "Tehuacan, Tehuacan Arpt [TCN], Mexico",
        "Tehran, Mehrabad Arpt [THR], Iran",
        "Tehran, Imam Khomeini Arpt [IKA], Iran",
        "Tegucigalpa, Toncontin Arpt [TGU], Honduras",
        "Tefe, Tefe Arpt [TFF], Brazil",
        "Teesside Durham, Durham Tees Valley Airport [MME], United Kingdom",
        "Te Anau, Manapouri Airport [TEU], New Zealand",
        "Tbilisi, Novo Alexeyevka Arpt [TBS], Georgia",
        "Tawau, Tawau Arpt [TWU], Malaysia",
        "Taveuni, Matei Arpt [TVU], Fiji",
        "Tauranga, Tauranga Arpt [TRG], New Zealand",
        "Taupo, Taupo Arpt [TUO], New Zealand",
        "Tasiujaq, Tasiujaq Arpt [YTQ], Canada",
        "Tashkent, Vostochny Arpt [TAS], Uzbekistan",
        "Tartu, Tartu Arpt [TAY], Estonia",
        "Tarija, Tarija Arpt [TJA], Bolivia",
        "Tari, Tari Arpt [TIZ], Papua New Guinea",
        "Taree, Taree Arpt [TRO], Australia",
        "Tarawa, Bonriki Arpt [TRW], Kiribati",
        "Taranto, M A Grottag Arpt [TAR], Italy",
        "Tapachula, Tapachula Arpt [TAP], Mexico",
        "Taos, Taos Airport [TSM], United States of America",
        "Tanna, Tanna Arpt [TAH], Vanuatu",
        "Tanjung Pandan, Bulutumbang Arpt [TJQ], Indonesia",
        "Tangier, Boukhalef Arpt [TNG], Morocco",
        "Tanga, Tanga Arpt [TGT], Tanzania",
        "Tanega Shima, Tanega Shima Arpt [TNE], Japan",
        "Tandil, Tandil Arpt [TDL], Argentina",
        "Tananarive, Ivato Arpt [TNR], Madagascar",
        "Tanana, Ralph Calhoun Arpt [TAL], United States of America",
        "Tamworth, Tamworth Arpt [TMW], Australia",
        "Tampico, General F Javier Mina [TAM], Mexico",
        "Tampere, Tampere Pirkkala Arpt [TMP], Finland",
        "Tampa, Tampa Intl [TPA], United States of America",
        "Tampa, Peter O Knight Arpt [TPF], United States of America",
        "Tamky, Chulai Arpt [VCL], Vietnam",
        "Tambor, Tambor Arpt [TMU], Costa Rica",
        "Tamatave, Tamatave Arpt [TMM], Madagascar",
        "Tamarindo, Tamarindo Arpt [TNO], Costa Rica",
        "Taloyoak, Taloyoak Arpt [YYH], Canada",
        "Tallinn, Ulemiste Arpt [TLL], Estonia",
        "Tallahassee, Tallahassee Municipal [TLH], United States of America",
        "Taldy Kurgan, Taldy Kurgan Arpt [TDK], Kazakhstan",
        "Talara, Talara Airport [TYL], Peru",
        "Takamatsu, Takamatsu Arpt [TAK], Japan",
        "Taiyuan, Taiyuan Arpt [TYN], China",
        "Taitung, Taitung Arpt [TTT], Taiwan",
        "Taipei, Sung Shan Arpt [TSA], Taiwan",
        "Taipei, Chiang Kai Shek Arpt [TPE], Taiwan",
        "Tainan, Tainan Arpt [TNN], Taiwan",
        "Taif, Taif Airport [TIF], Saudi Arabia",
        "Taichung, Taichung Arpt [TXG], Taiwan",
        "Taichung, Ching Chuan Kang [RMQ], Taiwan",
        "Tagbilaran, Tagbilaran Airport [TAG], Philippines",
        "Tacoma, Tacoma Industrial Arpt [TIW], United States of America",
        "Tacloban, D Z Romualdez Arpt [TAC], Philippines",
        "Tabuk, Tabuk Arpt [TUU], Saudi Arabia",
        "Tabriz, Tabriz International Airport [TBZ], Iran",
        "Tabatinga, Tabatinga Intl Arpt [TBT], Brazil",
        "Tabarka, Tabarka Arpt [TBJ], Tunisia",
        "Taba, Ras An Naqb Arpt [TCP], Egypt",
        "Szymany, Mazury Arpt [SZY], Poland",
        "Szczecin, Goleniow Arpt [SZZ], Poland",
        "Syros Island, Syros Island Arpt [JSY], Greece",
        "Syracuse, Hancock Intl [SYR], United States of America",
        "Sylhet, Sylhet Osmany Arpt [ZYL], Bangladesh",
        "Syktyvkar, Syktyvkar Arpt [SCW], Russian Federation",
        "Sydney, Sydney Kingsford Smith Arpt [SYD], Australia",
        "Swindon, Railway Station [XWS], United Kingdom",
        "Swindon, Gypsy [SWI], United Kingdom",
        "Swift Current, Swift Current Arpt [YYN], Canada",
        "Swansea, Fairwood Comm [SWS], United Kingdom",
        "Swan River, Swan River Municipal Arpt [ZJN], Canada",
        "Swan Hill, Swan Hill Arpt [SWH], Australia",
        "Svolvaer, Helle [SVJ], Norway",
        "Sveg, Sveg Arpt [EVG], Sweden",
        "Suzhou, Suzhou Arpt [SZV], China",
        "Suva, Nausori Airport [SUV], Fiji",
        "Surgut, Surgut Arpt [SGC], Russian Federation",
        "Surat, Surat Airport [STV], India",
        "Surat Thani, Surat Thani Arpt [URT], Thailand",
        "Surabaya, Juanda Arpt [SUB], Indonesia",
        "Sur Om, Sur Arpt [SUH], Oman",
        "Superior, Richard I Bong Arpt [SUW], United States of America",
        "Sundsvall, Sundsvall Arpt [SDL], Sweden",
        "Sun Valley, Friedman Memorial [SUN], United States of America",
        "Sumy, Sumy Arpt [UMY], Ukraine",
        "Sumter, Sumter Municipal Arpt [SUM], United States of America",
        "Sumare, Sumare Arpt [RWS], Brazil",
        "Sulaymaniyah, Sulaymaniyah International Airport [ISU], Iraq",
        "Sukhothai, Sukhothai Arpt [THS], Thailand",
        "Sugar Land, Hull Field Arpt [SGR], United States of America",
        "Sudbury (ON), Sudbury Municipal Arpt [YSB], Canada",
        "Sucre, Sucre Arpt [SRE], Bolivia",
        "Suceava, Salcea Arpt [SCV], Romania",
        "Subic Bay, Subic Bay Intl Arpt [SFS], Philippines",
        "Stuttgart, Stuttgart Municipal [SGT], United States of America",
        "Stuttgart, Echterdingen Arpt [STR], Germany",
        "Sturgis (MI), Kirsch Municipal [IRS], United States of America",
        "Sturgeon Bay, Door Country Arpt [SUE], United States of America",
        "Stuart, Witham Field [SUA], United States of America",
        "Stronsay, Stronsay [SOY], United Kingdom",
        "Straubing, Wallmuhle [RBM], Germany",
        "Strasbourg (Strassburg), Enzheim Arpt [SXB], France",
        "Strahan, Strahan Arpt [SRN], Australia",
        "Storuman, Gunnarn Arpt [SQO], Sweden",
        "Stornoway, Stornoway Airport [SYY], United Kingdom",
        "Stord, Stord Arpt [SRP], Norway",
        "Stokmarnkes, Skagen Arpt [SKN], Norway",
        "Stockton, Stockton Metro Arpt [SCK], United States of America",
        "Stockholm, Skavsta Airport [NYO], Sweden",
        "Stockholm, Hasslo Airport [VST], Sweden",
        "Stockholm, Bromma Airport [BMA], Sweden",
        "Stockholm, Arlanda Arpt [ARN], Sweden",
        "Stillwater, Searcy Fld [SWO], United States of America",
        "Stevens Point, Stevens Point Municipal [STE], United States of America",
        "Sterling Rock Point, Whiteside Cty Municipal [SQI], United States of America",
        "Stephenville, Stephenville Municipal [YJT], Canada",
        "Stephenville, Clark Field [SEP], United States of America",
        "Stella Maris, Stella Maris Arpt [SML], Bahamas",
        "Steamboat Springs, Steamboat Arpt [SBS], United States of America",
        "Stavropol, Stavropol Arpt [STW], Russian Federation",
        "Stavanger, Sola Airport [SVG], Norway",
        "Staunton, Shenandoah Valley Arpt [SHD], United States of America",
        "Statesville, Statesville Municipal Arpt [SVH], United States of America",
        "State College, University Park Arpt [SCE], United States of America",
        "Stanthorpe, Stanthorpe [SNH], Australia",
        "St Vincent, Arnos Vale Arpt [SVD], St. Vincent And Grenadines",
        "St Tropez, La Mole Arpt [LTT], France",
        "St Thomas, Pembroke Area Municipal Arpt [YQS], Canada",
        "St Thomas, Cyril E King Arpt [STT], United States of America",
        "St Theris Point, St Therese Pt Municipal [YST], Canada",
        "St Pierre, St Pierre Arpt [FSP], Canada",
        "St Pierre, Pikangikum Arpt [YPM], Canada",
        "St Pierre Dela Reunion, St Pierre Dela Reunion Arpt [ZSE], Reunion Island",
        "St Petersburg, Whitted Airport [SPG], United States of America",
        "St Petersburg, St Petersburg Clearwater Arpt [PIE], United States of America",
        "St Petersburg, Rzhevka Arpt [RVH], Russian Federation",
        "St Petersburg, Pulkovo Airport [LED], Russian Federation",
        "St Paul, Downtown St Paul Arpt [STP], United States of America",
        "St Moritz, Samedan Arpt [SMV], Switzerland",
        "St Martin-Grand Case, Grand Case Arpt [CCE], Guadeloupe",
        "St Martin, Esperance Airport [SFG], Netherlands Antilles",
        "St Maarten, Princ Juliana Arpt [SXM], Netherlands Antilles",
        "St Lucia, Vigie Field Arpt [SLU], St.Lucia",
        "St Louis, Spirit Of St Louis Arpt [SUS], United States of America",
        "St Louis, Lambert St Louis Intl [STL], United States of America",
        "St Louis, East St Louis [CPS], United States of America",
        "St Leonard, Edmunston Arpt [YSL], Canada",
        "St Joseph (MO), Rosecrans Memorial Arpt [STJ], United States of America",
        "St John's, St Johns Arpt [YYT], Canada",
        "St Helens, St Helens Arpt [HLS], Australia",
        "St George, St George Arpt [SGO], Australia",
        "St George, Saint George Municipal Arpt [SGU], United States of America",
        "St Etienne, Boutheon Arpt [EBU], France",
        "St Croix-Christianst, Alexander Hamilton Arpt [STX], Virgin Islands (US)",
        "St Brieuc, Tremuson Armor Arpt [SBK], France",
        "St Barthelemy, St Jean Arpt [SBH], Guadeloupe",
        "St Augustine, St Augustine Arpt [UST], United States of America",
        "St Andrews, Leuchars [ADX], United Kingdom",
        "St  Eustatius, Roosevelt Field [EUX], Netherlands Antilles",
        "Srinagar, Srinagar Arpt [SXR], India",
        "Springfield, Westover Metro [CEF], United States of America",
        "Springfield, Capital Airport [SPI], United States of America",
        "Springfield (MO), Springfield Branson Regional Arpt [SGF], United States of America",
        "Springdale, Springdale Municipal Arpt [SPZ], United States of America",
        "Springbok, Springbok Arpt [SBU], South Africa",
        "Spokane, Spokane Intl Arpt [GEG], United States of America",
        "Spokane, Felts Field [SFF], United States of America",
        "Split, Split Arpt [SPU], Croatia",
        "Spiddal, Connemara Arpt [NNR], Ireland",
        "Spencer (IA), Spencer Municipal [SPW], United States of America",
        "Spearfish, Black Hills Clyde Ice Field [SPF], United States of America",
        "Sparta (WI), Camp Mccoy Aaf [CMY], United States of America",
        "Sparta (IL), Sparta Community Airport [SAR], United States of America",
        "Southern Pines, Pinehurst Arpt [SOP], United States of America",
        "Southampton, Southampton Intl Arpt [SOU], United Kingdom",
        "South Molle Island, South Molle Island Arpt [SOI], Australia",
        "South Caicos, South Caicos Intl Arpt [XSC], Turks And Caicos Islands",
        "South Bend, Michiana Regional Arpt [SBN], United States of America",
        "South Andros, South Andros Arpt [TZN], Bahamas",
        "Sorrento, Sorrento Arpt [RRO], Italy",
        "Sorong, Jefman Arpt [SOQ], Indonesia",
        "Sorocaba, Sorocaba Arpt [SOD], Brazil",
        "Songpan, Jiu Zhai Huang Long Arpt [JZH], China",
        "Sonderborg, Sonderborg Arpt [SGD], Denmark",
        "Son La, Na San Arpt [SQH], Vietnam",
        "Somerset (KY), Pulaski County Arpt [SME], United States of America",
        "Solo City, Adi Sumarno Arpt [SOC], Indonesia",
        "Sogndal, Haukasen Arpt [SOG], Norway",
        "Sofia, Sofia Vrazhdebna Arpt [SOF], Bulgaria",
        "Soderhamn, Soderhamn Arpt [SOO], Sweden",
        "Smyrna, Smyrna Arpt [MQY], United States of America",
        "Smithfield (RI), Smithfield Arpt [SFZ], United States of America",
        "Smithers, Smithers Municipal [YYD], Canada",
        "Sligo, Collooney [SXL], Ireland",
        "Sliac, Sliac Arpt [SLD], Slovakia",
        "Skukuza, Skukuza Arpt [SZK], South Africa",
        "Skrydstrup, Skrydstrup Airport [SKS], Denmark",
        "Skovde, Skovde Arpt [KVB], Sweden",
        "Skopje, Skopje Arpt [SKP], Macedonia",
        "Skien, Skien Arpt [SKE], Norway",
        "Skiathos, Skiathos Arpt [JSI], Greece",
        "Skelleftea, Skelleftea Arpt [SFT], Sweden",
        "Skagway, Skagway Minicipal Arpt [SGY], United States of America",
        "Sitka, Sitka Airport [SIT], United States of America",
        "Sisimiut, Sisimiut Arpt [JHS], Greenland",
        "Sioux Lookout, Sioux Lookout Municipal Arpt [YXL], Canada",
        "Sioux Falls, Joe Foss Field [FSD], United States of America",
        "Sioux City, Sioux Gateway Arpt [SUX], United States of America",
        "Sion, Sion Arpt [SIR], Switzerland",
        "Sinoe, R E Murray Arpt [SNI], Liberia",
        "Singapore, Changi Intl Arpt [SIN], Singapore",
        "Simferopol, Simferopol Arpt [SIP], Ukraine",
        "Silver City, Grant County Airport [SVC], United States of America",
        "Silchar, Silchar Airport [IXS], India",
        "Sihanoukville, Sihanouk Intr [KOS], Cambodia",
        "Siguanea, Siguanea Arpt [SZJ], Cuba",
        "Sigleton, Singleton Arpt [SIX], Australia",
        "Siena, Siena Arpt [SAY], Italy",
        "Siem Reap, Siem Reap International Airport [REP], Cambodia",
        "Siem Reap, Siem Reap Angkor International Airport [SAI], Cambodia",
        "Siegen, Siegerland Arpt [SGE], Germany",
        "Sidney, Sindey Richland Municipal Arpt [SDY], United States of America",
        "Sidney (NE), Sidney Municipal [SNY], United States of America",
        "Sibu, Sibu Arpt [SBW], Malaysia",
        "Sibiu, Sibiu Arpt [SBZ], Romania",
        "Sialkot, Sialkot Arpt [SKT], Pakistan",
        "Shreveport, Shreveport Regional Arpt [SHV], United States of America",
        "Shreveport, Barksdale Air Force Base [BAD], United States of America",
        "Show Low, Show Low Arpt [SOW], United States of America",
        "Shoreham By Sea, Shoreham [ESH], United Kingdom",
        "Shonai, Shonai Arpt [SYO], Japan",
        "Shirdi, Shirdi Airport [SAG], India",
        "Shiraz, Shiraz Arpt [SYZ], Iran",
        "Shimla, Simla Arpt [SLV], India",
        "Shimkent, Shimkent Arpt [CIT], Kazakhstan",
        "Shillong, Barapani Airport [SHL], India",
        "Shijiazhuang, Daguocun Arpt [SJW], China",
        "Shetland Islands, Scatsta [SCS], United Kingdom",
        "Shetland Islands, Lerwick/Tingwall [LWK], United Kingdom",
        "Sherman, Grayson County Arpt [PNX], United States of America",
        "Sheridan, Sheridan Cty Arpt [SHR], United States of America",
        "Sherbrooke, Sherbrooke Airport [YSC], Canada",
        "Shepparton, Shepparton [SHT], Australia",
        "Shenzhen, Shenzhen Arpt [SZX], China",
        "Shenyang, Shenyang Arpt [SHE], China",
        "Sheffield, Sheffield City [SZD], United Kingdom",
        "Sheboygan, Sheboygan Arpt [SBM], United States of America",
        "Shawnee, Shawnee Airport [SNL], United States of America",
        "Shashi, Shashi Arpt [SHS], China",
        "Sharurah, Sharurah Arpt [SHW], Saudi Arabia",
        "Sharm El Sheikh, Ophira Arpt [SSH], Egypt",
        "Sharjah, Sharjah Airport [SHJ], United Arab Emirates",
        "Shanzhou, Shanzhou Arpt [SZO], China",
        "Shantou, Shantou Airport [SWA], China",
        "Shannon, Shannon Arpt [SNN], Ireland",
        "Shanghai, Pu Dong Arpt [PVG], China",
        "Shanghai, Hongqiao Arpt [SHA], China",
        "Shahre Kord, Shahre Kord Arpt [CQD], Iran",
        "Sfax, El Maou Airport [SFA], Tunisia",
        "Seychelles, Seychelles Intl Arpt [SEZ], Seychelles",
        "Seward, Seward Airport [SWD], United States of America",
        "Seville, San Pablo Arpt [SVQ], Spain",
        "Sept Iles, Sept Iles Municipal Arpt [YZV], Canada",
        "Seoul, Incheon Intl Arpt [ICN], Korea, Republic of",
        "Seoul, Gimpo Intl Arpt [GMP], Korea, Republic of",
        "Sendai, Sendai Arpt [SDJ], Japan",
        "Semarang, Achmad Uani Arpt [SRG], Indonesia",
        "Selibaby, Selibaby Arpt [SEY], Mauritania",
        "Selebi Phikwe, Selebi Phikwe Arpt [PKW], Botswana",
        "Seldovia, Seldovia Arpt [SOV], United States of America",
        "Seinajoki, Ilmajoki Arpt [SJY], Finland",
        "Sedona, Sedona Arpt [SDX], United States of America",
        "Sedalia, Sedalia Memorial Arpt [DMO], United States of America",
        "Secunda, Secunda Arpt [ZEC], South Africa",
        "Sechelt, Sechelt Arpt [YHS], Canada",
        "Sebring, Air Terminal Arpt [SEF], United States of America",
        "Seattle, Seattle Tacoma Intl Arpt [SEA], United States of America",
        "Seattle, Seattle Boeing Field [BFI], United States of America",
        "Seattle, Lake Union Seaplane Base [LKE], United States of America",
        "Seattle, Kenmore Air Harbor [KEH], United States of America",
        "Scranton, Wilkes Barre Scranton Intl Arpt [AVP], United States of America",
        "Scottsdale, Scottsdale Municipal Arpt [SCF], United States of America",
        "Scottsbluff, Scottsbluff Municipal [BFF], United States of America",
        "Scone, Scone Airport [NSO], Australia",
        "Schwerin, Parchim Arpt [SZW], Germany",
        "Schenectady, Schenectady County Arpt [SCH], United States of America",
        "Schefferville, Schefferville Arpt [YKL], Canada",
        "Savusavu, Savusavu Airport [SVU], Fiji",
        "Savonlinna, Savonlinna Arpt [SVL], Finland",
        "Savannakhet, Savannakhet Airport [ZVK], Laos",
        "Savannah, Savannah Intl Arpt [SAV], United States of America",
        "Sausalito, Marin County Arpt [JMC], United States of America",
        "Sault Ste Marie, Chippewa Cnty Intl [CIU], United States of America",
        "Sault Sainte Maire, Sault Ste Marie Arpt [YAM], Canada",
        "Saudarkrokur, Saudarkrokur Arpt [SAK], Iceland",
        "Saskatoon, Saskatoon Municipal [YXE], Canada",
        "Saskatoon, Kindersley Arpt [YKY], Canada",
        "Sarnia, Sarnia Airport [YZR], Canada",
        "Saratov, Saratov Arpt [RTW], Russian Federation",
        "Sarasota, Sarasota Bradenton Arpt [SRQ], United States of America",
        "Saranac Lake, Adirondack Arpt [SLK], United States of America",
        "Sarajevo, Butmir Arpt [SJJ], Bosnia Hercegovina",
        "Sapporo, Okadama Arpt [OKD], Japan",
        "Sapporo, Chitose Arpt [CTS], Japan",
        "Sao Vicente, San Pedro Airport [VXE], Cape Verde Islands",
        "Sao Paulo, Viracopos Arpt [VCP], Brazil",
        "Sao Paulo, Guarulhos Intl Airport [GRU], Brazil",
        "Sao Paulo, Congonhas Arpt [CGH], Brazil",
        "Sao Nicolau, Preguica Arpt [SNE], Cape Verde Islands",
        "Sao Miguel - Ponta Delgada (Azores), Nordela Arpt [PDL], Portugal",
        "Sao Luiz, Mal Cunha Machado [SLZ], Brazil",
        "Sao Jose Dos Campos, Sao Jose Dos Campos Arpt [SJK], Brazil",
        "Sao Jose Do Rio Preto, Sao Jose Do Rio Preto [SJP], Brazil",
        "Sao Jorge Island, Sao Jorge Arpt [SJZ], Portugal",
        "Sao Filipe, Sao Filipe Arpt [SFL], Cape Verde Islands",
        "Santos, Santos Arpt [SSZ], Brazil",
        "Santo Domingo, Mayor Humberto Vivas Guerrero Arpt [STD], Venezuela",
        "Santo Domingo de la Calzada, Las Americas Arpt [SDQ], Spain",
        "Santo Domingo de la Calzada, Herrera Arpt [HEX], Spain",
        "Santo Antao, Santo Antao Arpt [NTO], Cape Verde Islands",
        "Santiago, Santiago Municipal [STI], Dominican Republic",
        "Santiago de Cuba, Antonio Maceo Arpt [SCU], Cuba",
        "Santiago de Compostela, Santiago Airport [SCQ], Spain",
        "Santiago de Chile, Los Cerrillos [ULC], Chile",
        "Santiago de Chile, Arturo Merino Benitez [SCL], Chile",
        "Santiago Del Estero, Santiago Des Estero Arpt [SDE], Argentina",
        "Santarem, Eduardo Gomes Arpt [STM], Brazil",
        "Santander, Santander Airport [SDR], Spain",
        "Santa Ynez, Santa Ynez Arpt [SQA], United States of America",
        "Santa Rosalia, Santa Rosalia Arpt [SRL], Mexico",
        "Santa Rosa, Sonoma Cty Arpt [STS], United States of America",
        "Santa Paula, Santa Paula Arpt [SZP], United States of America",
        "Santa Marta, Simon Bolivar [SMR], Colombia",
        "Santa Maria, Santa Maria Public Arpt [SMX], United States of America",
        "Santa Maria, Lompoc Arpt [LPC], United States of America",
        "Santa Maria, Base Aerea Arpt [RIA], Brazil",
        "Santa Maria Island (Azores), Vilo Do Porto Arpt [SMA], Portugal",
        "Santa Fe, Santa Fe Municipal [SAF], United States of America",
        "Santa Fe, Santa Fe Arpt [SFN], Argentina",
        "Santa Cruz, Skypark Arpt [SRU], United States of America",
        "Santa Cruz, La Palma Arpt [SPC], Spain",
        "Santa Cruz, Guanacaste Arpt [SZC], Costa Rica",
        "Santa Cruz(BO), Viru Viru Intl Arpt [VVI], Bolivia",
        "Santa Cruz(BO), El Trompillo Arpt [SRZ], Bolivia",
        "Santa Cruz Flores, Aerodromo Das Flores [FLW], Portugal",
        "Santa Clara, Santa Clara Arpt [SNU], Cuba",
        "Santa Barbara, Santa Barbara Arpt [SBA], United States of America",
        "Santa Barbara, Las Delicias Arpt [STB], Venezuela",
        "Santa Ana, John Wayne Arpt [SNA], United States of America",
        "Sanli Urfa, Sanli Urfa Arpt [SFQ], Turkey",
        "Sanikiluaq, Sanikiluaq Arpt [YSK], Canada",
        "Sanford, Sanford Regional Arpt [SFM], United States of America",
        "Sanford, Orlando Sanford Arpt [SFB], United States of America",
        "Sandy Lake, Sandy Lake Arpt [ZSJ], Canada",
        "Sandusky, Griffing Sandusky Arpt [SKY], United States of America",
        "Sandspit, Federal Airport [YZP], Canada",
        "Sandnessjoen, Stokka Arpt [SSJ], Norway",
        "Sanday, Sanday [NDY], United Kingdom",
        "Sandane, Anda Airport [SDN], Norway",
        "Sandakan, Sandakan Arpt [SDK], Malaysia",
        "Sand Point, Sand Point Arpt [SDP], United States of America",
        "Sancti Spiritus, Sancti Spiritus Arpt [USS], Cuba",
        "Sanaa, Sanaa Intl Airport [SAH], Yemen",
        "San Tome, San Tome Airport [TMS], Sao Tome And Principe",
        "San Tome, El Tigre Arpt [SOM], Venezuela",
        "San Sebastian, San Sebastian Arpt [EAS], Spain",
        "San Sebastian De La Gomera, La Gomera Arpt [GMZ], Spain",
        "San Salvador, San Salvador Arpt [ZSA], Bahamas",
        "San Salvador, Comalapa Intl Arpt [SAL], El Salvador",
        "San Rafael, San Rafael Arpt [AFA], Argentina",
        "San Quintin, San Quintin Arpt [SNQ], Mexico",
        "San Pedro, San Pedro Arpt [SPR], Belize",
        "San Pedro Sula, Ramon Villeda Morales Arpt [SAP], Honduras",
        "San Matias, San Matias Arpt [MQK], Bolivia",
        "San Luis, San Luis Cty Arpt [LUQ], Argentina",
        "San Luis Rio Colorado, San Luis Rio Colorado Municipal [UAC], Mexico",
        "San Luis Potosi, San Luis Potosi Municipal Arpt [SLP], Mexico",
        "San Luis Obispo, San Luis Obispo County Arpt [SBP], United States of America",
        "San Luis Obispo, O Sullivan Army Air Field [CSL], United States of America",
        "San Juan, San Juan Arpt [UAQ], Argentina",
        "San Juan, Luiz Munoz Marin Intl [SJU], Puerto Rico",
        "San Jose, Tobias Bolanos Intl [SYQ], Costa Rica",
        "San Jose, San Jose Intl Arpt [SJC], United States of America",
        "San Jose, Reid Hillview Of Santa Clara County [RHV], United States of America",
        "San Jose, Los Cabos Arpt [SJD], Mexico",
        "San Jose, Juan Santa Maria Intl [SJO], Costa Rica",
        "San Giovanni Rotondo, San Giovanni Rotondo Arpt [GBN], Italy",
        "San Francisco, San Francisco Intl Arpt [SFO], United States of America",
        "San Fernando, Las Flecheras [SFD], Venezuela",
        "San Felix, San Felix Arpt [SFX], Venezuela",
        "San Felipe, San Felipe Arpt [SFH], Mexico",
        "San Felipe, San Felipe Airport [SNF], Venezuela",
        "San Domino Island, San Domino Island Arpt [TQR], Italy",
        "San Diego, Montgomery Field [MYF], United States of America",
        "San Diego, Lindbergh Intl Arpt [SAN], United States of America",
        "San Diego, Gillespie Field [SEE], United States of America",
        "San Diego, Brown Field Municipal [SDM], United States of America",
        "San Crystobal, Aeropuerto Santo Domingo [SCI], Venezuela",
        "San Cristobal, San Cristobal Airport [SCY], Ecuador",
        "San Cristobal de las Casas, San Cristobal Arpt [SZT], Mexico",
        "San Carlos, San Carlos Arpt [SQL], United States of America",
        "San Carlos Bariloche, San Carlos De Bariloche Intl Arpt [BRC], Argentina",
        "San Bernardino, San Bernardino Intl Arpt [SBD], United States of America",
        "San Antonio, San Antonio Arpt [SVZ], Venezuela",
        "San Antonio Int'l, Stinson Municipal Arpt [SSF], United States of America",
        "San Antonio Int'l, San Antonio Intl [SAT], United States of America",
        "San Angelo, Mathis Field [SJT], United States of America",
        "San Andres Island, San Andres Island Arpt [ADZ], Colombia",
        "Samsun, Carsamba Arpt [SZF], Turkey",
        "Samos, Samos Arpt [SMI], Greece",
        "Samoa-Apia, Fagali Arpt [FGI], Samoa",
        "Samoa-Apia, Apia Airport [APW], Samoa",
        "Samburu, Samburu Airstrip [UAS], Kenya",
        "Sambava, Sambava Arpt [SVB], Madagascar",
        "Samarkand, Samarkand Arpt [SKD], Uzbekistan",
        "Samara, Samara Arpt [KUF], Russian Federation",
        "Samana, El Portillo Arpt [EPS], Dominican Republic",
        "Salzburg, W A Mozart Arpt [SZG], Austria",
        "Salvador, Luis E Magalhaes Arpt [SSA], Brazil",
        "Salto, Salto Arpt [STY], Uruguay",
        "Saltillo, Saltillo Arpt [SLW], Mexico",
        "Salta, General Belgrano Intl Arpt [SLA], Argentina",
        "Salt Lake City, Salt Lake City Intl Arpt [SLC], United States of America",
        "Salmon Arm, Salmon Arm Municipal [YSN], Canada",
        "Salluit, Salluit Arpt [YZG], Canada",
        "Salisbury, Wicomico Regional Arpt [SBY], United States of America",
        "Salinas, Salinas Arpt [SNS], United States of America",
        "Salina, Salina Municipal [SLN], United States of America",
        "Salina Cruz, Salina Cruz Arpt [SCX], Mexico",
        "Salida, Salida Arpt [SLT], United States of America",
        "Salem, McNary Field [SLE], United States of America",
        "Sale, Sale Arpt [SXE], Australia",
        "Saldanha Bay, Langebaanweg Arpt [SDB], South Africa",
        "Salamo, Salamo Arpt [SAM], Papua New Guinea",
        "Salamanca, Matacan Arpt [SLM], Spain",
        "Salalah, Salalah Arpt [SLL], Oman",
        "Sal Island, Amilcar Cabral Intl Arpt [SID], Cape Verde Islands",
        "Sakon Nakhon, Sakon Nakhon Arpt [SNO], Thailand",
        "Saipan, Saipan Intl [SPN], Northern Mariana Islands",
        "Saint Nazaire, Montoir Airport [SNR], France",
        "Saint Marie, St Marie Arpt [SMS], Madagascar",
        "Saint Laurent Du Maroni, Saint Laurent du Maroni Arpt [LDX], French Guiana",
        "Saint John, St John Municipal [YSJ], Canada",
        "Saint Cloud, Saint Cloud Municipal [STC], United States of America",
        "Saginaw, Saginaw Arpt [MBS], United States of America",
        "Saga, Saga Arpt [HSG], Japan",
        "Sacramento, Sacramento International [SMF], United States of America",
        "Sacramento, Sacramento Executive Arpt [SAC], United States of America",
        "Sacramento, Mather Air Force Base [MHR], United States of America",
        "Saarmelleek, Saarmelleek Balaton Arpt [SOB], Hungary",
        "Saarbrucken, Ensheim Arpt [SCN], Germany",
        "Rzeszow, Jasionka Arpt [RZE], Poland",
        "Rutland, Rutland Arpt [RUT], United States of America",
        "Ruston, Ruston Regional Arpt [RSN], United States of America",
        "Russian Mission, Russian Seaplane Base [RSH], United States of America",
        "Rurrenabaque, Rurrenabaque Airport [RBQ], Bolivia",
        "Ruidoso, Ruidoso Municipal Arpt [RUI], United States of America",
        "Rovaniemi, Rovaniemi Arpt [RVN], Finland",
        "Rouyn Noranda, Rouyn Noranda Arpt [YUY], Canada",
        "Rouen, Boos Airport [URO], France",
        "Rottnest Island, Rottnest Island Arpt [RTS], Australia",
        "Rotterdam, Rotterdam Central Railway Station Airport [QRH], Netherlands",
        "Rotterdam, Rotterdam Arpt [RTM], Netherlands",
        "Rotorua, Rotorua Arpt [ROT], New Zealand",
        "Rota Island, Rota Arpt [ROP], Northern Mariana Islands",
        "Roswell, Industrial Aircenter [ROW], United States of America",
        "Rostov, Rostov Arpt [ROV], Russian Federation",
        "Rostock Laage, Laage Arpt [RLG], Germany",
        "Roskilde, Roskilde Arpt [RKE], Denmark",
        "Rosh Pina, Rosh Pina Arpt [RPN], Israel",
        "Rosh Pina, Rosh Pina Airport [RHN], Namibia",
        "Roseburg, Roseburg Municipal [RBG], United States of America",
        "Rosario, Fisherton Airport [ROS], Argentina",
        "Roros, Roros Arpt [RRS], Norway",
        "Ronneby, Kallinge Arpt [RNB], Sweden",
        "Rondonopolis, Rondonopolis Arpt [ROO], Brazil",
        "Rome, Richard B Russell Arpt [RMG], United States of America",
        "Rome, Fiumicino Arpt [FCO], Italy",
        "Rome, Ciampino Arpt [CIA], Italy",
        "Roma, Roma Arpt [RMA], Australia",
        "Rolla, National Arpt [RLA], United States of America",
        "Roi Et, Roi Et Arpt [ROI], Thailand",
        "Rogers, Rogers Arpt [ROG], United States of America",
        "Roervik, Ryumsjoen Arpt [RVK], Norway",
        "Rodrigues Island, Rodrigues Island Arpt [RRG], Mauritius",
        "Rodez, Marcillac [RDZ], France",
        "Rocky Mount, Wilson Arpt [RWI], United States of America",
        "Rockport, Aransas County Arpt [RKP], United States of America",
        "Rockland, Rockland Arpt [RKD], United States of America",
        "Rockhampton, Rockhampton Arpt [ROK], Australia",
        "Rock Springs, Rock Springs Municipal [RKS], United States of America",
        "Rock Sound, S Eleuthera Arpt [RSD], Bahamas",
        "Rochester, Rochester [RCS], United Kingdom",
        "Rochester (NY), Monroe Cty Arpt New York [ROC], United States of America",
        "Rochester (MN), Rochester Municipal [RST], United States of America",
        "Rochefort, St Agnant Arpt [RCO], France",
        "Roche Harbor, Roche Harbor Arpt [RCE], United States of America",
        "Roberval, Roberval Airport [YRJ], Canada",
        "Roatan, Roatan Arpt [RTB], Honduras",
        "Roanoke, Roanoke Regional Arpt [ROA], United States of America",
        "Roanoke Rapids, Halifax Cty Arpt [RZZ], United States of America",
        "Roanne, Renaison Arpt [RNE], France",
        "Rizhao, Rizhao Shanzihe Airport [RIZ], China",
        "Riyan, Riyan Mukalla Arpt [RIY], Yemen",
        "Riyadh, King Khaled Intl [RUH], Saudi Arabia",
        "Riviere Du Loup, Riviere Du Loup Arpt [YRI], Canada",
        "Riverton, Riverton Municipal [RIW], United States of America",
        "Riverside, Riverside Municipal [RAL], United States of America",
        "Rio de Janeiro, Santos Dumont Arpt [SDU], Brazil",
        "Rio de Janeiro, Rio Internacional [GIG], Brazil",
        "Rio Grande, Rio Grande Arpt [RGA], Argentina",
        "Rio Grande, Rio Grande Airport [RIG], Brazil",
        "Rio Gallegos, Rio Gallegos Internacional Arpt [RGL], Argentina",
        "Rio Dulce, Las Vegas Airport [LCF], Guatemala",
        "Rio Cuarto, Rio Cuarto Arpt [RCU], Argentina",
        "Rio Branco, Pres Medici Arpt [RBR], Brazil",
        "Rimouski, Rimouski Municipal Arpt [YXK], Canada",
        "Rimini, Miramare Airport [RMI], Italy",
        "Rijeka, Rijeka Arpt [RJK], Croatia",
        "Riga, Riga Arpt [RIX], Latvia",
        "Rifle, Garfield Cty Arpt [RIL], United States of America",
        "Richmond (VA), Byrd Intl [RIC], United States of America",
        "Richland, Richland Arpt [RLD], United States of America",
        "Richfield, Reynolds Municipal Arpt [RIF], United States of America",
        "Richards Bay, Richards Bay Arpt [RCB], South Africa",
        "Riberao Preto, Leite Lopes [RAO], Brazil",
        "Rhodes-Rhodes Town, Diagoras Arpt [RHO], Greece",
        "Rhinelander, Oneida County [RHI], United States of America",
        "Reynosa, General Lucio Blanco Arpt [REX], Mexico",
        "Reykjavik, Reykjavik Keflavik Intl Arpt [KEF], Iceland",
        "Reykjavik, Reykjavik Domestic Arpt [RKV], Iceland",
        "Reus, Reus Arpt [REU], Spain",
        "Reunion Island, Gillot Airport [RUN], Reunion Island",
        "Retalhuleu, Base Aerea Del Sur Arpt [RER], Guatemala",
        "Resistencia, Resistencia Arpt [RES], Argentina",
        "Reno, Reno Tahoe Intl Arpt [RNO], United States of America",
        "Rennes, Saint Jacques Arpt [RNS], France",
        "Renmark, Renmark Airport [RMK], Australia",
        "Reims, Champagne Arpt [RHE], France",
        "Regina, Regina Municipal [YQR], Canada",
        "Regina, Moose Jaw Arpt [YMJ], Canada",
        "Reggio Calabria, Tito Menniti Arpt [REG], Italy",
        "Redmond, Roberts Field [RDM], United States of America",
        "Redding, Redding Municipal Arpt [RDD], United States of America",
        "Redding, Flight Service Station [RBL], United States of America",
        "Redang, Lts Pulau Redang Arpt [RDN], Malaysia",
        "Red Lake, Federal Red Lake [YRL], Canada",
        "Recife, Recife Airport [REC], Brazil",
        "Reading, Reading Municipal [RDG], United States of America",
        "Rawlins, Rawlins Municipal [RWL], United States of America",
        "Ravensthorpe, Ravensthorpe Arpt [RVT], Australia",
        "Raton, Crews Fld [RTN], United States of America",
        "Ras Al Khaimah, Ras Al Khaimah Arpt [RKT], United Arab Emirates",
        "Rarotonga, Rarotonga Arpt [RAR], Cook Islands",
        "Rapid City, Rapid City Regional Arpt [RAP], United States of America",
        "Ranong, Ranong Arpt [UNN], Thailand",
        "Rankin Inlet, Rankin Inlet Arpt [YRT], Canada",
        "Rangiroa, Rangiroa Arpt [RGI], French Polynesia",
        "Ranger, Ranger Municipal Arpt [RGR], United States of America",
        "Ranchi, Birsa Munda Airport [IXR], India",
        "Ramsten, Ramsteim Arpt [RMS], Germany",
        "Raleigh, Raleigh Durham Intl Arpt [RDU], United States of America",
        "Rajkot, Rajkot Civil Arpt [HSR], India",
        "Rajahmundry, Rajahmundry Airport [RJA], India",
        "Raipur, Raipur Arpt [RPR], India",
        "Rainbow Lake, Rainbow Lake Arpt [YOP], Canada",
        "Raiatea, Raiatea Arpt [RFP], French Polynesia",
        "Rahim Yar Khan, Rahim Yar Khan [RYK], Pakistan",
        "Racine, Horlick Arpt [RAC], United States of America",
        "Rach Gia, Rach Gia Arpt [VKG], Vietnam",
        "Rabaul, Lakunai Arpt [RAB], Papua New Guinea",
        "Rabat, Sale Airport [RBA], Morocco",
        "Quito, Mariscal Arpt [UIO], Ecuador",
        "Quirindi, Quirindi Arpt [UIR], Australia",
        "Quincy (IL), Baldwin Field [UIN], United States of America",
        "Quimper, Pluguffan Arpt [UIP], France",
        "Quilpie, Quilpie Arpt [ULP], Australia",
        "Qui Nhon, Qui Nhon Arpt [UIH], Vietnam",
        "Quetta, Quetta International Airport [UET], Pakistan",
        "Quesnel, Quesnel Arpt [YQZ], Canada",
        "Queretaro, Queretaro Arpt [QRO], Mexico",
        "Queenstown, Queenstown Arpt [UEE], Australia",
        "Queenstown, Queenstown Airport [UTW], South Africa",
        "Queenstown, Frankton Airport [ZQN], New Zealand",
        "Queen Charlotte Island, Queen Charlotte Island Arpt [ZQS], Canada",
        "Quebec City, Quebec Intl [YQB], Canada",
        "Quaqtaq, Quaqtaq Arpt [YQC], Canada",
        "Quakertown, Upper Bucks Arpt [UKT], United States of America",
        "Qiqihar, Qiqihar Arpt [NDG], China",
        "Qinhuangdao, Qinhuangdao Arpt [SHP], China",
        "Qingdao (Tsingtao), Liuting Arpt [TAO], China",
        "Qaqortoq, Qaqortoq Arpt [JJU], Greenland",
        "Qaisumah, Qaisumah Arpt [AQI], Saudi Arabia",
        "Qaarsut, Qaarsut Arpt [JQA], Greenland",
        "Pyongyang, Sunan Arpt [FNJ], Korea, Democratic People's Republic of",
        "Puvirnituq, Povungnituk Arpt [YPX], Canada",
        "Punta del Este, Cap Curbelo Arpt [PDP], Uruguay",
        "Punta Renes, Punta Renes Arpt [JAP], Costa Rica",
        "Punta Gorda, Charlotte County [PGD], United States of America",
        "Punta Cana, Punta Cana Arpt [PUJ], Dominican Republic",
        "Punta Arenas, Presidente Ibanez Arpt [PUQ], Chile",
        "Pune, Lohegaon Arpt [PNQ], India",
        "Pullman, Pullman Moscow Arpt [PUW], United States of America",
        "Pula, Pula Arpt [PUY], Croatia",
        "Puerto Vallarta, Ordaz Arpt [PVR], Mexico",
        "Puerto Princesa, Puerto Princesa Arpt [PPS], Philippines",
        "Puerto Plata, La Union Arpt [POP], Dominican Republic",
        "Puerto Penasco, Puerto Penasco Municipal [PPE], Mexico",
        "Puerto Ordaz, Puerto Ordaz Arpt [PZO], Venezuela",
        "Puerto Natales, Teniente Julio Gallardo Arpt [PNT], Chile",
        "Puerto Montt, Tepual Airport [PMC], Chile",
        "Puerto Maldonado, Puerto Maldonado Arpt [PEM], Peru",
        "Puerto Madryn, El Tehuelche Arpt [PMY], Argentina",
        "Puerto La Victoria, Puerto La Victoria Arpt [PCJ], Paraguay",
        "Puerto Escondido, Puerto Escondido Municipal [PXM], Mexico",
        "Puerto Del Rosario, Fuerteventura Arpt [FUE], Spain",
        "Puerto Barrios, Puerto Barrios Arpt [PBR], Guatemala",
        "Puerto Aisen, Puerto Aisen Arpt [WPA], Chile",
        "Pueblo, Pueblo Arpt [PUB], United States of America",
        "Pueblo, Las Animas Arpt [TAD], United States of America",
        "Puebla, Huejostingo Arpt [PBC], Mexico",
        "Pucon, Pucon Arpt [ZPC], Chile",
        "Pucallpa, Capitan Rolden Arpt [PCL], Peru",
        "Pt Macquarie, Port Macquarie Arpt [PQQ], Australia",
        "Pskov, Pskov Arpt [PKV], Russian Federation",
        "Prudhoe Bay Deadhorse, Prudhoe Bay Deadhorse Arpt [SCC], United States of America",
        "Provo, Provo Arpt [PVU], United States of America",
        "Provincetown, Provincetown Municipal [PVC], United States of America",
        "Providenciales, Providenciales Intl [PLS], Turks And Caicos Islands",
        "Providence, T F Green St Arpt [PVD], United States of America",
        "Proserpine, Whitsunday Coast Arpt [PPP], Australia",
        "Propriano, Propriano Arpt [PRP], France",
        "Pristina, Pristina Arpt [PRN], Serbia and Montenegro",
        "Princeton, Princeton Arpt [PCT], United States of America",
        "Prince Rupert, Seal Cove Arpt [ZSW], Canada",
        "Prince Rupert, Digby Island Arpt [YPR], Canada",
        "Prince George, Prince George Municipal [YXS], Canada",
        "Prince Albert, Prince Albert Municipal Arpt [YPA], Canada",
        "Price, Carbon Cty Municipal Arpt [PUC], United States of America",
        "Preveza, Aktion Arpt [PVK], Greece",
        "Pretoria, Wonderboom Arpt [PRY], South Africa",
        "Pretoria, Central Hpr Arpt [HPR], South Africa",
        "Presque Isle, Northern Maine Regional [PQI], United States of America",
        "Presidente Prudente, A De Barros [PPB], Brazil",
        "Prescott, Prescott Municipal [PRC], United States of America",
        "Praslin Island, Praslin Island Arpt [PRI], Seychelles",
        "Prairie du Chien, Prairie Du Chien Municipal Arpt [PCD], United States of America",
        "Praia, Praia International Airport [RAI], Cape Verde Islands",
        "Praha, Phrae Arpt [PRH], Thailand",
        "Prague, Ruzyne Arpt [PRG], Czech Republic",
        "Poznan, Lawica Arpt [POZ], Poland",
        "Poza Rica, Tajin Arpt [PAZ], Mexico",
        "Powell River, Westview Arpt [YPW], Canada",
        "Poughkeepsie, Dutchess County [POU], United States of America",
        "Pottstown, Pottstown Limerick [PTW], United States of America",
        "Poste De La Baleine, Kuujjuaraapik Arpt [YGW], Canada",
        "Posadas, Posadas Arpt [PSS], Argentina",
        "Portsmouth, Portsmouth [PME], United Kingdom",
        "Portsmouth (NH), Portsmouth Pease Intl Arpt [PSM], United States of America",
        "Portoroz, Portoroz Airport [POW], Slovenia",
        "Portoheli, Alexion Arpt [PKH], Greece",
        "Porto, Porto Airport [OPO], Portugal",
        "Porto Velho, Belmonte Arpt [PVH], Brazil",
        "Porto Seguro, Porto Seguro Arpt [BPS], Brazil",
        "Porto Santo, Porto Santo Arpt [PXO], Portugal",
        "Porto Alegre, Porto Alegre Airport [POA], Brazil",
        "Portland, Portland Intl Jetport [PWM], United States of America",
        "Portland, Portland Arpt [PTJ], Australia",
        "Portland (OR), Portland Intl Arpt [PDX], United States of America",
        "Portimao, Portimao Airport [PRM], Portugal",
        "Port of Spain, Piarco Arpt [POS], Trinidad And Tobago",
        "Port Vila, Bauerfield Arpt [VLI], Vanuatu",
        "Port Pirie, Port Porie Arpt [PPI], Australia",
        "Port Moresby, Jackson Field [POM], Papua New Guinea",
        "Port Menier, Port Menier Arpt [YPN], Canada",
        "Port Lincoln, Port Lincoln Arpt [PLO], Australia",
        "Port Huron, St Clair County Intl Arpt [PHN], United States of America",
        "Port Hedland, Port Hedland  Arpt [PHE], Australia",
        "Port Hawkesbury, Port Hawkesbury Arpt [YPS], Canada",
        "Port Hardy, Port Hardy Municipal [YZT], Canada",
        "Port Harcourt, Port Harcourt City Arpt [PHG], Nigeria",
        "Port Harcourt, Port Harcourt Arpt [PHC], Nigeria",
        "Port Gentil, Port Gentil Arpt [POG], Gabon",
        "Port Elizabeth, Port Elizabeth Airport [PLZ], South Africa",
        "Port Douglas, Port Douglas Arpt [PTI], Australia",
        "Port Blair, Port Blair Arpt [IXZ], India",
        "Port Augusta, Port Augusta Arpt [PUG], Australia",
        "Port Au Prince, Mais Gate Arpt [PAP], Haiti",
        "Port Anageles, William Fairchild Intl Arpt [CLM], United States of America",
        "Port Alberni, Port Alberni Arpt [YPB], Canada",
        "Porlamar, Delcaribe Gen S Marino Arpt [PMV], Venezuela",
        "Pori, Pori Airport [POR], Finland",
        "Porbandar, Porbandar Arpt [PBD], India",
        "Poprad Tatry, Poprad Tatry Arpt [TAT], Slovakia",
        "Poplar Bluff, Earl Fields Memorial [POF], United States of America",
        "Popayan, Machangara Arpt [PPN], Colombia",
        "Pontoise, Cormeille En Vexin [POX], France",
        "Pontiac, Oakland Pontiac Arpt [PTK], United States of America",
        "Ponta Pora, International [PMG], Brazil",
        "Pondicherry, Pondicherry Airport [PNY], India",
        "Ponce, Mercedita Arpt [PSE], Puerto Rico",
        "Ponca City, Ponca City Municipal Arpt [PNC], United States of America",
        "Pompano Beach, Pompano Beach Arpt [PPM], United States of America",
        "Poltava, Poltava Arpt [PLV], Ukraine",
        "Pokhara, Pokhara Arpt [PKR], Nepal",
        "Poitiers, Biard Airport [PIS], France",
        "Pointe Noire, Pointe Noire Arpt [PNR], Congo",
        "Pointe A Pitre, Le Raizet Arpt [PTP], Guadeloupe",
        "Point Lay, Dew Station Arpt [PIZ], United States of America",
        "Pohnpei, Pohnpei Arpt [PNI], Micronesia",
        "Pohang, Pohang Arpt [KPO], Korea, Republic of",
        "Podgorica, Golubovci Arpt [TGD], Serbia and Montenegro",
        "Pocatello, Pocatello Municipal Arpt [PIH], United States of America",
        "Plymouth, Plymouth [PLH], United Kingdom",
        "Plymouth (IN), Plymouth Municipal Arpt [PLY], United States of America",
        "Plovdiv, Plovdiv Arpt [PDV], Bulgaria",
        "Plettenberg Bay, Plettenberg Bay Arpt [PBZ], South Africa",
        "Pleiku, Pleiku Arpt [PXU], Vietnam",
        "Playa Grande, Playa Grand Arpt [PKJ], Guatemala",
        "Playa Del Carmen, Playa Del Carmen Arpt [PCM], Mexico",
        "Plattsburg, Clinton Cty Municipal [PLB], United States of America",
        "Plainview (TX), Hale County Arpt [PVW], United States of America",
        "Placencia, Placencia Arpt [PLJ], Belize",
        "Piura, Piura Arpt [PIU], Peru",
        "Pittsfield, Pittsfield Municipal [PSF], United States of America",
        "Pittsburgh, Pittsburgh Intl Arpt [PIT], United States of America",
        "Pittsburgh, Allegheny Cty Arpt [AGC], United States of America",
        "Pittsburg, Atkinson Municipal Arpt [PTS], United States of America",
        "Pisa, Gal Galilei Arpt [PSA], Italy",
        "Pinotepa Nacional, Pinotepa Nacional Arpt [PNO], Mexico",
        "Pine Bluff, Pine Bluff Arpt [PBF], United States of America",
        "Pilanesburg, Pilansberg Arpt [NTY], South Africa",
        "Pietersburg, Pietersburg Arpt [PTG], South Africa",
        "Pietermaritzburg, Pietermaritzburg Arpt [PZB], South Africa",
        "Piestany, Piestany Arpt [PZY], Slovakia",
        "Pierre, Pierre Municipal [PIR], United States of America",
        "Piedras Negras, Piedras Negras Intl Arpt [PDS], Mexico",
        "Picton, Koromiko [PCN], New Zealand",
        "Pico Island (Azores), Pico Arpt [PIX], Portugal",
        "Pickle Lake, Provincial Airport [YPL], Canada",
        "Phuket, Phuket Intl Airport [HKT], Thailand",
        "Phu Quoc, Duong Dang Arpt [PQC], Vietnam",
        "Phoenix, Sky Harbor Intl Arpt [PHX], United States of America",
        "Phnom Penh, Pochentong Arpt [PNH], Cambodia",
        "Phitsanulok, Phitsanulok Arpt [PHS], Thailand",
        "Philipsburg, Midstate Arpt [PSB], United States of America",
        "Philadelphia, Philadelphia Intl Arpt [PHL], United States of America",
        "Philadelphia, N Philadelphia [PNE], United States of America",
        "Phaplu, Phaplu Airport [PPL], Nepal",
        "Phan Thiet, Phan Thiet Arpt [PHH], Vietnam",
        "Phalaborwa, Phalaborwa Airport [PHW], South Africa",
        "Petrozavodsk, Petrozavodsk Arpt [PES], Russian Federation",
        "Petropavlovsk, Petropavlovsk Arpt [PPK], Kazakhstan",
        "Petropavlovsk Kamchatskiy, Petropavlovsk Kamchatskiy Arpt [PKC], Russian Federation",
        "Petrolina, Petrolina Internacional Arpt [PNZ], Brazil",
        "Petra, King Hussein Airport [OMF], United Arab Emirates",
        "Petersburg, Petersburg Municipal [PSG], United States of America",
        "Petersburg, Petersburg Municipal Arpt [PTB], United States of America",
        "Peterborough, Peterborough Arpt [YPQ], Canada",
        "Peshawar, Peshawar Arpt [PEW], Pakistan",
        "Peschiei, Peschiei Arpt [PEJ], Italy",
        "Pescara, Liberi Airport [PSR], Italy",
        "Perugia, Sant Egidio Arpt [PEG], Italy",
        "Perth, Perth [PSL], United Kingdom",
        "Perth, Perth Arpt [PER], Australia",
        "Perth , ScotRail [ZXP], United Kingdom",
        "Perpignan, Llabanere Arpt [PGF], France",
        "Perm, Perm Arpt [PEE], Russian Federation",
        "Perigueux, Perigueux Arpt [PGX], France",
        "Pereira, Matecana Arpt [PEI], Colombia",
        "Peoria, Greater Peoria Arpt [PIA], United States of America",
        "Penzance, Penzance [PZE], United Kingdom",
        "Penza, Penza Arpt [PEZ], Russian Federation",
        "Penticton, Penticton Municipal Arpt [YYF], Canada",
        "Pensacola, Pensacola Regional Municipal [PNS], United States of America",
        "Penglai, Sha He Kou Arpt [PNJ], China",
        "Pendleton, Pendleton Municipal [PDT], United States of America",
        "Penang, Penang Intl Arpt [PEN], Malaysia",
        "Pembroke, Pembroke And Area [YTA], Canada",
        "Pelotas, Pelotas Federal Arpt [PET], Brazil",
        "Pellston, Emmet Cty Arpt [PLN], United States of America",
        "Pekanbaru, Simpang Tiga Arpt [PKU], Indonesia",
        "Peenemuende, Peenemuende Arpt [PEF], Germany",
        "Pecs, Pecs Arpt [PEV], Hungary",
        "Peaca River, Peace River Municipal Arpt [YPE], Canada",
        "Payson (AZ), Payson Arpt [PJB], United States of America",
        "Paysandu, Paysandu Arpt [PDU], Uruguay",
        "Pavlodar, Pavlodar Arpt [PWQ], Kazakhstan",
        "Paukuashipi, Pakuashipi Arpt [YIF], Canada",
        "Pau, Uzein Airport [PUF], France",
        "Pattaya, Pattaya Arpt [PYX], Thailand",
        "Patras, Araxos Arpt [GPA], Greece",
        "Pato Branco, Pato Branco Municipal [PTO], Brazil",
        "Patna, Patna Arpt [PAT], India",
        "Pasto, Cano Arpt [PSO], Colombia",
        "Passo Fundo, Passo Fundo Arpt [PFB], Brazil",
        "Paso Robles, Paso Robles Cty [PRB], United States of America",
        "Paso De Los Libres, Paso De Los Libres Arpt [AOL], Argentina",
        "Pasco, Tri Cities Arpt [PSC], United States of America",
        "Pascagoula, Jackson Cnty [PGL], United States of America",
        "Parry Sound, Parry Sound Municipal Arpt [YPD], Canada",
        "Paros, Paros Community Arpt [PAS], Greece",
        "Paro, Paro Arpt [PBH], Bhutan",
        "Parnu, Parnu Arpt [EPU], Estonia",
        "Parnaiba, Santos Dumont Airport [PHB], Brazil",
        "Parma, Parma Arpt [PMF], Italy",
        "Parkes, Parkes Arpt [PKE], Australia",
        "Parkersburg, Wood County [PKB], United States of America",
        "Park Rapids, Park Rapids Municipal Arpt [PKD], United States of America",
        "Paris, Orly Arpt [ORY], France",
        "Paris, Disneyland Paris Rail [XED], France",
        "Paris, Charles De Gaulle Intl Arpt [CDG], France",
        "Paris, All Airports [PAR], France",
        "Pardubice, Pardubice Arpt [PED], Czech Republic",
        "Paraparaumu, Paraparaumu Arpt [PPQ], New Zealand",
        "Paranagua, Paranagua Municipal [PNG], Brazil",
        "Parana, Parana Arpt [PRA], Argentina",
        "Paramaribo, Zorg En Hoop Arpt [ORG], Suriname",
        "Paramaribo, Zanderij Intl Arpt [PBM], Suriname",
        "Paraburdoo, Paraburdoo Arpt [PBO], Australia",
        "Paphos, Paphos Intl Airport [PFO], Cyprus",
        "Papeete, Intl Tahiti Faaa [PPT], French Polynesia",
        "Papa Westray, Papa Westray [PPW], United Kingdom",
        "Papa Stour, Papa Stour [PSV], United Kingdom",
        "Pangnirtung, Pangnirtung Arpt [YXP], Canada",
        "Pangkor, Pangkor Arpt [PKG], Malaysia",
        "Panama City, Tocumen Intl Arpt [PTY], Panama",
        "Panama City, Paitilla Arpt [PAC], Panama",
        "Panama City, Bay County Arpt [PFN], United States of America",
        "Pamplona, Pamplona Noain Arpt [PNA], Spain",
        "Palo Alto, Palo Alto Arpt [PAO], United States of America",
        "Palmerston  North, Palmerston North Arpt [PMR], New Zealand",
        "Palmdale, Airforce 42 [PMD], United States of America",
        "Palmas, Palmas Arpt [PMW], Brazil",
        "Palm Springs, Palm Springs Municipal [PSP], United States of America",
        "Palm Desert, Bermuda Dunes Arpt [UDD], United States of America",
        "Palermo, Punta Raisi Arpt [PMO], Italy",
        "Palenque, Palenque Arpt [PQM], Mexico",
        "Palembang, Mahmud Badaruddin Li Arpt [PLM], Indonesia",
        "Palanga, Palanga Intl Arpt [PLQ], Lithuania",
        "Painesville, Casement Arpt [PVZ], United States of America",
        "Pagosa Springs, Stevens Field Arpt [PGO], United States of America",
        "Pago Pago, Pago Pago Arpt [PPG], American Samoa",
        "Page, Page Airport [PGA], United States of America",
        "Paducah, Barkley Regional [PAH], United States of America",
        "Paderborn, Paderborn Airport [PAD], Germany",
        "Padang, Tabing Arpt [PDG], Indonesia",
        "Oxnard, Oxnard Ventura Arpt [OXR], United States of America",
        "Oxford, Kidlington [OXF], United Kingdom",
        "Oxford (MS), University Oxford [UOX], United States of America",
        "Owerri, Sam Mbakwe International [QOW], Nigeria",
        "Owensboro, Daviess County Arpt [OWB], United States of America",
        "Owen Sound, Billy Bishop Regional Arpt [YOS], Canada",
        "Owatonna, Owatonna Arpt [OWA], United States of America",
        "Oviedo, Asturias Airport [OVD], Spain",
        "Ovda, Ovda Arpt [VDA], Israel",
        "Outer Skerries, Outer Skerries [OUK], United Kingdom",
        "Ourilandia, Ourilandia Arpt [OIA], Brazil",
        "Oulu, Oulu Airport [OUL], Finland",
        "Oujda, Les Angades Arpt [OUD], Morocco",
        "Oudtshoorn, Oudtshoorn Arpt [OUH], South Africa",
        "Ouarzazate, Ourzazate [OZZ], Morocco",
        "Ouagadougou, Ouagadougou Arpt [OUA], Burkina Faso",
        "Ottumwa, Industrial Arpt [OTM], United States of America",
        "Ottawa, Ottawa Rail Station [XDS], Canada",
        "Ottawa, Ottawa Intl Arpt [YOW], Canada",
        "Ostrava, Mosnov Arpt [OSR], Czech Republic",
        "Ostersund, Froesoe Airport [OSD], Sweden",
        "Ostende, Ostend Airport [OST], Belgium",
        "Osorno, Canal Balo Arpt [ZOS], Chile",
        "Oslo, Sandefjord Arpt [TRF], Norway",
        "Oslo, Oslo Arpt [OSL], Norway",
        "Oskarshamn, Oskarshamn [OSK], Sweden",
        "Osijek, Osijek Airport [OSI], Croatia",
        "Oshkosh (WI), Wittman Field [OSH], United States of America",
        "Oshima, Oshima Arpt [OIM], Japan",
        "Oshawa, Oshawa Municipal Arpt [YOO], Canada",
        "Osaka, Kansai International Arpt [KIX], Japan",
        "Osaka, Itami Arpt [ITM], Japan",
        "Orsta Volda, Hovden Arpt [HOV], Norway",
        "Orpheus Island, Waterport Arpt [ORS], Australia",
        "Ornskoldsvick, Ornskoldsvik Arpt [OER], Sweden",
        "Orlando, Orlando Intl Arpt [MCO], United States of America",
        "Orlando, Herndon Arpt [ORL], United States of America",
        "Oriskany/Utica, Oneida County Arpt [UCA], United States of America",
        "Orebro, Orebro Bofors Arpt [ORB], Sweden",
        "Oranjestad, Reina Beatrix Arpt [AUA], Aruba",
        "Oranjemund, Oranjemund Arpt [OMD], Namibia",
        "Orange, Springhill Arpt [OAG], Australia",
        "Oran, Es Senia [ORN], Algeria",
        "Oradea, Oradea Arpt [OMR], Romania",
        "Ontario, Ontario Intl [ONT], United States of America",
        "Ontario, London International Airport [YXU], Canada",
        "Oneonta, Oneonta Municpal [ONH], United States of America",
        "Omsk, Omsk Arpt [OMS], Russian Federation",
        "Omaha, Millard Airport [MIQ], United States of America",
        "Omaha, Eppley Airfield [OMA], United States of America",
        "Olympic Dam, Olympic Dam Arpt [OLP], Australia",
        "Olympia, Olympia Arpt [OLM], United States of America",
        "Olsztyn, Olsztyn Arpt [QYO], Poland",
        "Olbia (Sardinia), Costa Smeralda Arpt [OLB], Italy",
        "Oklahoma City, Will Rogers World Arpt [OKC], United States of America",
        "Oklahoma City, Wiley Post Arpt [PWA], United States of America",
        "Oklahoma City, Downtown Airpark [DWN], United States of America",
        "Okino Erabu, Okino Erabu Arpt [OKE], Japan",
        "Okinawa, Naha Field [OKA], Japan",
        "Okeechobee, Okeechobee County Arpt [OBE], United States of America",
        "Okayama, Okayama Arpt [OKJ], Japan",
        "Oita, Oita Arpt [OIT], Japan",
        "Ohrid, Ohrid Arpt [OHD], Macedonia",
        "Ogdensburg, Ogdensburg Municipal [OGS], United States of America",
        "Ogden, Ogden Municipal [OGD], United States of America",
        "Ogallala, Searle Field [OGA], United States of America",
        "Odessa, Central Arpt [ODS], Ukraine",
        "Odense, Odense Airport [ODE], Denmark",
        "Odate Noshiro, Odate Noshiro Arpt [ONJ], Japan",
        "Ocho Rios, Boscobel Arpt [OCJ], Jamaica",
        "Oceanside, Oceanside Municipal Arpt [OCN], United States of America",
        "Ocean Reef, Ocean Reef Arpt [OCA], United States of America",
        "Ocean City, Ocean City Airport [OCE], United States of America",
        "Ocala, Taylor Field Arpt [OCF], United States of America",
        "Obihiro, Obihiro Arpt [OBO], Japan",
        "Oban, Oban [OBN], United Kingdom",
        "Oban, Heliport [OHP], United Kingdom",
        "Oaxaca, Xoxocotlan Arpt [OAX], Mexico",
        "Oamaru, Oamaru Airport [OAM], New Zealand",
        "Oakland (CA), Metro Oakland Intl Arpt [OAK], United States of America",
        "Oak Harbour, Oak Harbour Municipal [ODW], United States of America",
        "Nyngan, Nyngan Arpt [NYN], Australia",
        "Nyeri, Nyeri Arpt [NYE], Kenya",
        "Nyaung-U, Nyaung U Arpt [NYU], Myanmar",
        "Nuuk, Nuuk Arpt [GOH], Greenland",
        "Nuremberg, Nuremberg Arpt [NUE], Germany",
        "Nullarbor, Nullarbor Arpt [NUR], Australia",
        "Nulato, Nulato Arpt [NUL], United States of America",
        "Nuku'Alofa, Tongatapu Intl [TBU], Tonga",
        "Nuevo Laredo, Quetzalcoatl Intl [NLD], Mexico",
        "Nueva Gerona, Rafael Cabrera Arpt [GER], Cuba",
        "Nowra, Nowra Arpt [NOA], Australia",
        "Novosibirsk, Tolmachevo Arpt [OVB], Russian Federation",
        "Novo Hamburgo, Novo Hamburgo Arpt [QHV], Brazil",
        "Noumea, Tontouta Arpt [NOU], New Caledonia",
        "Noumea, Magenta Arpt [GEA], New Caledonia",
        "Nouakchott, Nouakchott Arpt [NKC], Mauritania",
        "Nouadhibou, Nouadhibou Arpt [NDB], Mauritania",
        "Nottingham, Railway Station [XQI], United Kingdom",
        "Nottingham, Railway Station [XQH], United Kingdom",
        "Nottingham Uk, Nottingham [NQT], United Kingdom",
        "Nottingham Uk, East Midlands Arpt [EMA], United Kingdom",
        "Notodden, Notodden Arpt [NTB], Norway",
        "Nossi Be, Fascene Arpt [NOS], Madagascar",
        "Norwood, Norwood Ma Arpt [OWD], United States of America",
        "Norwich, Norwich Airport [NWI], United Kingdom",
        "Northolt, London RAF Northolt Jet Centre [NHT], United Kingdom",
        "Northampton, Northampton [ORM], United Kingdom",
        "North Ronaldsay, North Ronaldsay [NRL], United Kingdom",
        "North Plate, Lee Bird Field [LBF], United States of America",
        "North Eleuthera, North Eleuthera Intl [ELH], Bahamas",
        "North Caicos, North Caicos Municipal Arpt [NCA], Turks And Caicos Islands",
        "North Bend, North Bend Municipal [OTH], United States of America",
        "North Bay, North Bay Municipal [YYB], Canada",
        "North Battleford, North Battleford Arpt [YQW], Canada",
        "Norseman, Norseman Arpt [NSM], Australia",
        "Norrkoping, Kungsangen Arpt [NRK], Sweden",
        "Normanton, Normanton Arpt [NTN], Australia",
        "Norman Wells, Norman Wells Municipal Arpt [YVQ], Canada",
        "Norfolk Island, Norfolk Island Intl Arpt [NLK], Australia",
        "Norfolk (VA), Norfolk Intl Arpt [ORF], United States of America",
        "Norfolk (NE), Karl Stefan Fld [OFK], United States of America",
        "Noosa, Noosa Airport [NSA], Australia",
        "Nome, Nome Arpt [OME], United States of America",
        "Nojabrxsk, Nojabrxsk Arpt [NOJ], Russian Federation",
        "Nogales, Nogales Arpt [NOG], Mexico",
        "Nogales, International Arpt [OLS], United States of America",
        "Nnakashibetsu, Nakashibetsu Arpt [SHB], Japan",
        "Nizhniy Novgorod, Nizhniy Novgorod Arpt [GOJ], Russian Federation",
        "Nizhnevartovsk, Nizhnevartovsk Arpt [NJC], Russian Federation",
        "Niue Island, Hanan Arpt [IUE], Niue Island",
        "Niteroi, Niteroi Arpt [QNT], Brazil",
        "Nis Yu, Nis Arpt [INI], Serbia and Montenegro",
        "Ningbo, Ningbo Arpt [NGB], China",
        "Nimes, Nimes Airport [FNI], France",
        "Niigata, Niigata Arpt [KIJ], Japan",
        "Nice, Cote D Azur Arpt [NCE], France",
        "Niamey, Niamey Airport [NIM], Niger",
        "Niagara Falls (NY), Niagara Falls Intl Arpt [IAG], United States of America",
        "Nha Trang, Nha Trang Arpt [NHA], Vietnam",
        "Newton, Municipal Newton Arpt [TNU], United States of America",
        "Newton (KS), Newton City County Arpt [EWK], United States of America",
        "Newquay, Cornwall Airport Newquay [NQY], United Kingdom",
        "Newport, Newport Railway Station [XNE], United Kingdom",
        "Newport News, Williamsburg Intl Arpt [PHF], United States of America",
        "Newport (RI), Newport State Arpt [NPT], United States of America",
        "Newport (OR), Newport Municipal Arpt [ONP], United States of America",
        "Newman, Newman Airport [ZNE], Australia",
        "Newcastle, Williamtown Arpt [NTL], Australia",
        "Newcastle, Newcastle Intl Arpt [NCS], South Africa",
        "Newcastle, Newcastle Arpt [NCL], United Kingdom",
        "Newcastle, Belmont Airport [BEO], Australia",
        "Newburgh, Stewart Airport [SWF], United States of America",
        "New York, Newark Intl Arpt [EWR], United States of America",
        "New York, La Guardia [LGA], United States of America",
        "New York, John F Kennedy Intl [JFK], United States of America",
        "New York, All Airports [NYC], United States of America",
        "New Ulm, New Ulm Arpt [ULM], United States of America",
        "New Richmond, New Richmond Municipal [RNH], United States of America",
        "New Plymouth, New Plymouth Arpt [NPL], New Zealand",
        "New Orleans, New Lakefront Arpt [NEW], United States of America",
        "New Orleans, Louis Armstrong Intl Arpt [MSY], United States of America",
        "New Haven, Tweed New Haven Arpt [HVN], United States of America",
        "New Bern, Simmons Nott Arpt [EWN], United States of America",
        "New Bedford, New Bedford Municipal [EWB], United States of America",
        "Nevsehir, Nevsehir Arpt [NAV], Turkey",
        "Nevis, Nevis Airport [NEV], St.Kitts And Nevis",
        "Nevers, Nevers Arpt [NVS], France",
        "Neuquen, Neuquen Airport [NQN], Argentina",
        "Nelspruit, Nelspruit Airport [NLP], South Africa",
        "Nelspruit, Kruger Mpumalanga Intl Arpt [MQP], South Africa",
        "Nelson, Nelson Arpt [NSN], New Zealand",
        "Nejran, Nejran Arpt [EAM], Saudi Arabia",
        "Negril, Negril Arpt [NEG], Jamaica",
        "Nefteyugansk, Nefteyugansk Arpt [NFG], Russian Federation",
        "Needles, Needles Arpt [EED], United States of America",
        "Naxos, Naxos Arpt [JNX], Greece",
        "Nawabshah, Nawabshah Arpt [WNS], Pakistan",
        "Navegantes, Navegantes Arpt [NVT], Brazil",
        "Nauru Island, Nauru Intl Arpt [INU], Nauru",
        "Natchez, Natchez Adams Cty [HEZ], United States of America",
        "Natashquan, Natashquan Arpt [YNA], Canada",
        "Natal, Augusto Severo Intl Arpt [NAT], Brazil",
        "Nassau, Seaplane Base Arpt [WZY], Bahamas",
        "Nassau, Paradise Island Arpt [PID], Bahamas",
        "Nassau, Nassau Intl [NAS], Bahamas",
        "Nasik, Nashik Airport [ISK], India",
        "Nashville, Nashville International Arpt [BNA], United States of America",
        "Nashua, Boire Field Arpt [ASH], United States of America",
        "Narvik, Framnes Arpt [NVK], Norway",
        "Narssarssuaq, Narsarsuaq Arpt [UAK], Greenland",
        "Narsaq, Narsaq Heleport [JNS], Greenland",
        "Narrandera, Narrandera Arpt [NRA], Australia",
        "Narrabri, Narrabri Arpt [NAA], Australia",
        "Nare, Nare Arpt [NAR], Colombia",
        "Narathiwat, Narathiwat Arpt [NAW], Thailand",
        "Naracoorte, Naracoorte Arpt [NAC], Australia",
        "Naples, Naples Municipal [APF], United States of America",
        "Naples, Capodichino Arpt [NAP], Italy",
        "Napier-Hastings, Hawkes Bay Arpt [NPE], New Zealand",
        "Napa, Napa Cty Arpt [APC], United States of America",
        "Nanyuki, Nanyuki Arpt [NYK], Kenya",
        "Nantucket, Nantucket Memorial [ACK], United States of America",
        "Nantes, Nantes Atlantique [NTE], France",
        "Nanortalik, Nanortalik Arpt [JNN], Greenland",
        "Nanning, Nanning Airport [NNG], China",
        "Nanjing, Nanjing Airport [NKG], China",
        "Nanded, Nanded Airport [NDC], India",
        "Nancy, Essey Airport [ENC], France",
        "Nanchang, Nanchang Airport [KHN], China",
        "Nanaimo, Nanaimo Arpt [YCD], Canada",
        "Nanaimo, Harbour Seaplane Base [ZNA], Canada",
        "Nan, Nan Arpt [NNT], Thailand",
        "Namsos, Namsos Arpt [OSY], Norway",
        "Nampula, Nampula Arpt [APL], Mozambique",
        "Namlea, Namlea Arpt [NAM], Indonesia",
        "Namdrik, Namdrik Arpt [NDK], Marshall Islands",
        "Nalchik, Nalchik Arpt [NAL], Russian Federation",
        "Nakhon si Thammarat, Nakhon Si Thammarat Arpt [NST], Thailand",
        "Nakhon Ratchasima, Nakhon Ratchasima Arpt [NAK], Thailand",
        "Nakhon Phanom, Nakhon Phanom Arpt [KOP], Thailand",
        "Nairobi, Wilson Airport [WIL], Kenya",
        "Nairobi, Jomo Kenyatta Intl [NBO], Kenya",
        "Nain, Nain Arpt [YDP], Canada",
        "Nagpur, Sonegaon Arpt [NAG], India",
        "Nagoya, Nagoya Komaki Arpt [NKM], Japan",
        "Nagoya, Nagoya Chubu Centrair Intl Arpt [NGO], Japan",
        "Nagasaki, Nagasaki Airport [NGS], Japan",
        "Nador, Nador Arpt [NDR], Morocco",
        "Nadi, Nadi Intl [NAN], Fiji",
        "Nacala, Nacala Airport [MNC], Mozambique",
        "Nabervnye Chelny, Nijnekamsk Arpt [NBC], Russian Federation",
        "N Gaoundere, N Gaoundere Arpt [NGE], Cameroon",
        "N Dola, Ndola Arpt [NLA], Zambia",
        "N Djamena, N Djamena Arpt [NDJ], Chad",
        "Mytliene, Mytilene Arpt [MJT], Greece",
        "Mysore, Mysore Airport [MYQ], India",
        "Myrtle Beach, Myrtle Beach Jetway [MYR], United States of America",
        "Myrtle Beach, Grand Strand Arpt [CRE], United States of America",
        "Myrtle Beach, George Town Arpt [GGE], United States of America",
        "Mykonos-Mykonos Town, Mykonos Greece Arpt [JMK], Greece",
        "Myall Lakes, McCarthy Arpt [MXY], Australia",
        "Mwanza, Mwanza Arpt [MWZ], Tanzania",
        "Mutare, Mutare Arpt [UTA], Zimbabwe",
        "Mustique, Mustique Arpt [MQS], St. Vincent And Grenadines",
        "Muskoka, Muskoka Arpt [YQA], Canada",
        "Muskogee, Davis Field [MKO], United States of America",
        "Muskegon, Muskegon Cty Intl [MKG], United States of America",
        "Muscle Shoals, Muscle Shoals Arpt [MSL], United States of America",
        "Muscat, Seeb Intl [MCT], Oman",
        "Murray (KY), Calloway Cty Arpt [CEY], United States of America",
        "Murmansk, Murmansk Arpt [MMK], Russian Federation",
        "Murcia, San Javier Airport [MJV], Spain",
        "Munster, Mus Arpt [MSR], Germany",
        "Munich, Munich Intl Arpt [MUC], Germany",
        "Muncie, Johnson Field [MIE], United States of America",
        "Mumbai, Chhatrapati Shivaji Intl [BOM], India",
        "Mulu, Mulu Arpt [MZV], Malaysia",
        "Multan, Multan Intl Airport [MUX], Pakistan",
        "Mull, Mull [ULL], United Kingdom",
        "Mulhouse, Mulhouse EuroAirport French [MLH], France",
        "Mukalla, Mukalla Arpt [MKX], Yemen",
        "Mukah, Mukah Arpt [MKM], Malaysia",
        "Muharraq Town, Muharraq Arpt [GBQ], Bahrain",
        "Muenster, Muenster Airport [FMO], Germany",
        "Mudgee, Mudgee Arpt [DGE], Australia",
        "Mudanjiang, Mudanjiang Arpt [MDG], China",
        "Mtwara, Mtwara Arpt [MYW], Tanzania",
        "Mt Hagen, Kagamuga Arpt [HGU], Papua New Guinea",
        "Mountain Village, Mountain Village Arpt [MOU], United States of America",
        "Mountain View, Moffett Field [NUQ], United States of America",
        "Mountain Home (ID), Mountain Home AFB [MUO], United States of America",
        "Mountain Home (AR), Mountain Home Arpt [WMH], United States of America",
        "Mount Vernon (IL), Mt Vernon Outland Arpt [MVN], United States of America",
        "Mount Pleasant, Mount Pleasant Arpt [MPN], Falkland Islands",
        "Mount Pleasant (MI), Mt Pleasant Municipal [MOP], United States of America",
        "Mount Magnet, Mount Magnet Arpt [MMG], Australia",
        "Mount Isa, Mount Isa Arpt [ISA], Australia",
        "Mount Hotham, Mount Hotham Arpt [MHU], Australia",
        "Mount Holly, Burlington Country Arpt [LLY], United States of America",
        "Mount Gambier, Mount Gambier Arpt [MGB], Australia",
        "Mount Cook, Mount Cook Arpt [MON], New Zealand",
        "Moultrie, Moultrie Arpt [MGR], United States of America",
        "Mostar, Mostar Arpt [OMO], Bosnia Hercegovina",
        "Mossoro, Dixsept Rosado Arpt [MVF], Brazil",
        "Mosjoen, Kjaerstad Arpt [MJF], Norway",
        "Mosinee/Wausau, Wausau Municipal Arpt [AUW], United States of America",
        "Mosinee/Wausau, Central Wisconsin Arpt [CWA], United States of America",
        "Moses Lake, Grant County [MWH], United States of America",
        "Moscow, Zhukovsky International [ZIA], Russian Federation",
        "Moscow, Vnukovo Arpt [VKO], Russian Federation",
        "Moscow, Sheremetyevo Arpt [SVO], Russian Federation",
        "Moscow, Domodedovo Arpt [DME], Russian Federation",
        "Moscow, Bykovo Arpt [BKA], Russian Federation",
        "Moscow, All Airports [MOW], Russian Federation",
        "Moruya, Moruya Arpt [MYA], Australia",
        "Morristown (NJ), Morristown Arpt [MMU], United States of America",
        "Moroni, Prince Said Ibrahim In [HAH], Comoros",
        "Moroni, Iconi Arpt [YVA], Comoros",
        "Morondava, Morondava Arpt [MOQ], Madagascar",
        "Morlaix, Morlaix Arpt [MXN], France",
        "Morioka, Hanamaki Arpt [HNA], Japan",
        "Morgantown (WV), Morgantown Municipal [MGW], United States of America",
        "Morelia, Michoacan Municipal Arpt [MLM], Mexico",
        "Moree, Moree Arpt [MRZ], Australia",
        "Moranbah, Moranbah Arpt [MOV], Australia",
        "Mora, Mora Arpt [MXX], Sweden",
        "Mopti, Mopti Airport [MZI], Mali",
        "Moosonee, Moosonee Arpt [YMO], Canada",
        "Moorea, Temae Airport [MOZ], French Polynesia",
        "Moorabbin, Moorabbin Arpt [MBW], Australia",
        "Montserrat, Bramble Arpt [MNI], Dominica",
        "Montrose, Montrose County [MTJ], United States of America",
        "Montreal, St Hubert Arpt [YHU], Canada",
        "Montreal, Pierre Elliott Trudeau Intl Arpt [YUL], Canada",
        "Montreal, Mirabel Intl Arpt [YMX], Canada",
        "Montpellier, Frejorgues Arpt [MPL], France",
        "Montpelier, E F Knapp Arpt [MPV], United States of America",
        "Monto, Monto Arpt [MNQ], Australia",
        "Montlucon, Gueret Arpt [MCU], France",
        "Monticello, Catskills Sulivan [MSV], United States of America",
        "Montgomery (AL), Dannelly Field [MGM], United States of America",
        "Montevideo, Carrasco Arpt [MVD], Uruguay",
        "Montes Claros, Montes Claros Arpt [MOC], Brazil",
        "Monterrey, Escobedo Arpt [MTY], Mexico",
        "Monterrey, Aeropuerto Del Norte [NTR], Mexico",
        "Monteria, S Jeronimo Arpt [MTR], Colombia",
        "Monterey, Monterey Peninsula [MRY], United States of America",
        "Montego Bay, Sangster Arpt [MBJ], Jamaica",
        "Monte Dourado, Monte Dourado Arpt [MEU], Brazil",
        "Monte Carlo, Hel De Monte Carlo Airport [MCM], Monaco",
        "Montauk, Sky Portal Arpt [MTP], United States of America",
        "Mont Tremblant, La Macaza [YTM], Canada",
        "Mont Joli, Mont Joli Arpt [YYY], Canada",
        "Monrovia, Sprigg Payne Arpt [MLW], Liberia",
        "Monrovia, Roberts Intl [ROB], Liberia",
        "Monroeville (AL), Monroe County Arpt [MVC], United States of America",
        "Monore, Monroe Regional [MLU], United States of America",
        "Moncton, Moncton Municipal [YQM], Canada",
        "Monclova, Monclova Arpt [LOV], Mexico",
        "Monastir, Habib Bourguiba Intl [MIR], Tunisia",
        "Mombasa, Moi Intl [MBA], Kenya",
        "Moline, Quad City Arpt [MLI], United States of America",
        "Molde, Aro Arpt [MOL], Norway",
        "Mokpo, Mokpo Arpt [MPK], Korea, Republic of",
        "Mojave, Kern County Arpt [MHV], United States of America",
        "Mohenjodaro, Mohenjodaro Arpt [MJD], Pakistan",
        "Mogilev, Mogilev Arpt [MVQ], Belarus",
        "Mogadishu, Mogadishu International Airport [MGQ], Somalia",
        "Modlin, Warsaw Modlin Airport [WMI], Poland",
        "Modesto, Harry Sham Fld [MOD], United States of America",
        "Mobile, Mobile Municipal [MOB], United States of America",
        "Moab, Moab Municipal [CNY], United States of America",
        "Mo i Rana, Rossvoll [MQN], Norway",
        "Mmabatho, Mmabatho International Arpt [MBD], South Africa",
        "Miyazaki, Miyazaki Arpt [KMI], Japan",
        "Miyako Jima Island, Hirara Arpt [MMY], Japan",
        "Mitiaro Island, Mitiaro Island Arpt [MOI], Cook Islands",
        "Mitchell, Mitchell Municipal [MHE], United States of America",
        "Missoula, Missoula Intl [MSO], United States of America",
        "Miskolc, Miskolc Arpt [MCQ], Hungary",
        "Misawa, Misawa Arpt [MSJ], Japan",
        "Miri, Miri Arpt [MYY], Malaysia",
        "Minsk, Minsk Intl 2 [MSQ], Belarus",
        "Minsk, Minsk Intl 1 [MHP], Belarus",
        "Minot, Minot Intl [MOT], United States of America",
        "Minneapolis, Minneapolis St Paul Intl [MSP], United States of America",
        "Minneapolis, Flying Cloud Arpt [FCM], United States of America",
        "Mineralnye Vody, Mineralnye Vody Arpt [MRV], Russian Federation",
        "Minden, Douglas County Arpt [MEV], United States of America",
        "Minatitlan, Minatitlan Municipal Arpt [MTT], Mexico",
        "Milwaukee, General Mitchell Intl Arpt [MKE], United States of America",
        "Milton Keynes, Milton Keynes [KYN], United Kingdom",
        "Milton (FL), Whiting Field Nas [NSE], United States of America",
        "Millville, Millville Arpt [MIV], United States of America",
        "Millinocket, Millinocket Arpt [MLT], United States of America",
        "Milford Sound, Milford Sound Arpt [MFN], New Zealand",
        "Miles City, Miles City Municipal Arpt [MLS], United States of America",
        "Mildura, Mildura Arpt [MQL], Australia",
        "Mildenhall, NAF [GXH], United Kingdom",
        "Mildenhall, Mildenhall Arpt [MHZ], United Kingdom",
        "Milan, Segrate Arpt [SWK], Italy",
        "Milan, Malpensa Arpt [MXP], Italy",
        "Milan, Linate Arpt [LIN], Italy",
        "Mikkeli, Mikkeli Aprt [MIK], Finland",
        "Midway Island, Sand Island Field [MDY], Wake/Midway Islands",
        "Midland, Midland Intl Arpt [MAF], United States of America",
        "Middletown (OH), Hook Field [MWO], United States of America",
        "Middlemount, Middlemount Arpt [MMM], Australia",
        "Michigan City, Michigan City Arpt [MGC], United States of America",
        "Mian Yang, Mian Yang Arpt [MIG], China",
        "Miami, Tamiami Airport [TMB], United States of America",
        "Miami, Opa Locka Arpt [OPF], United States of America",
        "Miami, Miami Intl [MIA], United States of America",
        "Miami, MPB Seaplane Base [MPB], United States of America",
        "Mexico City, Juarez Intl [MEX], Mexico",
        "Mexicali, Rodolfg Sachez Taboada [MXL], Mexico",
        "Metz, Frescaty Airport [MZM], France",
        "Metz Nancy, Metz Nancy Lorraine [ETZ], France",
        "Mesquite, Mesquite Arpt [MFH], United States of America",
        "Mesa, Falcon Field [MSC], United States of America",
        "Merritt, Merritt Arpt [YMB], Canada",
        "Merimbula, Merimbula Arpt [MIM], Australia",
        "Meridian, Key Field [MEI], United States of America",
        "Merida, Merida Intl [MID], Mexico",
        "Merida, Alberto Carnevalli Arpt [MRD], Venezuela",
        "Merced, Merced Municipal Arpt [MCE], United States of America",
        "Menorca-Mahon, Aerop De Menorca [MAH], Spain",
        "Menominee, Menominee County [MNM], United States of America",
        "Mendoza, El Plumerillo Airport [MDZ], Argentina",
        "Mendi, Mendi Arpt [MDU], Papua New Guinea",
        "Memphis, Memphis Naval Air Station [NQA], United States of America",
        "Memphis, Memphis Intl [MEM], United States of America",
        "Memambetsu, Memanbetsu Arpt [MMB], Japan",
        "Melo, Melo Arpt [MLZ], Uruguay",
        "Melilla, Melilla Arpt [MLN], Spain",
        "Melbourne, Tullamarine Arpt [MEL], Australia",
        "Melbourne, Melbourne Regional [MLB], United States of America",
        "Melbourne, Melbourne Avalon Arpt [AVV], Australia",
        "Melbourne, Essendon Arpt [MEB], Australia",
        "Mekele, Mekele Airport [MQX], Ethiopia",
        "Meixian, Meixian Arpt [MXZ], China",
        "Mehamn, Mehamn Arpt [MEH], Norway",
        "Megeve, Megeve Arpt [MVV], France",
        "Meekathara, Meekatharra Arpt [MKR], Australia",
        "Medicine Hat, Medicine Hat Airport [YXH], Canada",
        "Medford, Medford Jackson Cty [MFR], United States of America",
        "Medellin, Jose Marie Cordova [MDE], Colombia",
        "Medellin, Enrique Olaya Herrara [EOH], Colombia",
        "Medan, Polonia Airport [MES], Indonesia",
        "Meadow Lake, Meadow Lake Arpt [YLJ], Canada",
        "Mcallen, Miller Intl [MFE], United States of America",
        "Mcalester, McAlester Municipal Arpt [MLC], United States of America",
        "McPherson, McPherson Arpt [MPR], United States of America",
        "McCook, McCook Municipal [MCK], United States of America",
        "McCall, Mccall Arpt [MYL], United States of America",
        "Mazatlan, Buelina Arpt [MZT], Mexico",
        "Mazar-I-Sharif, Mazar-I-Sharif [MZR], Afghanistan",
        "Mayaguez, Eugenio M De Hostos Arpt [MAZ], Puerto Rico",
        "Mauritius, Plaisance Arptt [MRU], Mauritius",
        "Maun, Maun Arpt [MUB], Botswana",
        "Maui-Kapalua, Kapalua Arpt [JHM], United States of America",
        "Maui-Kahului, Kahului Airport [OGG], United States of America",
        "Maui-Hana, Hana Municipal [HNM], United States of America",
        "Maturin, Quiriquire Arpt [MUN], Venezuela",
        "Mattoon, Coles County [MTO], United States of America",
        "Mattannur, Kannur International Airport [CNN], India",
        "Matsuyama, Matsuyama Airport [MYJ], Japan",
        "Matsumoto, Matsumoto Arpt [MMJ], Japan",
        "Matsu, Matsu Arpt [MFK], Taiwan",
        "Matamoros, Servando Canales Arpt [MAM], Mexico",
        "Masvingo, Ft Victoria Arpt [MVZ], Zimbabwe",
        "Masterton, Masterton Arpt [MRO], New Zealand",
        "Massena, Richards Field [MSS], United States of America",
        "Mason City, Mason City Municipal [MCW], United States of America",
        "Mashhad, Mashad Arpt [MHD], Iran",
        "Maseru, Moshoeshoe Intl Arpt [MSU], Lesotho",
        "Marysville, Yuba County Arpt [MYV], United States of America",
        "Maryborough, Maryborough Arpt [MBH], Australia",
        "Mary, Mary Arpt [MYP], Turkmenistan",
        "Marudi, Marudi Arpt [MUR], Malaysia",
        "Martinsville, La Chinita Arpt [MAR], United States of America",
        "Martinsburgh, Martinsburgh Regional Arpt [MRB], United States of America",
        "Marshfield, Marshfield Municipal Arpt [MFI], United States of America",
        "Marshalltown, Marshalltown Municipal [MIW], United States of America",
        "Marshall, Marshall Municipal [MML], United States of America",
        "Marshall, Harrison County Arpt [ASL], United States of America",
        "Marsh Harbour, Marsh Harbour Intl Arpt [MHH], Bahamas",
        "Marseille, Marseilles St Charles [XRF], France",
        "Marseille, Marseille Provence Arpt [MRS], France",
        "Marsa Alam, Marsa Alam Arpt [RMF], Egypt",
        "Marrakech, Menara Airport [RAK], Morocco",
        "Marquette, Sawyer Intl Airport [MQT], United States of America",
        "Maroua, Salam Arpt [MVR], Cameroon",
        "Maroochydore, Maroochydore Arpt [MCY], Australia",
        "Mariupol, Mariupol Arpt [MPW], Ukraine",
        "Marion, Williamson County [MWA], United States of America",
        "Maringa, Maringa Arpt [MGF], Brazil",
        "Marilia, Dr Gastao Vidigal [MII], Brazil",
        "Marigot St Martin, Marigot Seaplane Base [MSB], Guadeloupe",
        "Marietta, Dobbins Air Force Base [MGE], United States of America",
        "Mariehamn, Mariehamn Arpt [MHQ], Finland",
        "Maribor, Maribor Arpt [MBX], Slovenia",
        "Marianske Lazne, Marianske Lazne Arpt [MKA], Czech Republic",
        "Margate, Margate Arpt [MGH], South Africa",
        "Margaret River, Margaret River Station Arpt [MGV], Australia",
        "Margaret River, Margaret River Arpt [MQZ], Australia",
        "Mardin, Mardin Arpt [MQM], Turkey",
        "Marathon, Marathon Municipal Arpt [YSP], Canada",
        "Marathon, Marathon Arpt [MTH], United States of America",
        "Maratha S Vineyard, Dukes County [MVY], United States of America",
        "Maracay, Maracay Arpt [MYC], Venezuela",
        "Maraba, Maraba Arpt [MAB], Brazil",
        "Mara Lodges, Mara Lodges Arpt [MRE], Kenya",
        "Mar del Plata, Mar Del Plata Arpt [MDQ], Argentina",
        "Maputo, Maputo Intl [MPM], Mozambique",
        "Maota, Maota Arpt [MXS], Samoa",
        "Manzini, Matsapha Intl Arpt [MTS], Swaziland",
        "Manzini, King Mswati III International Airport [SHO], Swaziland",
        "Manzanillo, Sierra Maestra Arpt [MZO], Cuba",
        "Manzanillo, Manzanillo Arpt [ZLO], Mexico",
        "Manus Island, Momote Arpt [MAS], Papua New Guinea",
        "Mantane, Matane Airport [YME], Canada",
        "Manta, Manta Arpt [MEC], Ecuador",
        "Manston, Kent International [MSE], United Kingdom",
        "Mansfield (OH), Mansfield Municipal [MFD], United States of America",
        "Mannheim, Mannheim Neuostheim [MHG], Germany",
        "Mankato, Mankato Municipal Arpt [MKT], United States of America",
        "Manizales, Santaguida Arpt [MZL], Colombia",
        "Manitowoc, Manitowoc Municipal Arpt [MTW], United States of America",
        "Manitouwadge, Manitouwadge Municipal Arpt [YMG], Canada",
        "Manistique, Schoolcraft County Arpt [ISQ], United States of America",
        "Manistee, Manistee Arpt [MBL], United States of America",
        "Manila, Ninoy Aquino Intl [MNL], Philippines",
        "Maniitsoq, Maniitsoq Heleport [JSU], Greenland",
        "Manhattan, Manhattan Municipal Arpt [MHK], United States of America",
        "Mangalore, Bajpe Arpt [IXE], India",
        "Mandalay, Annisaton Arpt [MDL], Myanmar",
        "Manchester, Manchester New Hampshire [MHT], United States of America",
        "Manchester, Manchester Intl [MAN], United Kingdom",
        "Manchester , Manchester Bus Stn [ZMP], United Kingdom",
        "Manaus, Intl Arpt Eduardo Gomes [MAO], Brazil",
        "Manassas, Manassas Arpt [MNZ], United States of America",
        "Managua, Augusto C Sandino [MGA], Nicaragua",
        "Manado, Samratulang Arpt [MDC], Indonesia",
        "Mammoth Lakes, Mammoth Lakes Municipal [MMH], United States of America",
        "Malta, Luqa Airport [MLA], Malta",
        "Maloy, Harbour Arpt [QFQ], Norway",
        "Malmo, Sturup Arpt [MMX], Sweden",
        "Malmo, Malmo Metropolitan Area Arpt [MMA], Sweden",
        "Mallorca-Palma, Palma Mallorca Arpt [PMI], Spain",
        "Malindi, Malindi Arpt [MYD], Kenya",
        "Malelane, Municipal Arpt [LLE], South Africa",
        "Maldives, Male Intl Arpt [MLE], Maldives",
        "Malatya, Malatya Airport [MLX], Turkey",
        "Malang, Abdul Rachman Saleh Airport [MLG], Indonesia",
        "Malaga, Malaga Arpt [AGP], Spain",
        "Malacca, Batu Berendum Arpt [MKZ], Malaysia",
        "Malabo, Santa Isabel Arpt [SSG], Equatorial Guinea",
        "Mala Mala, Mala Mala Arpt [AAM], South Africa",
        "Makurdi, Makurdi Arpt [MDI], Nigeria",
        "Makung, Makung Arpt [MZG], Taiwan",
        "Makhachkala, Uytash Airport [MCX], Russian Federation",
        "Majuro, Amata Kabua Intl Arpt [MAJ], Marshall Islands",
        "Majunga, Amborovy Arpt [MJN], Madagascar",
        "Maitland, Maitland Airport [MTL], Australia",
        "Maio, Vila Do Maio Arpt [MMO], Cape Verde Islands",
        "Maiana, Maiana Airport [MNK], Kiribati",
        "Mahenye, Mahenye Airfield [MJW], Zimbabwe",
        "Magnolia, Magnolia Municipal [AGO], United States of America",
        "Magnitogorsk, Magnitogorsk Arpt [MQF], Russian Federation",
        "Magadan, Magadan Arpt [GDX], Russian Federation",
        "Mae Sot, Mae Sot Arpt [MAQ], Thailand",
        "Mae Hong Son, Mae Hong Son Arpt [HGN], Thailand",
        "Madurai, Madurai Airport [IXM], India",
        "Madrid, Barajas Arpt [MAD], Spain",
        "Madison, Dane County Regional [MSN], United States of America",
        "Madinah, Prince Mohammad Bin Abdulaziz Arpt [MED], Saudi Arabia",
        "Madera, Madera Municipal Arpt [MAE], United States of America",
        "Madeira-Funchal, Madeira Arpt [FNC], Portugal",
        "Madang, Madang Arpt [MAG], Papua New Guinea",
        "Macon, Lewis B Wilson [MCN], United States of America",
        "Mackay, Mackay Arpt [MKY], Australia",
        "Maceio, Palmeres Airport [MCZ], Brazil",
        "Macau, Macau Arpt [MFM], Macau",
        "Macapa, Macapa Intl Arpt [MCP], Brazil",
        "Macae, Macae Airport [MEA], Brazil",
        "Maastricht, Maastricht Aachen Arpt [MST], Netherlands",
        "Maan, Maan Arpt [MPQ], Jordan",
        "MEDAN, Kuala Namu International [KNO], Indonesia",
        "Lyon St Exupery Apt, Lyon Saint Exupery Arpt [LYS], France",
        "Lyon St Exupery Apt, Bron Arpt [LYN], France",
        "Lynchburg, Lynchburg Municipal [LYH], United States of America",
        "Lycksele, Hedlunda Arpt [LYC], Sweden",
        "Lvov, Snilow Arpt [LWO], Ukraine",
        "Luxor, Luxor Airport [LXR], Egypt",
        "Luxembourg, Luxembourg Arpt [LUX], Luxembourg",
        "Lutsk, Lutsk Arpt [UCK], Ukraine",
        "Lusaka, Lusaka Airport [LUN], Zambia",
        "Lumberton, Lumberton Arpt [LBT], United States of America",
        "Lulea, Kallax Airport [LLA], Sweden",
        "Lugansk, Lugansk Arpt [VSG], Ukraine",
        "Lugano, Agno Airport [LUG], Switzerland",
        "Lufkin, Angelina Cty Arpt [LFK], United States of America",
        "Ludhiana, Ludhiana Arpt [LUH], India",
        "Luderitz, Luderitz Arpt [LUD], Namibia",
        "Lucknow, Amausi Arpt [LKO], India",
        "Lucca, Lucca Arpt [LCV], Italy",
        "Lubumbashi, Luano [FBM], Congo, The Democratic Republic of",
        "Lublin, Lublin Airport [LUZ], Poland",
        "Lubeck, Blankensee Arpt [LBC], Germany",
        "Lubbock, Lubbock Preston Smith Intl Arpt [LBB], United States of America",
        "Luang Prabang, Louangphrabang Arpt [LPQ], Laos",
        "Luanda, Four De Fevereiro Arpt [LAD], Angola",
        "Lourdes, Tarbes Intl Arpt [LDE], France",
        "Louisville (KY), Louisville International Arpt [SDF], United States of America",
        "Louisville (KY), Bowman Field Arpt [LOU], United States of America",
        "Los Mochis, Federal Los Mochis Arpt [LMM], Mexico",
        "Los Banos, Los Banos Arpt [LSN], United States of America",
        "Los Angeles, Los Angeles Intl Arpt [LAX], United States of America",
        "Lorient, Lann Bihoue Arpt [LRT], France",
        "Loreto, Loreto Intl Arpt [LTO], Mexico",
        "Lord Howe Island, Lord Howe Island Arpt [LDH], Australia",
        "Lopez Island, Lopez Island Arpt [LPS], United States of America",
        "Longyearbyen, Svalbard Arpt [LYR], Norway",
        "Longreach, Longreach Arpt [LRE], Australia",
        "Long Island, Long Island Arpt [HAP], Australia",
        "Long Beach (CA), Long Beach Municipal [LGB], United States of America",
        "Long Akah, Long Akah Arpt [LKH], Malaysia",
        "Londrina, Londrina Arpt [LDB], Brazil",
        "Londonderry, Eglinton [LDY], United Kingdom",
        "London-Hendon, Nay Pyi Taw International Airport [NYT], United Kingdom",
        "London-Hendon, Hendon [HEN], United Kingdom",
        "London, St Pancras Intl Rail  [QQS], United Kingdom",
        "London, Southend Airport [SEN], United Kingdom",
        "London, QQU Railway Station [QQU], United Kingdom",
        "London, Luton Arpt [LTN], United Kingdom",
        "London, London Victoria Station [ZEP], United Kingdom",
        "London, London Stansted Arpt [STN], United Kingdom",
        "London, London Heathrow Arpt [LHR], United Kingdom",
        "London, London Gatwick Arpt [LGW], United Kingdom",
        "London, London City Arpt [LCY], United Kingdom",
        "London, Ebbsfleet Intl [XQE], United Kingdom",
        "London, Ashford Intl [QDH], United Kingdom",
        "London, All Airports [LON], United Kingdom",
        "Lome, Lome Airport [LFW], Togo",
        "Lombok-Mataram, Selaparang Airport [AMI], Indonesia",
        "Lombok-Mataram, Lombok International Airport [LOP], Indonesia",
        "Lokichoggio, Lokichoggio Arpt [LKG], Kenya",
        "Logrono, Agoncillo Arpt [RJL], Spain",
        "Logan, Logan Arpt [LGU], United States of America",
        "Lodz, Lodz Lublinek Arpt [LCJ], Poland",
        "Lockhart, Lockhart River Arpt [IRG], Australia",
        "Lochgilphead, Heliport [LPH], United Kingdom",
        "Loa Alamos, Los Alamos Municipal [LAM], United States of America",
        "Lloydminster, Lloydminster Arpt [YLL], Canada",
        "Ljubljana, Brnik Airport [LJU], Slovenia",
        "Lizard Island, Lizard Island Arpt [LZR], Australia",
        "Livingstone, Livingstone Arpt [LVI], Zambia",
        "Livingston (MT), Mission Field [LVM], United States of America",
        "Liverpool, Liverpool Arpt [LPL], United Kingdom",
        "Livermore, Bron Airport [LVK], United States of America",
        "Liuzhou, Liuzhou Airport [LZH], China",
        "Little Rock, Little Rock Regional [LIT], United States of America",
        "Little Cayman, Little Cayman Arpt [LYB], Cayman Islands",
        "Lismore, Lismore Airport [LSY], Australia",
        "Lisbon, Lisboa [LIS], Portugal",
        "Linz, Hoersching Arpt [LNZ], Austria",
        "Linkoping, Saab Airport [LPI], Sweden",
        "Ling Ling, Ling Ling Arpt [LLF], China",
        "Linden, Linden Municipal [LDJ], United States of America",
        "Lincoln (NE), Lincoln Municipal [LNK], United States of America",
        "Limon, Limon Arpt [LIO], Costa Rica",
        "Limoges, Bellegarde Arpt [LIG], France",
        "Limbang, Limbang Arpt [LMN], Malaysia",
        "Lima, Jorge Chavez International Arpt [LIM], Peru",
        "Lima, Allen County Arpt [AOH], United States of America",
        "Lilongwe, Lilongwe Intl Arpt [LLW], Malawi",
        "Lille, Lille Europe [XDB], France",
        "Lille, Lesquin Arpt [LIL], France",
        "Lilabari, Lilabari Arpt [IXI], India",
        "Lijiang City, Lijiang Arpt [LJG], China",
        "Lihue, Lihue Municipal [LIH], United States of America",
        "Lihir Island, Lihir Island Arpt [LNV], Papua New Guinea",
        "Lightning Ride, Lightning Ridge Arpt [LHG], Australia",
        "Lifou, Lifou Arpt [LIF], New Caledonia",
        "Liepaja, Liepaya Arpt [LPX], Latvia",
        "Liege, Bierset Airport [LGG], Belgium",
        "Lidkoping, Hovby Airport [LDK], Sweden",
        "Libreville, Libreville Arpt [LBV], Gabon",
        "Liberia, Liberia Arpt [LIR], Costa Rica",
        "Liberal, Liberal Municipal [LBL], United States of America",
        "Lianyungang, Lianyungang Arpt [LYG], China",
        "Lianping, Lianping Arpt [LIA], China",
        "Lhasa, Lhasa Arpt [LXA], China",
        "Lexington, Blue Grass Field [LEX], United States of America",
        "Lexington (ME), Jim Kelley Field [LXN], United States of America",
        "Lewistown, Lewistown Municipal [LWT], United States of America",
        "Lewiston, Lewiston Nez Pierce Arpt [LWS], United States of America",
        "Lewiston, Auburn Lewiston Arpt [LEW], United States of America",
        "Lewisburg, Greenbrier Valley Arpt [LWB], United States of America",
        "Leticia, General A V Cobo Arpt [LET], Colombia",
        "Lethbridge, Lethbridge Arpt [YQL], Canada",
        "Lesobeng, Lesobeng Arpt [LES], Lesotho",
        "Les Sables, Talmont Arpt [LSO], France",
        "Lerwick, Sumburgh Airport [LSI], United Kingdom",
        "Leros Island, Leros Arpt [LRS], Greece",
        "Leonora, Leonora Arpt [LNO], Australia",
        "Leon, Del Bajio Arpt [BJX], Mexico",
        "Leon Airport, Leon Arpt [LEN], Spain",
        "Lencois, Chapada Diamantina Arpt [LEC], Brazil",
        "Lemoore, Nas Reeves Field [NLC], United States of America",
        "Lemnos, Limnos Airport [LXS], Greece",
        "Lelystad, Lelystad Arpt [LEY], Netherlands",
        "Leknes, Leknes Arpt [LKN], Norway",
        "Leipzig, Leipzig Arpt [LEJ], Germany",
        "Leinster, Leinster Arpt [LER], Australia",
        "Leh, Leh Arpt [IXL], India",
        "Legaspi, Legaspi Arpt [LGP], Philippines",
        "Leeton, Leeton Arpt [QLE], Australia",
        "Leesburg, Leesburg Municipal Arpt [LEE], United States of America",
        "Leeds, Leeds Bradford Arpt [LBA], United Kingdom",
        "Lecce, Galatina Arpt [LCC], Italy",
        "Lebanon (NH), Lebanon Regional [LEB], United States of America",
        "Lebanon (NH), Claremont Municipal [CNH], United States of America",
        "Learmonth, Learmonth Arpt [LEA], Australia",
        "Le Touquet, Le Touquet Arpt [LTQ], France",
        "Le Puy en Velay, Loudes Airport [LPY], France",
        "Le Mans, Arnage Arpt [LME], France",
        "Le Havre, Octeville Arpt [LEH], France",
        "Lazaro Cardenas Michoacan, Lazaro Cardenas Arpt [LZC], Mexico",
        "Lawton, Lawton Municipal [LAW], United States of America",
        "Lawrence, Lawrence Municipal [LWC], United States of America",
        "Lawrence (MA), Lawrence Arpt [LWM], United States of America",
        "Lawas, Lawas Arpt [LWY], Malaysia",
        "Laverton, Laverton Airport [LVO], Australia",
        "Laurel, Hesler Noble Field [LUL], United States of America",
        "Laurel, Hattiesburg Laurel Regional Arpt [PIB], United States of America",
        "Launceston, Launceston Arpt [LST], Australia",
        "Latrobe, Westmorland County [LBE], United States of America",
        "Latakia, Hmelmin Airport [LTK], Syrian Arab Republic",
        "Latacunga, Cotapaxi Intl Arpt [LTX], Ecuador",
        "Las Vegas, Las Vegas, Harry Reid Intl Airport [LAS], United States of America",
        "Las Vegas, Las Vegas North Air Terminal [VGT], United States of America",
        "Las Vegas, Henderson Sky Harbor Arpt [HSH], United States of America",
        "Las Vegas (NM), Tucumcari Arpt [TCC], United States of America",
        "Las Vegas (NM), Las Vegas Arpt [LVS], United States of America",
        "Las Piedras, Josefa Camejo Arpt [LSP], Venezuela",
        "Las Cruces, Las Cruces Crawford Arpt [LRU], United States of America",
        "Larnaca, Larnaca Intl [LCA], Cyprus",
        "Larisa, Larisa Arpt [LRA], Greece",
        "Laredo, Laredo Intl [LRD], United States of America",
        "Laramie, Lar Shively Arpt [SAA], United States of America",
        "Laramie, General Brees Fld [LAR], United States of America",
        "Lappeeranta, Lappeenranta Arpt [LPP], Finland",
        "Laoag, Laoag International Airport [LAO], Philippines",
        "Lanzhou, Lanzhou Arpt [LHW], China",
        "Lansing, Lansing Arpt [LAN], United States of America",
        "Lanseria, Lanseria Arpt [HLA], South Africa",
        "Lannion, Servel Airport [LAI], France",
        "Langley, Langley Regional Arpt [YLY], Canada",
        "Langkawi, Langkawi Arpt [LGK], Malaysia",
        "Lands End, Lands End [LEQ], United Kingdom",
        "Lancaster, Quartz Hill Arpt [RZH], United States of America",
        "Lancaster (PA), Lancaster Arpt [LNS], United States of America",
        "Lanai-Lanai City, Lanai Airport [LNY], United States of America",
        "Lamu, Lamu Arpt [LAU], Kenya",
        "Lampedusa, Lampedusa Arpt [LMP], Italy",
        "Lampang, Lampang Arpt [LPT], Thailand",
        "Lamezia Terme, S Eufemia Arpt [SUF], Italy",
        "Lamar, Lamar Arpt [LAA], United States of America",
        "Lakselv, Banak Airport [LKL], Norway",
        "Lakeland, Lakeland Arpt [LAL], United States of America",
        "Lake Tahoe, Lake Tahoe Arpt [TVL], United States of America",
        "Lake Of The Ozarks, Lee C Fine Memorial [AIZ], United States of America",
        "Lake Jackson, Lake Jackson Arpt [LJN], United States of America",
        "Lake Havasu Cty, Lake Havasu City Municipal Airport [HII], United States of America",
        "Lake Charles, Lake Charles Municipal [LCH], United States of America",
        "Lajes, Lages Arpt [LAJ], Brazil",
        "Lahr, Lahr Arpt [LHA], Germany",
        "Lahore, Lahore Arpt [LHE], Pakistan",
        "Lahad Datu, Lahad Datu Arpt [LDU], Malaysia",
        "Lagos, Murtala Muhammed Arpt [LOS], Nigeria",
        "Lago Agrio, Lago Agrio [LGQ], Ecuador",
        "Lafayette, Lafayette Municipal [LFT], United States of America",
        "Lafayette (IN), Lafayette Arpt [LAF], United States of America",
        "Laeso Island, Laeso Airport [BYR], Denmark",
        "Lae Pg, Nadzab Arpt [LAE], Papua New Guinea",
        "Ladysmith, Ladysmith Arpt [LAY], South Africa",
        "Laconia, Laconia Municipal Arpt [LCI], United States of America",
        "Labuan, Labuan Arpt [LBU], Malaysia",
        "Labuan Bajo, Komodo Airpor [LBJ], Indonesia",
        "Labasa, Labasa Arpt [LBS], Fiji",
        "La Verne, Brackett Field [POC], United States of America",
        "La Tabatiere, La Tabatiere Arpt [ZLT], Canada",
        "La Serena, La Florida [LSC], Chile",
        "La Romana, La Romana Arpt [LRM], Dominican Republic",
        "La Rochelle, Laleu Airport [LRH], France",
        "La Roche, Les Ajoncs Arpt [EDM], France",
        "La Rioja, La Rioja Arpt [IRJ], Argentina",
        "La Porte (IN), La Porte Arpt [LPO], United States of America",
        "La Plata, La Plata Arpt [LPG], Argentina",
        "La Paz, El Alto Arpt [LPB], Bolivia",
        "La Paz, Aeropuerto Gen Marquez De Leon [LAP], Mexico",
        "La Grange, Calloway Arpt [LGC], United States of America",
        "La Grande, La Grande Municipal Arpt [YGL], Canada",
        "La Desirade, La Desirade Arpt [DSD], Guadeloupe",
        "La Crosse, La Crosse Municipal [LSE], United States of America",
        "La Coruna, La Coruna Arpt [LCG], Spain",
        "La Ceiba, Goloson Intl Arpt [LCE], Honduras",
        "La Baule, Montoir Arpt [LBY], France",
        "LOJA, Catamayo [LOH], Ecuador",
        "Kzyl Orda, Kzyl Orda Arpt [KZO], Kazakhstan",
        "Kyoto, Kyoto Arpt [UKY], Japan",
        "Kwajalein, Kwajalein Arpt [KWA], Marshall Islands",
        "Kuwait, Kuwait Intl [KWI], Kuwait",
        "Kuusamo, Kuusamo [KAO], Finland",
        "Kuujjuaq, Kuujjuaq Arpt [YVP], Canada",
        "Kushiro, Kushiro Arpt [KUH], Japan",
        "Kushinagar, Kushinagar International Airport [KBK], India",
        "Kursk, Kursk Arpt [URS], Russian Federation",
        "Kuressaare, Kuressaare Arpt [URE], Estonia",
        "Kuopio, Kuopio Arpt [KUO], Finland",
        "Kununurra, Kununurra Airport [KNX], Australia",
        "Kunsan, Kunsan Arpt [KUV], Korea, Republic of",
        "Kunming Airport, Kunming Airport [KMG], China",
        "Kume Jima, Kumejima Arpt [UEO], Japan",
        "Kumamoto, Kumamoto Airport [KMJ], Japan",
        "Kulusuk, Kulusuk Arpt [KUS], Greenland",
        "Kulu, Bhuntar Arpt [KUU], India",
        "Kuching, Kuching Airport [KCH], Malaysia",
        "Kuantan, Kuantan Arpt [KUA], Malaysia",
        "Kuala Terengganu, Sultan Mahmood Arpt [TGG], Malaysia",
        "Kuala Lumpur, Sultan Abdul Aziz Shah Arpt [SZB], Malaysia",
        "Kuala Lumpur, Kuala Lumpur International Arpt [KUL], Malaysia",
        "Kuala Belait, Kuala Belait Arpt [KUB], Brunei",
        "Kristiansund, Kvernberget Arpt [KSU], Norway",
        "Kristianstad, Kristianstad Arpt [KID], Sweden",
        "Kristiansand, Kjevik Airport [KRS], Norway",
        "Krasnoyarsk, Krasnojarsk Arpt [KJA], Russian Federation",
        "Krasnodar, Krasnodar Arpt [KRR], Russian Federation",
        "Kramfors, Kramfors Arpt [KRF], Sweden",
        "Krakow, John Paul 11 Balice Intl [KRK], Poland",
        "Krabi, Krabi Arpt [KBV], Thailand",
        "Kozhikode (Calicut), Kozhikode Arpt [CCJ], India",
        "Koutaba, Koutaba Arpt [KOB], Cameroon",
        "Koumac, Koumac Arpt [KOC], New Caledonia",
        "Kotzebue, Ralph Wien Memorial [OTZ], United States of America",
        "Kota Kinabalu, Kota Kinabalu Arpt [BKI], Malaysia",
        "Kota Bahru, Pengkalan Chepa [KBR], Malaysia",
        "Kostroma, Kostroma Arpt [KMW], Russian Federation",
        "Kostanay, Kostanay Arpt [KSN], Kazakhstan",
        "Kosice, Barca Arpt [KSC], Slovakia",
        "Kos Town, Kos Arpt [KGS], Greece",
        "Kortrijk, Kortrijk Arpt [KJK], Belgium",
        "Koror Island, Airai Airport [ROR], Palau",
        "Kopasker, Kopasker Arpt [OPA], Iceland",
        "Konya, Konya Arpt [KYA], Turkey",
        "Komsomolsk Na Amure, Komsomolsk Na Amure Arpt [KXK], Russian Federation",
        "Komatsu, Komatsu Airport [KMQ], Japan",
        "Kolkata , Netaji Subhas Chandra Bose Intl [CCU], India",
        "Kokoro, Kokoro Arpt [KOR], Papua New Guinea",
        "Kokomo, Kokomo Municipal [OKK], United States of America",
        "Kokkola Karleby, Kokkola Arpt [KOK], Finland",
        "Koh Samui, Koh Samui Arpt [USM], Thailand",
        "Kodiak, Kodiak Municipal [KDK], United States of America",
        "Kodiak, Kodiak Arpt [ADQ], United States of America",
        "Kochi, Kochi Intl Arpt [COK], India",
        "Kochi, Kochi Airport [KCZ], Japan",
        "Kobe, Kobe Airport [UKB], Japan",
        "Knoxville, McGhee Tyson Arpt [TYS], United States of America",
        "Knock, Knock International [NOC], Ireland",
        "Klawock, Klawock Arpt [KLW], United States of America",
        "Klamath Falls, Kingsley Field [LMT], United States of America",
        "Klaipeda, Klaipeda Arpt [KLJ], Lithuania",
        "Klagenfurt, Klagenfurt Arpt [KLU], Austria",
        "Kittila, Kittila Arpt [KTT], Finland",
        "Kithira, Kithira Arpt [KIT], Greece",
        "Kitchener, Kitchener Waterloo Regional [YKF], Canada",
        "Kitakyushu, Kokura Arpt [KKJ], Japan",
        "Kisumu, Kisumu Arpt [KIS], Kenya",
        "Kissimmee, Kissimmee Municipal Arpt [ISM], United States of America",
        "Kiryat Shmona, Kiryat Shmona Arpt [KSW], Israel",
        "Kiruna, Kiruna Airport [KRN], Sweden",
        "Kirkwall, Kirkwall Airport [KOI], United Kingdom",
        "Kirksville, Kirksville Municipal [IRK], United States of America",
        "Kirkland, Kirkland Lake Municipal Arpt [YKX], Canada",
        "Kirkenes, Hoeybuktmoen Arpt [KKN], Norway",
        "Kinston, East Reg Jetport Stallings [ISO], United States of America",
        "Kinshasa, Kinshasa Arpt [FIH], Congo, The Democratic Republic of",
        "Kinmen, Shang Yi Arpt [KNH], Taiwan",
        "Kingsville, Naval Air Station Arpt [NQI], United States of America",
        "Kingston, Tinson Arpt [KTP], Jamaica",
        "Kingston, Norman Rodgers Arpt [YGK], Canada",
        "Kingston, Norman Manly Arpt [KIN], Jamaica",
        "Kings Canyon, Kings Canyon Arpt [KBJ], Australia",
        "Kingman, Mohave County [IGM], United States of America",
        "King Salmon, King Salmon Arpt [AKN], United States of America",
        "King Of Prussia, King Of Prussia Arpt [KPD], United States of America",
        "King Khalid Military City, King Khalid Military Airport [KMC], Saudi Arabia",
        "King Island, King Island Arpt [KNS], Australia",
        "King City, Mesa Del Rey Arpt [KIC], United States of America",
        "Kimberley, Kimberley Arpt [KIM], South Africa",
        "Killeen, Killeen Municipal [ILE], United States of America",
        "Killeen, Gray AAF [GRK], United States of America",
        "Killarney, Kerry County Arpt [KIR], Ireland",
        "Kilimanjaro, Kilimanjaro Arpt [JRO], Tanzania",
        "Kigali, Kayibanda Arpt [KGL], Rwanda",
        "Kiev, Zhulhany Arpt [IEV], Ukraine",
        "Kiev, Borispol Arpt [KBP], Ukraine",
        "Kiel, Holtenau Arpt [KEL], Germany",
        "Khorramabad, Khorramabad Arpt [KHD], Iran",
        "Khon Kaen, Khon Kaen Arpt [KKC], Thailand",
        "Khartoum, Civil Arpt [KRT], Sudan",
        "Kharkiv (Kharkov), Kharkov Arpt [HRK], Ukraine",
        "Kharga, Kharga Arpt [UVL], Egypt",
        "Khanty Mansiysk, Khanty Mansiysk Arpt [HMA], Russian Federation",
        "Khamis Mushayat, Abha Airport [AHB], Saudi Arabia",
        "Khamis Mushait, Khamis Mushait Arpt [KMX], Saudi Arabia",
        "Khajuraho, Khajuraho Arpt [HJR], India",
        "Khabarovsk, Novyy Arpt [KHV], Russian Federation",
        "Key West, Key West Intl [EYW], United States of America",
        "Key Largo, Port Largo Arpt [KYL], United States of America",
        "Ketchikan, Ketchikan Intl Arpt [KTN], United States of America",
        "Kerteh, Kertech Arpt [KTE], Malaysia",
        "Kermanshah, Kermanshah Arpt [KSH], Iran",
        "Kerman, Kerman Arpt [KER], Iran",
        "Kerikeri, Kerikeri Arpt [KKE], New Zealand",
        "Keokuk, Keokuk Arpt [EOK], United States of America",
        "Kentland, Kentland Arpt [KKT], United States of America",
        "Kenosha, Kenosha Municipal Arpt [ENW], United States of America",
        "Kenora, Kenora Airport [YQK], Canada",
        "Kennett, Kennett Municipal Arpt [KNT], United States of America",
        "Kenai, Kenai Municipal Arpt [ENA], United States of America",
        "Kempsey, Kempsey Arpt [KPS], Australia",
        "Kemi, Kemi Tornio Arpt [KEM], Finland",
        "Kemerovo, Kemerovo Arpt [KEJ], Russian Federation",
        "Kelowna, Ellison Field [YLW], Canada",
        "Kegaska, Kegaska Arpt [ZKG], Canada",
        "Kefalonia-Argostoli, Argostoli Arpt [EFL], Greece",
        "Keene, Dilliant Hopkins Arpt [EEN], United States of America",
        "Kearney, Kearney Municipal Arrpt [EAR], United States of America",
        "Kazan, Kazan Arpt [KZN], Russian Federation",
        "Kayseri, Kayseri Arpt [ASR], Turkey",
        "Kavieng, Kavieng Arpt [KVG], Papua New Guinea",
        "Kavala, Megas Alexandros [KVA], Greece",
        "Kaunas, Kaunas Arpt [KUN], Lithuania",
        "Kauai-Princeville, Princeville Arpt [HPV], United States of America",
        "Katowice, Pyrzowice [KTW], Poland",
        "Kathmandu, Tribuvan Arpt [KTM], Nepal",
        "Katherine, Tindal Airport [KTR], Australia",
        "Kastoria, Aristoteles Arpt [KSO], Greece",
        "Kastelorizo, Kastelorizo Arpt [KZS], Greece",
        "Kassel, Kassel Calden Arpt [KSF], Germany",
        "Kasane, Kasane Arpt [BBK], Botswana",
        "Karup, Karup Arpt [KRP], Denmark",
        "Karumba, Karumba Arpt [KRB], Australia",
        "Kars, Kars Arpt [KSY], Turkey",
        "Karratha, Karratha Airport [KTA], Australia",
        "Karpathos, Karpathos Arpt [AOK], Greece",
        "Karlstad, Karlstad Arpt [KSD], Sweden",
        "Karlsruhe Baden Baden, Soellingen Arpt [FKB], Germany",
        "Karlovy Vary, Karlovy Vary Arpt [KLV], Czech Republic",
        "Kariba Dam, Kariba Arpt [KAB], Zimbabwe",
        "Karaganda, Karaganda Arpt [KGF], Kazakhstan",
        "Karachi, Quaid E Azam International [KHI], Pakistan",
        "Kapuskasing, Japuskasing Municipal Arpt [YYU], Canada",
        "Kaohsiung, Kaohsiung Intl [KHH], Taiwan",
        "Kansas City, Johnson Executive Arpt [OJC], United States of America",
        "Kansas City, Fairfax Municipal Arpt [KCK], United States of America",
        "Kansas City (MO), Kansas City Municipal Arpt [MKC], United States of America",
        "Kansas City (MO), Kansas City Intl [MCI], United States of America",
        "Kanpur, Kanpur Arpt [KNU], India",
        "Kano, Aminu Kano Intl Arpt [KAN], Nigeria",
        "Kankakee, Greater Kankakee Arpt [IKK], United States of America",
        "Kangirsuk, Kangirsuk Arpt [YKG], Canada",
        "Kangiqsujuaq, Kangiqsujuaq Arpt [YWB], Canada",
        "Kangerlussuaq, Sondre Stromfjord Arpt [SFJ], Greenland",
        "Kangaroo Kingscote, Kingscote Arpt [KGC], Australia",
        "Kandla, Kandla Airport [IXY], India",
        "Kamuela, Waimea Arpt [MUE], United States of America",
        "Kamloops, Davie Fulton Arpt [YKA], Canada",
        "Kalskag, Kalskag Municipal Arpt [KLG], United States of America",
        "Kalmar, Kalmar Arpt [KLR], Sweden",
        "Kalispell, Glacier Park Intl [FCA], United States of America",
        "Kaliningrad, Kaliningrad Arpt [KGD], Russian Federation",
        "Kalibo, Kalibo Arpt [KLO], Philippines",
        "Kalgoorlie, Kalgoorlie Arpt [KGI], Australia",
        "Kalaupapa, Kalaupapa Municipal [LUP], United States of America",
        "Kalamazoo, Kalamazoo Cty Arpt [AZO], United States of America",
        "Kalamata, Kalamata Airport [KLX], Greece",
        "Kake, Kake Arpt [KAE], United States of America",
        "Kajaani, Kajaani Airport [KAJ], Finland",
        "Kaitaia, Kaitaia Arpt [KAT], New Zealand",
        "Kaikoura, Kaikoura Arpt [KBZ], New Zealand",
        "Kagoshima, Kagoshima Arpt [KOJ], Japan",
        "Kabul, Khwaja Rawash Arpt [KBL], Afghanistan",
        "Jyvaskyla, Jyvaskyla Arpt [JYV], Finland",
        "Jurado, Jurado Arpt [JUO], Colombia",
        "Juneau, Juneau Arpt [JNU], United States of America",
        "Juliaca, Juliaca Arpt [JUL], Peru",
        "Julia Creek, Julia Creek Arpt [JCK], Australia",
        "Jujuy, El Cadillal Airport [JUJ], Argentina",
        "Juiz De Fora, Francisco De Assis Arpt [JDF], Brazil",
        "Juist, Juist Arpt [JUI], Germany",
        "Juazeiro Do Norte, Regional Do Cariri Arpt [JDO], Brazil",
        "Jorhat, Rowriah Airport [JRH], India",
        "Joplin, Joplin Municipal Arpt [JLN], United States of America",
        "Jonkoping, Axamo Airport [JKG], Sweden",
        "Jonesboro, Jonesboro Municipal [JBR], United States of America",
        "Joinville, Cubatao Arpt [JOI], Brazil",
        "Johor Bahru, Sultan Ismail Intl Arpt [JHB], Malaysia",
        "Johnstown, Johnstown Cambria Arpt [JST], United States of America",
        "Johannesburg, Randgermiston Arpt [QRA], South Africa",
        "Johannesburg, Johannesburg International [JNB], South Africa",
        "Johannesburg, Grand Central Arpt [GCJ], South Africa",
        "Joensuu, Joensuu Arpt [JOE], Finland",
        "Jodhpur, Jodhpur Arpt [JDH], India",
        "Joao Pessoa, Castro Pinto Arpt [JPA], Brazil",
        "Joacaba, Joacaba Arpt [JCB], Brazil",
        "Jiujiang, Jiujiang Airport [JIU], China",
        "Jinzhou, Jinzhou Arpt [JNZ], China",
        "Jinjiang, Jinjiang Arpt [JJN], China",
        "Jinghong, Jinghong Arpt [JHG], China",
        "Jingdezhen, Jingdezhen Arpt [JDZ], China",
        "Jinan, Jinan Arpt [TNA], China",
        "Jimma, Jimma Arpt [JIM], Ethiopia",
        "Jilin, Jilin Arpt [JIL], China",
        "Jijiga, Jijiga [JIJ], Ethiopia",
        "Jiayuguan, Jiayuguan Arpt [JGN], China",
        "Ji Parana, Ji Parana Arpt [JPR], Brazil",
        "Jharsuguda, Jharsuguda Veer Surendra Sai [JRG], India",
        "Jerusalem, Atarot Airport [JRS], Israel",
        "Jersey, Jersey Airport [JER], United Kingdom",
        "Jerez De La Frontera, La Parra Arpt [XRY], Spain",
        "Jeju (Cheju), Jeju Intl Arpt [CJU], Korea, Republic of",
        "Jefferson City, Jefferson City Memorial [JEF], United States of America",
        "Jeddah, Jeddah Intl [JED], Saudi Arabia",
        "Jasper, Jasper Airport [YJA], Canada",
        "Janesville, Rock County Arpt [JVL], United States of America",
        "Jan, Jackson Evers Intl Arpt [JAN], United States of America",
        "Jan, Hawkins Field [HKS], United States of America",
        "Jamnagar, Govardhanpur Arpt [JGA], India",
        "Jammu, Satwari Arpt [IXJ], India",
        "Jamestown, Jamestown Arpt [JMS], United States of America",
        "Jamestown, Chautauqua Cty Arpt [JHW], United States of America",
        "Jambi, Sultan Taha Syarifudin Arpt [DJB], Indonesia",
        "Jalapa, Jalapa Arpt [JAL], Mexico",
        "Jalandhar, Jaladhar Airport [QJU], India",
        "Jakarta, Soekarno–Hatta Arpt [JKT], Indonesia",
        "Jakarta, Soekarno Hatta Intl [CGK], Indonesia",
        "Jakarta, Halim Perdana Kusuma Arpt [HLP], Indonesia",
        "Jaisalmer, Jaisalmer Airport [JSA], India",
        "Jaipur, Sanganeer Arpt [JAI], India",
        "Jacksonville, Jacksonville Municipal Arpt [IJX], United States of America",
        "Jacksonville, Cherokee County Arpt [JKV], United States of America",
        "Jacksonville, Albert J Ellis [OAJ], United States of America",
        "Jacksonville (FL), Jacksonville Nas [NIP], United States of America",
        "Jacksonville (FL), Jacksonville Intl Arpt [JAX], United States of America",
        "Jacksonville (FL), Craig Municipal Arpt [CRG], United States of America",
        "Jacksonville (FL), Cecil Field Nas [NZC], United States of America",
        "Jackson, McKellar Fld [MKL], United States of America",
        "Jackson, Jackson Reynolds Municipal [JXN], United States of America",
        "Jackson (WY), Jackson Hole Arpt [JAC], United States of America",
        "Jabiru, Jabiru Arpt [JAB], Australia",
        "Jabalpur, Jabalpur Airport [JLR], India",
        "JUBA, Juba Airport [JUB], Sudan",
        "JEKI, Jeki Airport [JEK], Zambia",
        "Izumo, Izumo Arpt [IZO], Japan",
        "Izmir, Izmir Cigli Military [IGL], Turkey",
        "Izmir, Adnan Menderes Airport [ADB], Turkey",
        "Izhevsk, Izhevsk Arpt [IJK], Russian Federation",
        "Ixtepec, Ixtepec Arpt [IZT], Mexico",
        "Iwami, Iwami Arpt [IWJ], Japan",
        "Ivujivik, Ivujivik Arpt [YIK], Canada",
        "Ivanovo, Ivanovo Arpt [IWA], Russian Federation",
        "Ivano Frankovsk, Ivano Frankovsk Arpt [IFO], Ukraine",
        "Ivalo, Ivalo Arpt [IVL], Finland",
        "Ithaca (NY), Tomkins County [ITH], United States of America",
        "Itaperruna, Itaperuna Arpt [ITP], Brazil",
        "Istanbul, Sabiha Gokcen Arpt [SAW], Turkey",
        "Istanbul, Istanbul Ataturk Arpt [ISL], Turkey",
        "Istanbul, Istanbul Arpt [IST], Turkey",
        "Islip, Long Island Macarthur Arpt [ISP], United States of America",
        "Isles Of Scilly, Tresco [TSO], United Kingdom",
        "Isle of Skye, Broadford [SKL], United Kingdom",
        "Isle of Scilly, St Marys [ISC], United Kingdom",
        "Isle of Man, Isle of man [IOM], United Kingdom",
        "Isle Of Colonsay, Isle Of Colonsay [CSA], United Kingdom",
        "Isle Of Coll, Isle Of Coll [COL], United Kingdom",
        "Islay, Islay Airport [ILY], United Kingdom",
        "Islamabad, Islamabad Intl [ISB], Pakistan",
        "Isla Mujeres, Isla Mujeres Arpt [ISJ], Mexico",
        "Isla Grande, SIG Isla Grande Arpt [SIG], United States of America",
        "Ishigaki, Ishigaki Airport [ISG], Japan",
        "Isfahan, Isfahan Arpt [IFN], Iran",
        "Ischia, Ischia Arpt [ISH], Italy",
        "Isafjordur, Isafjordur Arpt [IFJ], Iceland",
        "Ironwood, Ironwood Arpt [IWD], United States of America",
        "Iron Mountain, Ford Arpt [IMT], United States of America",
        "Irkutsk, Irkutsk Airport [IKT], Russian Federation",
        "Iquitos, C F Secada Arpt [IQT], Peru",
        "Iquique, Cavancha Chucumata Arpt [IQQ], Chile",
        "Iqaluit, Iqaluit Arpt [YFB], Canada",
        "Ipswich, Ipswich [IPW], United Kingdom",
        "Ipoh, Ipoh Arpt [IPH], Malaysia",
        "Ipiales, San Luis Arpt [IPI], Colombia",
        "Ipatinga, Usiminas Arpt [IPN], Brazil",
        "Ioannina, Ioannina Arpt [IOA], Greece",
        "Inyokern, Kern Cty Airport [IYK], United States of America",
        "Inverness, ScotRail [ZIV], United Kingdom",
        "Inverness, Inverness Airport [INV], United Kingdom",
        "Inverell, Inverell Arpt [IVR], Australia",
        "Invercargill, Invercargill Arpt [IVC], New Zealand",
        "Inuvik, Inuvik Mike Zubko Arpt [YEV], Canada",
        "Inukjuak, Inukjuak Arpt [YPH], Canada",
        "International Falls, Intl Falls Arpt [INL], United States of America",
        "Innsbruck, Kranebitten Airport [INN], Austria",
        "Inhambane, Inhambane Airport [INH], Mozambique",
        "Indore, Indore Arpt [IDR], India",
        "Indianapolis, Indianapolis Intl Arpt [IND], United States of America",
        "Indiana, Indiana County Arpt [IDI], United States of America",
        "Imphal, Tulihal Arpt [IMF], India",
        "Imperatriz, Imperatriz Arpt [IMP], Brazil",
        "Ilulissat, Ilulissat Arpt [JAV], Greenland",
        "Iloilo, Mandurriao Arpt [ILO], Philippines",
        "Ilheus, Eduardo Gomes Airport [IOS], Brazil",
        "Iles De Madeleine, House Harbour Arpt [YGR], Canada",
        "Ile des Pins, Ile Des Pins Arpt [ILP], New Caledonia",
        "Ikaria Island, Ikaria Arpt [JIK], Greece",
        "Iguazu Falls (BR), Cataratas Arpt [IGU], Brazil",
        "Iguazu Falls (AR), Iguazu Intl [IGR], Argentina",
        "Iguatu, Iguatu Arpt [QIG], Brazil",
        "Idaho Falls, Fanning Field [IDA], United States of America",
        "Ibiza - Ibiza Town, Ibiza Airport [IBZ], Spain",
        "Iasi, Iasi Arpt [IAS], Romania",
        "Hyderabad, Rajiv Gandhi Intl Arpt Shamshabad [HYD], India",
        "Hyannis, Barnstable Cty Arpt [HYA], United States of America",
        "Hwange, Hwange Arpt [HWN], Zimbabwe",
        "Hutchinson (KS), Hutchinson Municipal [HUT], United States of America",
        "Huslia, Huslia Arpt [HSL], United States of America",
        "Husavik, Husavik Arpt [HZK], Iceland",
        "Huron, Huron Municipal [HON], United States of America",
        "Hurghada, Hurghada Airport [HRG], Egypt",
        "Huntsville, Huntsville Intl Arpt [HSV], United States of America",
        "Huntsville (TX), Huntsville Arpt [HTV], United States of America",
        "Huntington, Tri State Milton Arpt [HTS], United States of America",
        "Huntington, Portsmith Regional [PMH], United States of America",
        "Huntingburg, Huntingburg Municipal [HNB], United States of America",
        "Humberside, Humberside Arpt [HUY], United Kingdom",
        "Humacao, Humacao Arpt [HUC], United States of America",
        "Hultsfred, Hultsfred Arpt [HLF], Sweden",
        "Huizhou, Huizhou Arpt [HUZ], China",
        "Hughenden, Hughenden Arpt [HGD], Australia",
        "Hudiksvall, Hudiksvall Arpt [HUV], Sweden",
        "Hubli, Hubli Airport [HBX], India",
        "Huatulco, Bahia De Huatulco Arpt [HUX], Mexico",
        "Huanuco, Huanuco Arpt [HUU], Peru",
        "Huangyan, Huangyan Arpt [HYN], China",
        "Huanghua, Changsha Huanghua Arpt [HHA], China",
        "Hualien, Hualien Arpt [HUN], Taiwan",
        "Huahine Island, Huahine Arpt [HUH], French Polynesia",
        "Hua Hin, Hua Hin Arpt [HHQ], Thailand",
        "Hu Pg, Phu Bai Arpt [HUI], Vietnam",
        "Hsinchun, Hsinchun Arpt [HSZ], Taiwan",
        "Houston, West Houston [IWS], United States of America",
        "Houston, Houston Hobby Arpt [HOU], United States of America",
        "Houston, George Bush Intercontinental [IAH], United States of America",
        "Houston, Ellington Field [EFD], United States of America",
        "Houston, David Wayne Hooks Arpt [DWH], United States of America",
        "Houma, Terrebonne Arpt [HUM], United States of America",
        "Hot Springs, Memorial Field [HOT], United States of America",
        "Hot Springs, Ingalls Field [HSP], United States of America",
        "Hoskins, Hoskins Arpt [HKN], Papua New Guinea",
        "Horn Island, Horn Island Arpt [HID], Australia",
        "Horizontina, Horizontina Arpt [HRZ], Brazil",
        "Hopkinsville, Hopkinsville Christian Country Arpt [HOP], United States of America",
        "Hope, Hope Arpt [YHE], Canada",
        "Hoonah, Hoonah Municipal Arpt [HNH], United States of America",
        "Hoolehua, Molokai Arpt [MKK], United States of America",
        "Honolulu, Honolulu Intl [HNL], United States of America",
        "Honolulu, Hickam Air Force Base [HIK], United States of America",
        "Honningsvag, Valan Arpt [HVG], Norway",
        "Honiara, Henderson Intl Arpt [HIR], Solomon Islands",
        "Hong Kong, Hong Kong Intl [HKG], China",
        "Homer, Homer Municipal Arpt [HOM], United States of America",
        "Holyhead, Holyhead [HLY], United Kingdom",
        "Holland, Park Township [HLM], United States of America",
        "Holguin, Frank Pias Arpt [HOG], Cuba",
        "Hokitika, Hokitika Airport [HKK], New Zealand",
        "Hohhot, Hohhot Arpt [HET], China",
        "Hofn, Hornafjordur Arpt [HFN], Iceland",
        "Hof, Hof Pirk Arpt [HOQ], Germany",
        "Hoedspruit, Hoedspruit Arpt [HDS], South Africa",
        "Hobbs, Lea County Arpt [HOB], United States of America",
        "Hobart, Hobart International Arpt [HBA], Australia",
        "Ho Chi Minh City, Tan Son Nhut Arpt [SGN], Vietnam",
        "Hluhluwe, Hluhluwe Arpt [HLW], South Africa",
        "Hiroshima, Hiroshima Airport [HIJ], Japan",
        "Hilton Head Island, Hilton Head Municipal [HHH], United States of America",
        "Hilsboro, Portland Hillsboro Arpt [HIO], United States of America",
        "High Wycombe, High Wycombe [HYC], United Kingdom",
        "High Level, Footner Lake Arpt [YOJ], Canada",
        "Hickory, Hickory Municipal [HKY], United States of America",
        "Hibbing, Hibbing Chisolm Arpt [HIB], United States of America",
        "Hervey Bay, Hervey Bay [HVB], Australia",
        "Hermosillo, Gen Ignacio Pesqueira Garcia Arpt [HMO], Mexico",
        "Heringsdorf, Heringsdorf Arpt [HDF], Germany",
        "Hengyang, Hengyang Arpt [HNY], China",
        "Hengchun, Hengchun Arpt [HCN], Taiwan",
        "Hemestead, Homestead Municipal [HST], United States of America",
        "Helsinki, Helsinki Arpt [HEL], Finland",
        "Helgoland, Helgoland Arpt [HGL], Germany",
        "Helena, Helena Municipal [HLN], United States of America",
        "Heideleberg, Heidelberg Arpt [HDB], Germany",
        "Heide Buesum, Heide Arpt [HEI], Germany",
        "Heho, Heho Arpt [HEH], Myanmar",
        "Hefei, Hefei Arpt [HFE], China",
        "Hearst, Hearst Municipal Arpt [YHF], Canada",
        "Healy Lake, Healy Lake Arpt [HKB], United States of America",
        "Hayward, Hayward Municipal [HYR], United States of America",
        "Hayward, Hayward Air Terminal [HWD], United States of America",
        "Hays, Hays Municipal [HYS], United States of America",
        "Hayman Island, Hayman Island Arpt [HIS], Australia",
        "Hayden, Hayden Arpt [HDN], United States of America",
        "Hawthorne, Hawthorne Arpt [HHR], United States of America",
        "Hawaii-Kona, Keahole Arpt [KOA], United States of America",
        "Hawaii-Hilo, Hilo Hawaii Intl [ITO], United States of America",
        "Havre, City County [HVR], United States of America",
        "Havre St Pierre, Havre St Pierre Municipal Arpt [YGV], Canada",
        "Haverfordwest, Haverfordwest [HAW], United Kingdom",
        "Havana, Jose Marti Intl Arpt [HAV], Cuba",
        "Haugesund, Karmoy Arpt [HAU], Norway",
        "Hattiesburg, Bobby L Chain Municipal [HBG], United States of America",
        "Hatfield, Hatfield [HTF], United Kingdom",
        "Hatay, Hatay Havalimani [HTY], Turkey",
        "Hat Yai, Hat Yai Arpt [HDY], Thailand",
        "Hastings, Hastings Municipal [HSI], United States of America",
        "Hassi Messaoud, Oued Irara Arpt [HME], Algeria",
        "Hartford, Brainard Arpt [HFD], United States of America",
        "Hartford, Bradley Intl Arpt [BDL], United States of America",
        "Harstad  Narvik, Evenes Arpt [EVE], Norway",
        "Harrogate, Linton-On-Ouse [HRT], United Kingdom",
        "Harrison, Boone County Arpt [HRO], United States of America",
        "Harrismith, Harrismith Arpt [HRS], South Africa",
        "Harlow, Harrisburg Intl [MDT], United Kingdom",
        "Harlingen, Rio Grande Valley Intl Arpt [HRL], United States of America",
        "Hargeisa, Egal International Airport [HGA], Somalia",
        "Harbour Island, Harbour Island Arpt [HBI], Bahamas",
        "Harbin, Harbin Arpt [HRB], China",
        "Harare, Harare Arpt [HRE], Zimbabwe",
        "Hanoi, Noibai Arpt [HAN], Vietnam",
        "Hannover, Hanover Arpt [HAJ], Germany",
        "Hanksville, Intermediate Arpt [HVE], United States of America",
        "Hangzhou (Hangchow), Hangzhou Arpt [HGH], China",
        "Hancock, Houghton Cty Memorial Arpt [CMX], United States of America",
        "Hammersmith, Off Line Point [WUK], United Kingdom",
        "Hammerfest, Hammerfest Arpt [HFT], Norway",
        "Hamilton, Hamilton Arpt [HLZ], New Zealand",
        "Hamilton, Civic Airport [YHM], Canada",
        "Hamilton Island, Hamilton Island Arpt [HTI], Australia",
        "Hamburg, Fuhlsbuettel Arpt [HAM], Germany",
        "Hamar, Hamar Arpt [HMR], Norway",
        "Halmstad, Halmstad Arpt [HAD], Sweden",
        "Hall Beach, Hall Beach Arpt [YUX], Canada",
        "Halifax, Halifax Intl [YHZ], Canada",
        "Hakodate, Hakodate Arpt [HKD], Japan",
        "Haiphong, Catbi Arpt [HPH], Vietnam",
        "Haines, Haines Municipal Arpt [HNS], United States of America",
        "Hainan-Sanya, Sanya Arpt [SYX], China",
        "Hainan-Haikou, Haikou Arpt [HAK], China",
        "Hail, Hail Arpt [HAS], Saudi Arabia",
        "Haifa, Haifa Arpt [HFA], Israel",
        "Hagerstown, Washington Cty Regional [HGR], United States of America",
        "Hafr Albatin, Hafr Albatin Arpt [HBT], Saudi Arabia",
        "Hachijo Jima, Hachijo Jima Arpt [HAC], Japan",
        "Ha Apai, Salote Pilolevu Arpt [HPA], Tonga",
        "Gympie, Gympie Arpt [GYP], Australia",
        "Gweru, Gweru Airport [GWE], Zimbabwe",
        "Gwangju, Kwangju Arpt [KWJ], Korea, Republic of",
        "Gwalior, Gwalior Airport [GWL], India",
        "Guymon, Guymon Municipal Arpt [GUY], United States of America",
        "Guyana, Eugene F. Correira Intl Airport [OGL], Guyana",
        "Guwahati, Borjhar Arpt [GAU], India",
        "Gutersloh, Guetersloh Arpt [GUT], Germany",
        "Gurayat, Gurayat Arpt [URY], Saudi Arabia",
        "Gunungsitoli, Gunungsitoli Arpt [GNS], Indonesia",
        "Gunnsion, Gunnison Cty [GUC], United States of America",
        "Gunnedah, Gunnedah Airport [GUH], Australia",
        "Gulf Shores, Edwards Arpt [GUF], United States of America",
        "Guiyang, Guiyang Arpt [KWE], China",
        "Guilin, Guilin Airport [KWL], China",
        "Guettin, Guettin Arpt [GTI], Germany",
        "Guernsey, Guernsey Airport [GCI], United Kingdom",
        "Guaymas, Gen Jose M Yanez Arpt [GYM], Mexico",
        "Guayaquil, Simon Bolivar Airport [GYE], Ecuador",
        "Guatemala City, La Aurora Arpt [GUA], Guatemala",
        "Guangzhou, Baiyun Airport [CAN], China",
        "Guang Yuan, Guang Yuan Arpt [GYS], China",
        "Guam, Antonio B Won Pat Intl [GUM], Guam",
        "Guam, Anderson Air Force Base [UAM], Guam",
        "Guadalajara, Miguel Hidalgo Arpt [GDL], Mexico",
        "Gso, Piedmont Triad Intl [GSO], United States of America",
        "Groton, Groton New London Arpt [GON], United States of America",
        "Grosseto, Baccarini Arpt [GRS], Italy",
        "Groningen, Eelde Arpt [GRQ], Netherlands",
        "Groennedal, Groennedal Airport [JGR], Greenland",
        "Grodna, Grodna Arpt [GNA], Belarus",
        "Grimsby, Binbrook  [GSY], United Kingdom",
        "Griffith, Griffith Arpt [GFF], Australia",
        "Grenoble, Saint Geoirs Arpt [GNB], France",
        "Grenada, Port Saline Intl [GND], Grenada",
        "Greenwood, Greenwood Arpt [YZX], Canada",
        "Greenwood (MS), Leflore Arpt [GWO], United States of America",
        "Greenville, Pitt Greenville Arpt [PGV], United States of America",
        "Greenville, Greenville Spartanburg Arpt [GSP], United States of America",
        "Greenville, Greenville Municipal [GLH], United States of America",
        "Greenville, Greenville Downtown Arpt [GMU], United States of America",
        "Greenville, Downtown Memorial [SPA], United States of America",
        "Greenfield, Pope Field Arpt [GFD], United States of America",
        "Greeneville, Municipal Greeneville Arpt [GCY], United States of America",
        "Green Bay, Austin Straubel Fld [GRB], United States of America",
        "Great Falls (MT), Great Falls Intl Arpt [GTF], United States of America",
        "Great Bend, Greate Bend Municipal [GBD], United States of America",
        "Great Barrington, Great Barrington Arpt [GBR], United States of America",
        "Great Barrier Island, Great Barrier Island Arpt [GBZ], New Zealand",
        "Graz, Thalerhof Arpt [GRZ], Austria",
        "Gravatai, Gravatai Arpt [GCV], Brazil",
        "Grande Prairie, Grande Prairie Arpt [YQU], Canada",
        "Grand Turk, Grand Turk Is Arpt [GDT], Turks And Caicos Islands",
        "Grand River, Green River Arpt [RVR], United States of America",
        "Grand Rapids, Kent County Intl [GRR], United States of America",
        "Grand Rapids (MN), Itasca County [GPZ], United States of America",
        "Grand Junction, Walker Field Arpt [GJT], United States of America",
        "Grand Island, Hall Cty Regional [GRI], United States of America",
        "Grand Forks, Grand Forks Mark Andrews Intl [GFK], United States of America",
        "Grand Canyon (South), Grand Canyon Natl Park Arpt [GCN], United States of America",
        "Granada, Granada Arpt [GRX], Spain",
        "Gran Cayman Island, Owen Roberts Arpt [GCM], Cayman Islands",
        "Gran Canaria-Las Palmas, Aeropuerto De Gran Canaria [LPA], Spain",
        "Grafton, Grafton Arpt [GFN], Australia",
        "Graciosa Island, Graciosa Arpt [GRW], Portugal",
        "Gozo, Gozo Arpt [GZM], Malta",
        "Governor S Harbour, Governors Harbour Municipal Arpt [GHB], Bahamas",
        "Governador Valadares, Governador Valadares Arpt [GVR], Brazil",
        "Gove, Nhulunbuy Arpt [GOV], Australia",
        "Gothenburg, Saeve Arpt [GSE], Sweden",
        "Gothenburg, Landvetter Arpt [GOT], Sweden",
        "Goroka, Goroka Arpt [GKA], Papua New Guinea",
        "Gorakhpur, Gorakhpur Airport [GOP], India",
        "Goose Bay, Goose Bay Municipal Arpt [YYR], Canada",
        "Goondiwindi, Goondiwindi Arpt [GOO], Australia",
        "Goodyear, Litchfield Goodyear Arpt [GYR], United States of America",
        "Goodland, Goodland Municipal [GLD], United States of America",
        "Gonder, Gondar Airport [GDQ], Ethiopia",
        "Gomel, Gomel Arpt [GME], Belarus",
        "Goldsboro, Seymour Johnson AFB [GSB], United States of America",
        "Gold Coast, Gold Coast Arpt [OOL], Australia",
        "Gol City, Klanten Arpt [GLL], Norway",
        "Goiania, Santa Genoveva [GYN], Brazil",
        "Gode, Gode/Iddidole Airport [GDE], Ethiopia",
        "Goa, Mopa International Airport [GOX], India",
        "Goa, Dabolim Arpt [GOI], India",
        "Gloucester, Gloucestershire [GLO], United Kingdom",
        "Glenwood Springs, Glenwood Springs Arpt [GWS], United States of America",
        "Glens Falls, Warren County [GFL], United States of America",
        "Glen Innes, Glen Innes Arpt [GLI], Australia",
        "Glasgow, Prestwick Arpt [PIK], United Kingdom",
        "Glasgow, Glasgow Municipal Arpt [GLW], United States of America",
        "Glasgow, Glasgow Intl [GLA], United Kingdom",
        "Glasgow  Montana, International Glasgow [GGW], United States of America",
        "Gladstone, Gladstone Airport [GLT], Australia",
        "Glacier Bay, Gustavus Arpt [GST], United States of America",
        "Gjoa Haven, Gjoa Haven Arpt [YHK], Canada",
        "Gizan, Gizan Aprt [GIZ], Saudi Arabia",
        "Gisenyi, Gisenyi Arpt [GYI], Rwanda",
        "Gisborne, Gisborne Arpt [GIS], New Zealand",
        "Gillette, Campbell Cty Arpt [GCC], United States of America",
        "Gilgit, Gilgit Arpt [GIL], Pakistan",
        "Gibraltar, North Front Arpt [GIB], Gibraltar",
        "Ghardaia, Noumerate Arpt [GHA], Algeria",
        "Gettysburg, Gettysburg Arpt [GTY], United States of America",
        "Gethsemanie, Gethsemanie Arpt [ZGS], Canada",
        "Gerona, Costa Brava Arpt [GRO], Spain",
        "Geraldton, Geraldton Arpt [GET], Australia",
        "Georgetown, Sussex County Arpt [GED], United States of America",
        "Georgetown, Cheddi Jagan Intl [GEO], Guyana",
        "George, George Arpt [GRJ], South Africa",
        "George Town, George Town Airport [GGT], Bahamas",
        "Genoa, Christoforo Colombo [GOA], Italy",
        "Geneva, Geneve Cointrin [GVA], Switzerland",
        "General Santos, Buayan Arpt [GES], Philippines",
        "Geilo, Dagali Arpt [DLD], Norway",
        "Geelong, Geelong Arpt [GEX], Australia",
        "Gdansk, Rebiechowo [GDN], Poland",
        "Gbangbatok, Gbangbatok Arpt [GBK], Sierra Leone",
        "Gaziantep, Gaziantep Arpt [GZT], Turkey",
        "Gaza, Gaza International Arpt [GZA], Occupied Palestinian Territory",
        "Gaylord, Otsego Arpt [GLR], United States of America",
        "Gaya, Gaya Arpt [GAY], India",
        "Gavle, Sandviken Arpt [GVX], Sweden",
        "Gatlinburg, Gatlinburg Arpt [GKT], United States of America",
        "Gatineau Hull, Gatineau Hull Municipal Arpt [YND], Canada",
        "Gassim, Gassim Arpt [ELQ], Saudi Arabia",
        "Gaspe, Gaspe Municipal Arpt [YGP], Canada",
        "Gary, Gary Regional Arpt [GYY], United States of America",
        "Garoua, Garoua Arpt [GOU], Cameroon",
        "Garoe, Garoe Airport [GGR], Somalia",
        "Gare du Nord, Paris Gare Du Nord [XPG], France",
        "Garden City (KS), Garden City Municipal [GCK], United States of America",
        "Garaina, Garaina Arpt [GAR], Papua New Guinea",
        "Gap France, Tallard Arpt [GAT], France",
        "Ganzhou, Ganzhou Arpt [KOW], China",
        "Gangtok, Pakyong [PYG], India",
        "Gander, Gander International [YQX], Canada",
        "Galway, Carnmore Arpt [GWY], Ireland",
        "Galveston, Scholes Field [GLS], United States of America",
        "Gallup, Gallup Municipal [GUP], United States of America",
        "Gallivare, Gallivare Arpt [GEV], Sweden",
        "Galion, Galion Municipal Arpt [GQQ], United States of America",
        "Galesburg, Galesburg Arpt [GBG], United States of America",
        "Galena, Galena Arpt [GAL], United States of America",
        "Galapagos Is, Baltra Arpt [GPS], Ecuador",
        "Gaithersburg, Montgomery Cty Arpt [GAI], United States of America",
        "Gainesville, Gainesville Municipal Arpt [GLE], United States of America",
        "Gainesville (FL), Gainesville Regional [GNV], United States of America",
        "Gadsden, Gadsden Municipal [GAD], United States of America",
        "Gaborone, Gaborone Arpt [GBE], Botswana",
        "Fuzhou, Fuzhou Arpt [FOC], China",
        "Funafuti, Funafuti Intl Arpt [FUN], Tuvalu",
        "Fullerton, Fullerton Municipal Arpt [FUL], United States of America",
        "Fukushima, Fukushima Arpt [FKS], Japan",
        "Fukuoka, Itazuke Arpt [FUK], Japan",
        "Fukue, Fukue Arpt [FUJ], Japan",
        "Fuerte Olimpo, Fuerte Olimpo Arpt [OLK], Paraguay",
        "Ft Worth, Meacham Field [FTW], United States of America",
        "Ft Wayne, Baer Field [FWA], United States of America",
        "Ft St John, Ft St John Municipal Arpt [YXJ], Canada",
        "Ft Smith, Ft Smith Municipal Arpt [YSM], Canada",
        "Ft Mcmurray, Ft McMurray Municipal Arpt [YMM], Canada",
        "Ft Leonard Wood, Forney Field [TBN], United States of America",
        "Ft Huachuca, Sierra Vista Municipal [FHU], United States of America",
        "Ft De France, Lamentin Arpt [FDF], Martinique",
        "Front Royal, Warren County Arpt [FRR], United States of America",
        "Fritzlar, Fritzlar Airbase [FRZ], Germany",
        "Friday Harbor, Friday Harbor Airport [FRD], United States of America",
        "Frethun, Calais / Dunkerque [XFF], France",
        "Fresno, Fresno Chandler Arpt [FCH], United States of America",
        "Fresno, Fresno Air Terminal [FAT], United States of America",
        "Frejus, Frejus Arpt [FRJ], France",
        "Freetown, Lungi Intl Arpt [FNA], Sierra Leone",
        "Freeport, Grand Bahama Intl Arpt [FPO], Bahamas",
        "Frederikshavn, Friedrichshafen Lowenthal [FDH], Denmark",
        "Fredericton, Fredericton International Airport [YFC], Canada",
        "Frederick, Frederick Municipal [FDK], United States of America",
        "Franklin (PA), Chess Lamberton Arpt [FKL], United States of America",
        "Frankfurt, Neu Isenburg Arpt [QGV], Germany",
        "Frankfurt, Hahn Arpt [HHN], Germany",
        "Frankfurt, Frankfurt Intl [FRA], Germany",
        "Francistown, Francistown Arpt [FRW], Botswana",
        "Franceville Mvengue, Franceville Mvengue Arpt [MVB], Gabon",
        "Foula, Foula [FOA], United Kingdom",
        "Fortaleza, Pinto Martines Arpt [FOR], Brazil",
        "Fort William, Heliport [FWM], United Kingdom",
        "Fort Stockton, Pecos County Arpt [FST], United States of America",
        "Fort Smith, Ft Smith Municipal [FSM], United States of America",
        "Fort Polk, Fort Polk Arpt [POE], United States of America",
        "Fort Pierce, St Lucie County Arpt [FPR], United States of America",
        "Fort Nelson, Ft Nelson Municipal Arpt [YYE], Canada",
        "Fort Myers, Regional Southwest Arpt [RSW], United States of America",
        "Fort Myers, Page Field [FMY], United States of America",
        "Fort Madison, Fort Madison Municipal [FMS], United States of America",
        "Fort Lauderdale, Ft Lauderdale Hollywood Intl Arpt [FLL], United States of America",
        "Fort Lauderdale, Ft Lauderdale Excutive [FXE], United States of America",
        "Fort Lauderdale, Boca Raton Public [BCT], United States of America",
        "Fort Frances, Fort Frances Municipal [YAG], Canada",
        "Fort Dodge, Ft Dodge Municipal [FOD], United States of America",
        "Fort Collins, Municipal Airport [FNL], United States of America",
        "Forster, Forster [FOT], Australia",
        "Forrest City, Forrest City Municipal Arpt [FCY], United States of America",
        "Forres, Kinloss [FSS], United Kingdom",
        "Formosa, El Pucu Arpt [FMA], Argentina",
        "Forli, Luigi Ridolfi Arpt [FRL], Italy",
        "Forde, Bringeland Arpt [FDE], Norway",
        "Foggia, Gino Lisa Arpt [FOG], Italy",
        "Flotta, Flotta [FLH], United Kingdom",
        "Floro, Flora Arpt [FRO], Norway",
        "Florianopolis, Hercilio Luz Arpt [FLN], Brazil",
        "Floriano, Cangapara Arpt [FLB], Brazil",
        "Flores, Flores Airport [FRS], Guatemala",
        "Florence, Amerigo Vespucci Arpt [FLR], Italy",
        "Florence (SC), Gilbert Field [FLO], United States of America",
        "Flippin, Flippin Arpt [FLP], United States of America",
        "Flint, Bishop Intl Arpt [FNT], United States of America",
        "Flin Flon, Flin Flon Municipal Arpt [YFO], Canada",
        "Flensburg, Schaferhaus Arpt [FLF], Germany",
        "Flagstaff, Flagstaff Arpt [FLG], United States of America",
        "Filton, Filton [FZO], United Kingdom",
        "Figari, Sud Corse Arpt [FSC], France",
        "Ficksburg, Ficksburg Sentra Oes [FCB], South Africa",
        "Fez, Fez Airport [FEZ], Morocco",
        "Fetlar, Fetlar [FEA], United Kingdom",
        "Fernando De Noronha, Fernando De Noronha Arpt [FEN], Brazil",
        "Fergus Falls, Fergus Falls Municipal [FFM], United States of America",
        "Fayetteville, Northwest Arkansas Regional Arpt [XNA], United States of America",
        "Fayetteville, Fayetteville Municipal [FAY], United States of America",
        "Fayetteville, Fayetteville Municipal Arpt [FYV], United States of America",
        "Faro, Faro Airport [FAO], Portugal",
        "Farnborough Hampeshire, Farnborough Airport [FAB], United Kingdom",
        "Farmington, Four Corners Regional Arpt [FMN], United States of America",
        "Farmingdale, Republic Arpt [FRG], United States of America",
        "Fargo, Hector Airport [FAR], United States of America",
        "Falmouth, Otis AFB [FMH], United States of America",
        "Falls Creek, Falls Creek Arpt [FLC], Australia",
        "Fallon, Fallon Municipal Arpt [FLX], United States of America",
        "Fajardo, Fajardo Arpt [FAJ], Puerto Rico",
        "Faisalabad, Faisalabad Intl Airport [LYP], Pakistan",
        "Fairmont (MN), Fairmont Municipal [FRM], United States of America",
        "Fairfield, Travis AFB [SUU], United States of America",
        "Fairbanks, Fairbanks Intl Arpt [FAI], United States of America",
        "Fair Isle, Fair Isle [FIE], United Kingdom",
        "Faial - Horta (Azores), Horta Arpt [HOR], Portugal",
        "Fagernes, Valdres Arpt [VDB], Norway",
        "Faeroe Islands, Faeroe Airport [FAE], Denmark",
        "Exmouth Gulf, Exmouth Gulf [EXM], Australia",
        "Exeter, Exeter Arpt [EXT], United Kingdom",
        "Excursion Inlet, Excursion Inlet Municipal [EXI], United States of America",
        "Evreux, Evreux Arpt [EVX], France",
        "Everett, Snohomish Cty Arpt [PAE], United States of America",
        "Eveleth, Eveleth Virginia Municipal Arpt [EVM], United States of America",
        "Evansville, Evansville Regional Arpt [EVV], United States of America",
        "Eugene, Eugene Arpt [EUG], United States of America",
        "Eufaula, Weedon Field [EUF], United States of America",
        "Estevan, Estevan Arpt [YEN], Canada",
        "Essen, Essen Arpt [ESS], Germany",
        "Essaouira, Essaouira Arpt [ESU], Morocco",
        "Esquel, Esquel Airport [EQS], Argentina",
        "Espiritu Santo, Pekoa Arpt [SON], Vanuatu",
        "Esperance, Esperance Arpt [EPR], Australia",
        "Eskilstuna, Eskilstuna Arpt [EKT], Sweden",
        "Escanaba, Delta County [ESC], United States of America",
        "Esbjerg, Esbjerg Arpt [EBJ], Denmark",
        "Erzurum, Erzurum Arpt [ERZ], Turkey",
        "Erzincan, Erzincan Arpt [ERC], Turkey",
        "Erie, Erie Intl [ERI], United States of America",
        "Erfurt, Erfurt Arpt [ERF], Germany",
        "Ercan, Ercan Airport [ECN], Cyprus",
        "Epinal, Mirecourt Arpt [EPL], France",
        "Enugu, Enugu [ENU], Nigeria",
        "Enterprise, Enterprise Municipal [ETS], United States of America",
        "Entebbe, Entebbe Airport [EBB], Uganda",
        "Enschede, Twente Airport [ENS], Netherlands",
        "Enniskillen, St Angelo Arpt [ENK], United Kingdom",
        "Enesnada, Ensenada Arpt [ESE], Mexico",
        "Emerald, Emerald Arpt [EMD], Australia",
        "Ely Nv, Yelland Field [ELY], United States of America",
        "Ely Mn, Ely Municipal Arpt [LYU], United States of America",
        "Elmira, Elmira Corning Regional Arpt [ELM], United States of America",
        "Ellot Lake, Elliot Lake Arpt [YEL], Canada",
        "Elko, J C Harris Field [EKO], United States of America",
        "Elkins, Randolph County Arpt [EKN], United States of America",
        "Elkhart (IN), Elkhart Municipal Arpt [EKI], United States of America",
        "Elk City, Elk City Municipal [ELK], United States of America",
        "Elizabethtown (KY), Addington Field [EKX], United States of America",
        "Elizabeth City, Elizabeth Municipal Cgas [ECG], United States of America",
        "Elista, Elista Arpt [ESL], Russian Federation",
        "Eldoret, Eldoret Arpt [EDL], Kenya",
        "Elba Island, Marina Di Campo Arpt [EBA], Italy",
        "Elazig, Elazig Arpt [EZS], Turkey",
        "El Salvador, El Salvador Arpt [ESR], Chile",
        "El Paso, El Paso Intl Arpt [ELP], United States of America",
        "El Paso, Biggs Army Air Field [BIF], United States of America",
        "El Nido, El Nido Airport [ENI], Philippines",
        "El Monte, El Monte Arpt [EMT], United States of America",
        "El Eden, El Eden Arpt [AXM], Colombia",
        "El Dorado, Goodwin Field [ELD], United States of America",
        "El Centro Imperial, Imperial County [IPL], United States of America",
        "El Calafate, El Calafate Arpt [FTE], Argentina",
        "El Cajon, El Cajon Arpt [CJN], United States of America",
        "El  Bolson, El Bolson Arpt [EHL], Argentina",
        "El  Aaiun, Hassan I Arpt [EUN], Morocco",
        "Eisenach, Eisenach Arpt [EIB], Germany",
        "Eindhoven, Welschap Arpt [EIN], Netherlands",
        "Eilat, Elat Airport [ETH], Israel",
        "Egilstadir, Egilsstadir Arpt [EGS], Iceland",
        "Edward River, Edward River Arpt [EDR], Australia",
        "Edson, Edson Arpt [YET], Canada",
        "Edremit, Balikesir Koca Seyit Airport [EDO], Turkey",
        "Edmonton, Edmonton Municipal Arpt [YXD], Canada",
        "Edmonton, Edmonton Intl Arpt [YEG], Canada",
        "Edinburgh, Edinburgh Arpt [EDI], United Kingdom",
        "Edenton, Edenton Municipal Arpt [EDE], United States of America",
        "Eday, Eday [EOI], United Kingdom",
        "Echuca, Echuca Arpt [ECH], Australia",
        "Ebon, Ebon Arpt [EBO], Marshall Islands",
        "Eau Claire, Claire Municipal Airport [EAU], United States of America",
        "Easton (MD), Easton Municipal Arpt [ESN], United States of America",
        "Easter Island, Mataveri Intl Arpt [IPC], Chile",
        "East Stroudsburg, Birchwood Pocono Arpt [ESP], United States of America",
        "East Sound, Eastsound Orcas Is Arpt [ESD], United States of America",
        "East London, East London Arpt [ELS], South Africa",
        "East Hartford, Rentschler Arpt [EHT], United States of America",
        "East Hampton, East Hampton Arpt [HTO], United States of America",
        "Eagle, Eagle County Arpt [EGE], United States of America",
        "Eagle, Eagle Airport [EAA], United States of America",
        "Eagle River (WI), Eagle River Union Arpt [EGV], United States of America",
        "Eagle Pass, Maverick County Arpt [EGP], United States of America",
        "ERBIL, Erbil International Airport [EBL], Iraq",
        "E Vigia, El Vigia Arpt [VIG], Venezuela",
        "Dzaoudzi, Dzaoudzi Arpt [DZA], Mayotte",
        "Dutch Harbor, Emergency Field [DUT], United States of America",
        "Dusseldorf, Niederrhein Arpt [NRN], Germany",
        "Dusseldorf, Moenchen Gi Dus Exp [MGL], Germany",
        "Dusseldorf, Dusseldorf Arpt [DUS], Germany",
        "Dusseldorf,  Hbf Railway Station [QDU], Germany",
        "Dushanbe, Dushanbe Arpt [DYU], Tajikistan",
        "Durgapur, Kazi Nazrul Islam [RDP], India",
        "Durban, Virginia Arpt [VIR], South Africa",
        "Durban, Durban International [DUR], South Africa",
        "Durango, Guadalupe Victoria Arpt [DGO], Mexico",
        "Durango, Durango La Plata Cty Arpt [DRO], United States of America",
        "Durango, Animas Airpark [AMK], United States of America",
        "Dunk Island, Dunk Island Arpt [DKI], Australia",
        "Dunhuang, Dunhuang Arpt [DNH], China",
        "Dunedin, Momona Airport [DUD], New Zealand",
        "Dundee, ScotRail [ZDU], United Kingdom",
        "Dundee, Dundee Riverside [DND], United Kingdom",
        "Duncan (OK), Halliburton Arpt [DUC], United States of America",
        "Dumaguete, Dumaguete Arpt [DGT], Philippines",
        "Duluth, Duluth Intl [DLH], United States of America",
        "Dubuque, Dubuque Municipal Arpt [DBQ], United States of America",
        "Dubrovnik, Dubrovnik Arpt [DBV], Croatia",
        "Dubois, Dubois Jefferson Cty Arpt [DUJ], United States of America",
        "Dublin, Dublin Municipal Arpt [DBN], United States of America",
        "Dublin, Dublin Arpt [DUB], Ireland",
        "Dublin (VA), New River Valley Arpt [PSK], United States of America",
        "Dubbo, Dubbo Arpt [DBO], Australia",
        "Dubai, Travel Mall Dubai Etihad Bus Station [XNB], United Arab Emirates",
        "Dubai, Dubai Intl Arpt [DXB], United Arab Emirates",
        "Dubai, Al Maktoum Intl Arpt [DWC], United Arab Emirates",
        "Dresden, Dresden Arpt [DRS], Germany",
        "Doylestown, Doylestown Arpt [DYL], United States of America",
        "Dover, Dover AFB [DOV], United States of America",
        "Dover, Delaware Airpark [DVX], United States of America",
        "Douglas, Bisbee Douglas Intl [DUG], United States of America",
        "Douala, Douala Arpt [DLA], Cameroon",
        "Dothan, Dothan Municipal [DHN], United States of America",
        "Dos Lagunas, Dos Lagunas Airport [DON], Guatemala",
        "Dortmund, Wickede Dortmund Arpt [DTM], Germany",
        "Dornoch, Dornoch [DOC], United Kingdom",
        "Doomadgee, Doomadgee Arpt [DMD], Australia",
        "Dongguan, Dongguan Arpt [DGM], China",
        "Dongara, Dongara [DOX], Australia",
        "Donetsk, Donetsk Arpt [DOK], Ukraine",
        "Donegal, Donegal Arpt [CFN], Ireland",
        "Doncaster, Robin Hood International [DSA], United Kingdom",
        "Dominica, Melville Hall Arpt [DOM], Dominica",
        "Dominica, Canefield Arpt [DCF], Dominica",
        "Dole, Tavaux Arpt [DLE], France",
        "Doha, DOHA International Airport [DIA], Qatar",
        "Doha, DOHA Hamad International airport [DOH], Qatar",
        "Dodge City, Dodge City Municipal Arpt [DDC], United States of America",
        "Dnepropetrovsk, Dnepropetrovsk Arpt [DNK], Ukraine",
        "Djibouti, Ambouli Airport [JIB], Djibouti",
        "Djerba, Melita Airport [DJE], Tunisia",
        "Diyarbai, Diyarbakirarpt [DIY], Turkey",
        "Diu In, Diu Arpt [DIU], India",
        "Dire Dawa, Aba Tenna D Yilma Arpt [DIR], Ethiopia",
        "Diqing, Diging Arpt [DIG], China",
        "Dipolog, Dipolog Arpt [DPL], Philippines",
        "Dinard, Pleurtuit Arpt [DNR], France",
        "Dimapur, Dimapur Airport [DMU], India",
        "Dillon, Dillon Arpt [DLL], United States of America",
        "Dillingham, Dillingham Municipal Arpt [DLG], United States of America",
        "Dili, Comoro Arpt [DIL], Timor-Leste",
        "Dijon, Longvic Airport [DIJ], France",
        "Dieppe, Dieppe Arpt [DPE], France",
        "Dien Bien Phu, Gialam Arpt [DIN], Vietnam",
        "Diegosuarez, Antsiranana Arrachart Arpt [DIE], Madagascar",
        "Dickinson, Dickinson Municipal [DIK], United States of America",
        "Dibrugarh, Chabua Arpt [DIB], India",
        "Dharamshala, Gaggal Airport [DHM], India",
        "Dhaka, Shahjalal International Airport [DAC], Bangladesh",
        "Dhahran, Dhahran Intl [DHA], Saudi Arabia",
        "Dewsbury, British Rail Terminal [ZEQ], United Kingdom",
        "Devonport, Devonport Arpt [DPO], Australia",
        "Devils Lake, Devils Lake Arpt [DVL], United States of America",
        "Detroit, Willow Run Arpt [YIP], United States of America",
        "Detroit, Selfridge Air Natl Guard [MTC], United States of America",
        "Detroit, Detroit Metro Wayne Cnty Arpt [DTW], United States of America",
        "Detroit, Detroit City Apt [DET], United States of America",
        "Detroit, Berz Macomb Arpt [UIZ], United States of America",
        "Destin, Destin Arpt [DSI], United States of America",
        "Dessie, Dessie Airport [DSE], Ethiopia",
        "Des Moines (IA), Des Moines Municipal Airport [DSM], United States of America",
        "Derby, Derby Airport [DRB], Australia",
        "Dera Ismail Khan, Dera Ismail Khan Airport [DSK], Pakistan",
        "Denver, Denver Intl Arpt [DEN], United States of America",
        "Denver, Centennial Arpt [APA], United States of America",
        "Denizli, Cardak Arpt [DNZ], Turkey",
        "Deniliquin, Denilinquin Arpt [DNQ], Australia",
        "Denham, Denham Arpt [DNM], Australia",
        "Delhi, Delhi Indira Gandhi Intl [DEL], India",
        "Del Rio, International Del Rio [DRT], United States of America",
        "Deirezzor, Al Jafrah Arpt [DEZ], Syrian Arab Republic",
        "Dehra Dun, Jolly Grant Airport [DED], India",
        "Defiance, Defiance Memorial Arpt [DFI], United States of America",
        "Deer Lake, Deer Lake Municipal [YDF], Canada",
        "Deer Lake, Deer Lake Arpt [YVZ], Canada",
        "Decorah, Decorah Municipal [DEH], United States of America",
        "Decatur, Decatur Municipal [DEC], United States of America",
        "Decatur (AL), Pyor Arpt [DCU], United States of America",
        "Debrecen, Debrecen Arpt [DEB], Hungary",
        "Deauville, Saint Gatien Arpt [DOL], France",
        "Deadmans Cay, Deadmans Cay Arpt [LGI], Bahamas",
        "Daytona Beach, Daytona Beach Regional Arpt [DAB], United States of America",
        "Dayton (OH), Dayton International Airport [DAY], United States of America",
        "Daydream Island, Daydream Island Arpt [DDI], Australia",
        "Dawson Creek, Dawson Creek Arpt [YDQ], Canada",
        "David, Enrique Malek Arpt [DAV], Panama",
        "Davenport, Davenport Airport [DVN], United States of America",
        "Davao City, Mati Airport [DVO], Philippines",
        "Daugavpils, Daugavpils Arpt [DGP], Latvia",
        "Dashoguz, Dashoguz Arpt [TAZ], Turkmenistan",
        "Darwin, Darwin Airport [DRW], Australia",
        "Darbhanga, Darbhanga Airport [DBR], India",
        "Dar-Es-Salaam, Es Salaam Intl [DAR], Tanzania",
        "Danville, Danville Municipal [DAN], United States of America",
        "Danville (IL), Vermillion Cty [DNV], United States of America",
        "Dandong (Andong), Dandong Arpt [DDG], China",
        "Danbury, Danbury Municipal Arpt [DXR], United States of America",
        "Dammam, King Fahad Arpt [DMM], Saudi Arabia",
        "Damascus, Damascus Intl [DAM], Syrian Arab Republic",
        "Dallas, Love Field [DAL], United States of America",
        "Dallas, Dallas Ft Worth Intl [DFW], United States of America",
        "Dallas, Addison Arpt [ADS], United States of America",
        "Dalian, Dalian Arpt [DLC], China",
        "Dali, Dali Arpt [DLU], China",
        "Dalby, Dalby [DBY], Australia",
        "Dalat, Lienkhang [DLI], Vietnam",
        "Dalaman, Dalman Airport [DLM], Turkey",
        "Dakhla, Dakhla Airport [VIL], Morocco",
        "Dakhla Oasis, Dakhla Arpt [DAK], Egypt",
        "Dakar, Leopold Sedar Senghor Arpt [DKR], Senegal",
        "Dakar, Blaise Diagne Int [DSS], Senegal",
        "Daegu, Daegu Arpt [TAE], Korea, Republic of",
        "Da Nang, Da Nang Arpt [DAD], Vietnam",
        "Cuzco, Tte Velazco Astete Arpt [CUZ], Peru",
        "Cuxhaven, Cuxhaven Nordholz Arpt [FCN], Germany",
        "Curitiba, Afonso Pena Arpt [CWB], Brazil",
        "Curacao, Areopuerto Hato Arpt [CUR], Netherlands Antilles",
        "Cuneo, Levaldigi Arpt [CUF], Italy",
        "Cumberland, Cumberland Municipal Arpt [CBE], United States of America",
        "Culiacan, Fedl De Bachigualato Arpt [CUL], Mexico",
        "Culebra, Culebra Arpt [CPX], United States of America",
        "Cuidad Del Carmen, Ciudad Del Carmen Arpt [CME], Mexico",
        "Cuiaba, Marechal Rondon Arpt [CGB], Brazil",
        "Cuernavaca, Cuernavaca Arpt [CVJ], Mexico",
        "Cuenca, Cuenca Arpt [CUE], Ecuador",
        "Cuddapah, Cuddapah Airport [CDP], India",
        "Cudal, Cudal Arpt [CUG], Australia",
        "Cucuta, Camilo Dazo Arpt [CUC], Colombia",
        "Cuba-Cienfuegos, Cienfuegos Arpt [CFG], Cuba",
        "Csg, Columbus Metro Ft Benning Arpt [CSG], United States of America",
        "Cruzeiro Do Sul, Campo Intl Arpt [CZS], Brazil",
        "Crotone, Crotone Arpt [CRV], Italy",
        "Cromarty, Cromarty [CRN], United Kingdom",
        "Criciuma, Criciuma Arpt [CCM], Brazil",
        "Crete-Heraklion, N Kazantzakis Arpt [HER], Greece",
        "Crete-Chania, Souda Arpt [CHQ], Greece",
        "Creston, Creston Arpt [CFQ], Canada",
        "Crescent City, Crescent City Municipal Arpt [CEC], United States of America",
        "Creil, Creil Arpt [CSF], France",
        "Cranbrook, Cranbrook Municipal [YXC], Canada",
        "Craiova, Craiova Arpt [CRA], Romania",
        "Craig, Craig Seaplane Base [CGA], United States of America",
        "Craig (CO), Craig Moffat [CIG], United States of America",
        "Cozumel, Aeropuerto Intl De Cozumel [CZM], Mexico",
        "Coyhaique, Teniente Vidal Arpt [GXQ], Chile",
        "Coxs Bazar, Coxs Bazar Airport [CXB], Bangladesh",
        "Cowra, Cowra Arpt [CWT], Australia",
        "Coventry, Coventry – West Midlands [CVT], United Kingdom",
        "Courchevel, Courchevel Arpt [CVF], France",
        "Council, Melsing Creek Arpt [CIL], United States of America",
        "Council Bluffs, Council Bluffs Municipal Arpt [CBF], United States of America",
        "Cottonwood, Cottonwood Airport [CTW], United States of America",
        "Cotonou, Cotonou Airport [COO], Benin",
        "Cotabato, Awang Arpt [CBO], Philippines",
        "Corvo Island, Corvo Arpt [CVU], Portugal",
        "Corvallig, Corvallis Municipal Arpt [CVO], United States of America",
        "Corumba, Internacional Corumba [CMG], Brazil",
        "Cortez, Montezuma County [CEZ], United States of America",
        "Corrientes, Camba Punta Arpt [CNQ], Argentina",
        "Corpus Christi, Corpus Christi Intl Arpt [CRP], United States of America",
        "Coromandel, Coromandel Arpt [CMV], New Zealand",
        "Coro, Coro Arpt [CZE], Venezuela",
        "Cork, Cork International Arpt [ORK], Ireland",
        "Corinth, Roscoe Turner Arpt [CRX], United States of America",
        "Corfu Town, I Kapodistrias Arpt [CFU], Greece",
        "Cordova, Mudhole Smith Arpt [CDV], United States of America",
        "Cordoba, Pajas Blanco Arpt [COR], Argentina",
        "Cordoba, Cordoba Airport [ODB], Spain",
        "Coral Harbour, Coral Harbour Arpt [YZS], Canada",
        "Copiapo, Chamonate Arpt [CPO], Chile",
        "Copenhagen, Copenhagen Arpt [CPH], Denmark",
        "Coonamble, Coonamble Arpt [CNB], Australia",
        "Coonabarabran, Coonabarabran Arpt [COJ], Australia",
        "Cooma, Cooma Airport [OOM], Australia",
        "Cooktown, Cooktown Arpt [CTN], Australia",
        "Cook Islands-Aitutaki, Aitutaki Arpt [AIT], Cook Islands",
        "Cooinda, Cooinda Airport [CDA], Australia",
        "Coober Pedy, Coober Pedy Arpt [CPD], Australia",
        "Constantine, Ain El Bey Arpt [CZL], Algeria",
        "Constanta, Kogalniceanu Arpt [CND], Romania",
        "Conroe, Montgomery Co Arpt [CXO], United States of America",
        "Concordia, Concordia Arpt [COC], Argentina",
        "Concord, Buchanan Field [CCR], United States of America",
        "Concord (NH), Concord Arpt [CON], United States of America",
        "Concepcion, Mcal Lopez Arpt [CIO], Paraguay",
        "Concepcion, Carriel Sur Arpt [CCP], Chile",
        "Conakry, Conakry Airport [CKY], Guinea",
        "Comodoro Rivadavia, Comodoro Rivadavia Arpt [CRD], Argentina",
        "Comiso, Comiso Arpt [CIY], Italy",
        "Columbus, Lowndes Cty Arpt [UBS], United States of America",
        "Columbus, Golden Arpt [GTR], United States of America",
        "Columbus, Columbus Municipal [CUS], United States of America",
        "Columbus (OH), Rickenbacker Intl Arpt [LCK], United States of America",
        "Columbus (OH), Port Columbus Intl Arpt [CMH], United States of America",
        "Columbus (OH), Ohio State Univ Arpt [OSU], United States of America",
        "Columbus (NE), Columbus Arpt [OLU], United States of America",
        "Columbus (IN), Columbus Municipal Arpt [CLU], United States of America",
        "Columbia, Orangeburg Municpal [OGB], United States of America",
        "Columbia, Columbia Regional [COU], United States of America",
        "Columbia, Columbia Metro Arpt [CAE], United States of America",
        "Columbia (TN), Maury Country Arpt [MRC], United States of America",
        "Colorado Springs, Colorado Springs Municipal Arpt [COS], United States of America",
        "Colonia, Colonia Arpt [CYR], Uruguay",
        "Colombo, Bandaranaike Intl Arpt [CMB], Sri Lanka",
        "Cologne, Koeln Bonn Arpt [CGN], Germany",
        "Colmar, Colmar Houssen Arpt [CMR], France",
        "Collinsville, Collinsville Arpt [KCE], Australia",
        "College Station, Easterwood Field [CLL], United States of America",
        "Colima, Colima Arpt [CLQ], Mexico",
        "Cold Lake, Cold Lake Arpt [YOD], Canada",
        "Colby, Colby Municipal Arpt [CBK], United States of America",
        "Coimbra, Coimbra Arpt [CBP], Portugal",
        "Coimbatore, Peelamedu Airport [CJB], India",
        "Cognac, Parvaud Arpt [CNG], France",
        "Coffs Harbour, Coffs Harbour Arpt [CFS], Australia",
        "Coeur D Alene, Coeur d Alene Municipal Arpt [COE], United States of America",
        "Coen, Coen Arpt [CUQ], Australia",
        "Cody, Yellowstone Regional Arpt [COD], United States of America",
        "Cocos Islands, Cocos Islands Arpt [CCK], Cocos (Keeling )Islands",
        "Coconut Island, Coconut Island Arpt [CNC], Australia",
        "Cocoa Metro Area, Patrick AFB [COF], United States of America",
        "Cocoa Metro Area, Merrit Island Arpt [COI], United States of America",
        "Cochabamba, J Wilsterman Arpt [CBB], Bolivia",
        "Cobar, Cobar Arpt [CAZ], Australia",
        "Cluj, Napoca Arpt [CLJ], Romania",
        "Clovis, Clovis Airport [CVN], United States of America",
        "Cloncurry, Cloncurry Arpt [CNJ], Australia",
        "Clinton (OK), Sherman Arpt [CSM], United States of America",
        "Clinton (OK), Clinton Municipal Arpt [CLK], United States of America",
        "Clinton (IA), Clinton Municipal [CWI], United States of America",
        "Cleveland (OH), Hopkins Intl Arpt [CLE], United States of America",
        "Cleveland (OH), Cuyahoga County Airport [CGF], United States of America",
        "Cleveland (OH), Burke Lakefront Arpt [BKL], United States of America",
        "Clermont-Ferrand, Aulnat Arpt [CFE], France",
        "Clermont, Clermont Arpt [CMQ], Australia",
        "Clemson, Clemson Oconee Cty Arpt [CEU], United States of America",
        "Clear Lake City, Metroport [CLC], United States of America",
        "Clarksville, Outlaw Field [CKV], United States of America",
        "Clarksdale, Fletcher Field [CKM], United States of America",
        "Clarksburg, Clarksburg Benedum Arpt [CKB], United States of America",
        "Ciudad del este, Alejo Garcia Arpt [AGT], Paraguay",
        "Ciudad Victoria, Ciudad Victoria Arpt [CVM], Mexico",
        "Ciudad Real, Ciudad Real Arpt [CJI], Spain",
        "Ciudad Obregon, Ciudad Obregon Arpt [CEN], Mexico",
        "Ciudad Mante, Ciudad Mante Arpt [MMC], Mexico",
        "Ciudad Juarez, Intl Abraham Gonzalez [CJS], Mexico",
        "Ciudad Constitucion, Ciudad Constitucion Arpt [CUA], Mexico",
        "Ciudad Bolivar, Ciudad Bolivar Arpt [CBL], Venezuela",
        "Cirebon, Penggung Arpt [CBN], Indonesia",
        "Cincinnati, Cincinnati No Kentucky Intl Arpt [CVG], United States of America",
        "Cincinnati, Cincinnati Municipal Arpt [LUK], United States of America",
        "Cilacap, Tunggul Wulung Arpt [CXP], Indonesia",
        "Ciego De Avila, Maximo Gomez Arpt [AVI], Cuba",
        "Churchill, Churchill Arpt [YYQ], Canada",
        "Chumphon, Chumphon Arpt [CJM], Thailand",
        "Christmas Island, Christmas Island Arpt [XCH], Christmas Islands",
        "Christchurch, Christchurch Arpt [CHC], New Zealand",
        "Chongqing, Chongqing Arpt [CKG], China",
        "Cholet, Le Pontreau Arpt [CET], France",
        "Chittagong, Patenga Arpt [CGP], Bangladesh",
        "Chita, Chita Arpt [HTA], Russian Federation",
        "Chisinau, Chisinau Arpt [RMO], Moldova, Republic of",
        "Chisasibi, Chisasibi Arpt [YKU], Canada",
        "Chios, Chios Arpt [JKH], Greece",
        "Chinju, Sacheon Arpt [HIN], Korea, Republic of",
        "Chincoteague, Wallops Arpt [WAL], United States of America",
        "Chihuahua, Chihuahua Airport [CUU], Mexico",
        "Chico, Chico Municipal Arpt [CIC], United States of America",
        "Chiclayo, Cornel Ruiz Arpt [CIX], Peru",
        "Chichen Itza, Chichen Itza Arpt [CZA], Mexico",
        "Chicago, Pal Waukee Arpt [PWK], United States of America",
        "Chicago, Midway [MDW], United States of America",
        "Chicago, Meigs Field [CGX], United States of America",
        "Chicago, Dupage County Arpt [DPA], United States of America",
        "Chicago, Chicago Rockford Arpt [RFD], United States of America",
        "Chicago, A O Hare Intl [ORD], United States of America",
        "Chibougamau, Chibougamau Arpt [YMT], Canada",
        "Chiang Rai, Chaing Rai Arpt [CEI], Thailand",
        "Chiang Mai, Chiang Mai Intl Arpt [CNX], Thailand",
        "Cheyenne, Cheyenne Arpt [CYS], United States of America",
        "Chevery, Chevery Arpt [YHR], Canada",
        "Chetumal, Chetumal International [CTM], Mexico",
        "Chesterfield Inlet, Chesterfield Inlet Arpt [YCS], Canada",
        "Chester, Chester [CEG], United Kingdom",
        "Chernivtsi, Chernivtsi International Airport [CWC], Ukraine",
        "Cherbourg, Maupertius Arpt [CER], France",
        "Cheong Ju City, Cheongju Arpt [CJJ], Korea, Republic of",
        "Chennai, Chennai Arpt [MAA], India",
        "Chengdu Airport, Tianfu International [TFU], China",
        "Chengdu Airport, Chengdu Arpt [CTU], China",
        "Chengde, Chelinda Arpt [CEH], China",
        "Chelyabinsk, Chelyabinsk Arpt [CEK], Russian Federation",
        "Chattanooga, Chattanooga Lovell Fld [CHA], United States of America",
        "Chateauroux, Chateauroux Arpt [CHR], France",
        "Charlottetown, Charlottetown Municipal Arpt [YHG], Canada",
        "Charlottetown (PE), Charlottetown Municipal [YYG], Canada",
        "Charlottesville, Charlottesville Albemarle Arpt [CHO], United States of America",
        "Charlotte, Charlotte Douglas Intl Arpt [CLT], United States of America",
        "Charleville, Charleville Arpt [CTL], Australia",
        "Charleston (WV), Yeager Arpt [CRW], United States of America",
        "Charleston (SC), Charleston Intl Arpt [CHS], United States of America",
        "Chapelco, Chapelco Arpt [CPC], Argentina",
        "Chapeco, Chapeco Arpt [XAP], Brazil",
        "Changzhou, Changzhou Arpt [CZX], China",
        "Changuinola, Changuinola Arpt [CHX], Panama",
        "Changsha, Changsha Arpt [CSX], China",
        "Changde, Changde Arpt [CGD], China",
        "Changchun, Changchun Arpt [CGQ], China",
        "Chandler, Williams Airforce Base [CHD], United States of America",
        "Chandigarh, Chandigarh Arpt [IXC], India",
        "Chanaral, Chanaral Airport [CNR], Chile",
        "Champaign, Univ Of Illinois Willard Arpt [CMI], United States of America",
        "Chambery, Chambery Aix Les Bains Arpt [CMF], France",
        "Chadron, Chadron Arpt [CDR], United States of America",
        "Cessnock, Cessnock Arpt [CES], Australia",
        "Centralia, Centralia Municipal Arpt [ENL], United States of America",
        "Ceduna, Ceduna Arpt [CED], Australia",
        "Cedar, Cedar Rapids Municipal Arpt [CID], United States of America",
        "Cedar City, Cedar City Municipal [CDC], United States of America",
        "Cebu-San Fernando, San Fernando Arpt [SFE], Philippines",
        "Cebu City, Cebu Intl [CEB], Philippines",
        "Cayo Largo Del Sur, Cayo Largo Del Sur Arpt [CYO], Cuba",
        "Cayo Coco, Cayo Coco Arpt [CCC], Cuba",
        "Cayman Brac, Gerrard Smith Arpt [CYB], Cayman Islands",
        "Cayenne, Rochambeau Airport [CAY], French Guiana",
        "Caxias Do Sul, Campo Dos Bugres Arpt [CXJ], Brazil",
        "Cauquira, Cauquira Arpt [CDD], Honduras",
        "Caticlan, Malay Arpt [MPH], Philippines",
        "Catania, Fontanarossa Arpt [CTA], Italy",
        "Catamarca, Choya Arpt [CTC], Argentina",
        "Catalina Island, Catalina Arpt [SXC], United States of America",
        "Castres, Mazamet Arpt [DCM], France",
        "Castlegar, Ralph West Arpt [YCG], Canada",
        "Casper, Natrona Cty Intl Arpt [CPR], United States of America",
        "Casino, Casino Arpt [CSI], Australia",
        "Cascavel, Cascavel Arpt [CAC], Brazil",
        "Casablanca, Mohamed V Arpt [CMN], Morocco",
        "Casablanca, Anfa Airport [CAS], Morocco",
        "Caruaru, Caruaru Arpt [CAU], Brazil",
        "Cartagena, Rafael Nunez Arpt [CTG], Colombia",
        "Carson City, Carson Arpt [CSN], United States of America",
        "Carriacou, Lauriston Arpt [CRU], Grenada",
        "Carnarvon, Carnarvon Arpt [CVQ], Australia",
        "Carlsbad, Carlsbad Airport [CNM], United States of America",
        "Carlsbad (CA), Carlsbad Arpt [CLD], United States of America",
        "Carlisle, Carlisle [CAX], United Kingdom",
        "Caribou, Caribou Municipal Arpt [CAR], United States of America",
        "Cardiff, Cardiff Wales Arpt [CWL], United Kingdom",
        "Carcassonne, Salvaza [CCF], France",
        "Carbondale, Southern Illinois Arpt [MDH], United States of America",
        "Carajas, Carajas Arpt [CKS], Brazil",
        "Caracas, Simon Bolivar Arpt [CCS], Venezuela",
        "Cape Town, Cape Town International [CPT], South Africa",
        "Cape Girardeau, Cape Girardeau Municipal Arpt [CGI], United States of America",
        "Cape Dorset, Cape Dorset Arpt [YTE], Canada",
        "Cap Haitien, Cap Haitien Numicipal [CAP], Haiti",
        "Canouan Island, Canouan Island Municipal [CIW], St. Vincent And Grenadines",
        "Cannes, Mandelieu Arpt [CEQ], France",
        "Cancun, Cancun Aeropuerto Internacional [CUN], Mexico",
        "Canberra, Canberra Arpt [CBR], Australia",
        "Can Tho, Can Tho Arpt [VCA], Vietnam",
        "Campos, Bartolomeu Lisandro Arpt [CAW], Brazil",
        "Campogrande, Internacional [CGR], Brazil",
        "Campinas, International Campinas [CPQ], Brazil",
        "Campina Grande, Joao Suassuana Arpt [CPV], Brazil",
        "Campeche, Campeche Intl Arpt [CPE], Mexico",
        "Campbelltown, Machrihanish [CAL], United Kingdom",
        "Campbell River, Harbor Airport [YHH], Canada",
        "Campbell River, Campbell River Municipal [YBL], Canada",
        "Camp Springs, Andrews Air Force Base [ADW], United States of America",
        "Camiri, Choreti Arpt [CAM], Bolivia",
        "Camden (AR), Harrell Fieldsandro Arpt [CDH], United States of America",
        "Cambridge, Cambridge [CBG], United Kingdom",
        "Camarillo, Cunnamulla Arpt [CMA], United States of America",
        "Camaguey, Ign Agramonte Intl Arpt [CMW], Cuba",
        "Cam Rahn, Cam Ranh Arpt [CXR], Vietnam",
        "Calvi, Ste Catherine Arpt [CLY], France",
        "Cali, Alfonso Bonella Aragon Arpt [CLO], Colombia",
        "Calgary, Red Deer Arpt [YQF], Canada",
        "Calgary, Calgary Intl Arpt [YYC], Canada",
        "Calexico, Calexico Intl Arpt [CXL], United States of America",
        "Cald Well, Caldwell Wright Arpt [CDW], United States of America",
        "Calama, El Loa Arpt [CJC], Chile",
        "Cak, Akron Canton Regional Arpt [CAK], United States of America",
        "Cairo, Cairo Intl Arpt [CAI], Egypt",
        "Cairns, Cairns Airport [CNS], Australia",
        "Cahors, Laberandie Arpt [ZAO], France",
        "Cagliari, Elmas Airport [CAG], Italy",
        "Cagayan De Oro, Lumbia Arpt [CGY], Philippines",
        "Caen, Carpiquet Arpt [CFR], France",
        "Cadillac, Wexford County Arpt [CAD], United States of America",
        "Cacoal, Cacoal Arpt [OAL], Brazil",
        "Cacheiro De Itapemirim, Cachoeiro De Itapemirim Arpt [CDI], Brazil",
        "Cabo Frio, Cabo Frio Arpt [CFB], Brazil",
        "Cabimas, Oro Negro Arpt [CBS], Venezuela",
        "Bydgoszcz, Bydgoszcz Arpt [BZG], Poland",
        "Buzios, Buzios Arpt [BZC], Brazil",
        "Butuan, Butuan Arpt [BXU], Philippines",
        "Butte, Bert Mooney Arpt [BTM], United States of America",
        "Busselton, Busselton Arpt [BQB], Australia",
        "Bushehr, Bushehr Arpt [BUZ], Iran",
        "Busan (Pusan), Kimhae Arpt [PUS], Korea, Republic of",
        "Bury St Edmunds, Bury St Edmunds [BEQ], United Kingdom",
        "Burtonwood, Burtonwood Airport [BUT], United Kingdom",
        "Bursa, Yenisehir Arpt [YEI], Turkey",
        "Bursa, Bursa Arpt [BTZ], Turkey",
        "Burnie Wynward, Burnie Wynyard Arpt [BWT], Australia",
        "Burlington, Burlington Municipal Arpt [BRL], United States of America",
        "Burlington (VT), Burlington Intl Arpt [BTV], United States of America",
        "Burley, Burley Arpt [BYI], United States of America",
        "Burketown, Burketown Arpt [BUC], Australia",
        "Buri Ram, Buri Ram Arpt [BFV], Thailand",
        "Burgas, Bourgas Arpt [BOJ], Bulgaria",
        "Burbank, Burbank Glendale Pasadena Arpt [BUR], United States of America",
        "Bundaberg, Bundaberg Arpt [BDB], Australia",
        "Bunbury, Bunbury Arpt [BUY], Australia",
        "Bumi Hills, Bumi Hills Arpt [BZH], Zimbabwe",
        "Bullhead City, Laughlin Bullhead Intl Arpt [IFP], United States of America",
        "Bullfrog Basin, Bullfrog Basin Arpt [BFG], United States of America",
        "Bulawayo, Bulawayo Arpt [BUQ], Zimbabwe",
        "Bukhara, Bukhara Arpt [BHK], Uzbekistan",
        "Bujumbura, Bujumbura Intl Arpt [BJM], Burundi",
        "Buffalo (NY), Greater Buffalo Intl Arpt [BUF], United States of America",
        "Buenos Aires, Ministro Pistarini [EZE], Argentina",
        "Buenos Aires, Jorge Newbery [AEP], Argentina",
        "Budapest, Ferihegy Arpt [BUD], Hungary",
        "Bucharest, Otopeni International [OTP], Romania",
        "Bucharest, Baneasa Airport [BBU], Romania",
        "Bucaramanga, Palo Negro Arpt [BGA], Colombia",
        "Bryce Canyon, Bryce Arpt [BCE], United States of America",
        "Brussels, Brussels South Charleroi Arpt [CRL], Belgium",
        "Brussels, Brussels National Arpt [BRU], Belgium",
        "Brussels, Brussels Midi Rail Station [ZYR], Belgium",
        "Brunswick, Gylnco Jet Port [BQK], United States of America",
        "Brunswick (ME), Naval Air Station [NHZ], United States of America",
        "Brunswick (GA), McKinnon Arpt [SSI], United States of America",
        "Brownwood, Brownwood Municipal Arpt [BWD], United States of America",
        "Brownsville, South Padre Island Intl Arpt [BRO], United States of America",
        "Broomfield, Jeffco Arpt [BJC], United States of America",
        "Broome, Broome Airport [BME], Australia",
        "Brookings (SD), Brookings Municipal [BKX], United States of America",
        "Bronnoysund, Bronnoy Arpt [BNN], Norway",
        "Bromont, Bromont Regional Arpt [ZBM], Canada",
        "Broken Hill, Broken Hill Arpt [BHQ], Australia",
        "Broken Bow, Broken Bow Municipal [BBW], United States of America",
        "Brno, Turnay Arpt [BRQ], Czech Republic",
        "Brive la Gallarde, Laroche Airport [BVE], France",
        "Bristol, Tri City Regional Arpt [TRI], United States of America",
        "Bristol, Temple Meads Rail Station [TPB], United Kingdom",
        "Bristol, Parkway Railway Station [BPR], United Kingdom",
        "Bristol, Bristol Intl Arpt [BRS], United Kingdom",
        "Brisbane, Brisbane Arpt [BNE], Australia",
        "Brindisi, Papola Casale Arpt [BDS], Italy",
        "Brighton, Brighton Airport [BSH], United Kingdom",
        "Bridgeport, Sikorsky Memorial Arpt [BDR], United States of America",
        "Briansk, Briansk Airport [BZK], Russian Federation",
        "Brewarrina, Brewarrina Arpt [BWQ], Australia",
        "Brevig Mission, Brevig Mission Arpt [KTS], United States of America",
        "Brest, Guipavas Arpt [BES], France",
        "Brest, Brest Arpt [BQT], Belarus",
        "Bremerton, Bremerton Municipal [PWT], United States of America",
        "Bremerhaven, Bremerhaven Arpt [BRV], Germany",
        "Bremen, Bremen Airport [BRE], Germany",
        "Bremen,  Bremen Hbf [DHC], Germany",
        "Brazzaville, Maya Maya Arpt [BZV], Congo",
        "Brazoria, Brazoria County Arpt [BZT], United States of America",
        "Brawley, Brawley Arpt [BWC], United States of America",
        "Brava, Esperadinha [BVR], Cape Verde Islands",
        "Braunschweig, Braunschweig Arpt [BWE], Germany",
        "Bratislava, Ivanka Arpt [BTS], Slovakia",
        "Brasilia, Brasilia Intl Arpt [BSB], Brazil",
        "Branson Point Lookout, M Graham Clark Arpt [PLK], United States of America",
        "Brandon, Brandon Municipal Arpt [YBR], Canada",
        "Brampton Island, Brampton Island Arpt [BMP], Australia",
        "Brainerd, Crowwing Cty Arpt [BRD], United States of America",
        "Bradford, Bus Station [ZFC], United Kingdom",
        "Bradford, Bradford [BRF], United Kingdom",
        "Bradford, Bradford Regional Arpt [BFD], United States of America",
        "Bozeman, Gallatin Field [BZN], United States of America",
        "Bowling Green (KY), Warren Cty Arpt [BWG], United States of America",
        "Bournemouth, Bournemouth International Airport [BOH], United Kingdom",
        "Bourke, Bourke Airport [BRK], Australia",
        "Bourges, Bourges Arpt [BOU], France",
        "Boulia, Boulia Arpt [BQL], Australia",
        "Boston, Logan Intl Arpt [BOS], United States of America",
        "Bosaso, Bosaso International Airport [BSA], Somalia",
        "Borroloola, Borroloola Arpt [BOX], Australia",
        "Borrego Springs, Borrego Valley Arpt [BXS], United States of America",
        "Bornholm, Bornholm Arpt [RNN], Denmark",
        "Borlange, Dala Airport [BLE], Sweden",
        "Borg El Arab, Borg El Arab Arpt [HBE], Egypt",
        "Bordeaux, Bordeaux Arpt [BOD], France",
        "Bora Bora Island, Motu Mute Arpt [BOB], French Polynesia",
        "Bonaventure, Bonaventure Municipal Arpt [YVB], Canada",
        "Bonaire, Flamingo Field [BON], Netherlands Antilles",
        "Bolzano, Bolzano Arpt [BZO], Italy",
        "Bologna, Guglielmo Marconi [BLQ], Italy",
        "Boise, Boise Municipal Arpt Gowen Field [BOI], United States of America",
        "Boigu Island, Boigu Island Arpt [GIC], Australia",
        "Bogota, Eldorado Airport [BOG], Colombia",
        "Bodrum, Milas Arpt [BJV], Turkey",
        "Bodrum, Imsik Arpt [BXN], Turkey",
        "Bodo, Bodo Arpt [BOO], Norway",
        "Bocas Del Toro, Bocas Del Toro Arpt [BOC], Panama",
        "Bobo Dioulasso, Borgo Arpt [BOY], Burkina Faso",
        "Boa Vista, Rabil Arpt [BVC], Cape Verde Islands",
        "Boa Vista, Boa Vista Arpt [BVB], Brazil",
        "Blytheville, Blytheville Municipal Arpt [HKA], United States of America",
        "Blythe, Blythe Airport [BLH], United States of America",
        "Blumenau, Blumenau Arpt [BNU], Brazil",
        "Bluefield, Mercer County [BLF], United States of America",
        "Bloomington, Normal Airport [BMI], United States of America",
        "Bloomington, Monroe Cty Arpt Indiana [BMG], United States of America",
        "Bloemfontein, Bloemfontein International [BFN], South Africa",
        "Block Island, Block Island Municipal [BID], United States of America",
        "Blenheim, Woodbourne Arpt [BHE], New Zealand",
        "Blantyre, Chileka Airport [BLZ], Malawi",
        "Blakely Island, Blakely Is Municipal [BYW], United States of America",
        "Blairsville, Blairsville Arpt [BSI], United States of America",
        "Blagoveshchensk, Blagoveshchensk Arpt [BQS], Russian Federation",
        "Blackwater, Blackwater Arpt [BLT], Australia",
        "Blacksburg, Virginia Tech Arpt [BCB], United States of America",
        "Blackpool, Blackpool Airport [BLK], United Kingdom",
        "Blackkall, Blackall Arpt [BKQ], Australia",
        "Bissau, Airport Osvaldo Viera [OXB], Guinea Bissau",
        "Bismarck, Bismarck Municipal Arpt [BIS], United States of America",
        "Biskra, Biskra Arpt [BSK], Algeria",
        "Bishop, Bishop Airport [BIH], United States of America",
        "Bisho, Bisho Arpt [BIY], South Africa",
        "Bishkek, Bishkek Arpt [FRU], Kyrgyzstan",
        "Bisbee, Bisbee Municipal Arpt [BSQ], United States of America",
        "Birmingham, Colmore Row Bus Stn [ZBC], United Kingdom",
        "Birmingham, Birmingham New Street [QQN], United Kingdom",
        "Birmingham, Birmingham Intl Arpt [BHX], United Kingdom",
        "Birmingham Alabama , Birmingham Alabama [BHM], United States of America",
        "Birdsville, Birdsville Arpt [BVI], Australia",
        "Bintulu, Bintulu Arpt [BTU], Malaysia",
        "Binghamton, Edwin A Link Field [BGM], United States of America",
        "Bimini, North Seaplane Base [NSB], Bahamas",
        "Bimini, Bimini Airport [BIM], Bahamas",
        "Biloxi-Gulfport, Biloxi Regional Arpt [GPT], United States of America",
        "Biloela, Biloela Arpt [ZBL], Australia",
        "Billund, Billund Airport [BLL], Denmark",
        "Billings, Logan Field [BIL], United States of America",
        "Bilbao, Bilbao Arpt [BIO], Spain",
        "Bilaspur, Bilaspur Airport [PAB], India",
        "Bikin Atoll, Enyu Airfield [BII], Marshall Islands",
        "Bikaner, Bikaner Airport [BKB], India",
        "Biggin Hill, London Biggin Hill Airport [BQH], United Kingdom",
        "Big Bear, Big Bear City Arpt [RBF], United States of America",
        "Bielefeld, Bielefeld Arpt [BFE], Germany",
        "Biarritz, Bayonne Anglet Arpt [BIQ], France",
        "Biak, Mokmer Arpt [BIK], Indonesia",
        "Bhuj, Rudra Mata Arpt [BHJ], India",
        "Bhubaneswar, Bhubaneswar Arpt [BBI], India",
        "Bhopal, Bhopal Arpt [BHO], India",
        "Bhavnagar, Bhavnagar Airport [BHU], India",
        "Beziers, Beziers Vias Arpt [BZR], France",
        "Bethel, Bethel Airport [BET], United States of America",
        "Beru, Beru Arpt [BEZ], Kiribati",
        "Berne, Belp Airport [BRN], Switzerland",
        "Bermuda-Hamilton, Bermuda International [BDA], Bermuda",
        "Berlin-Tegel, Tegel Airport [TXL], Germany",
        "Berlin, Tempelhof Arpt [THF], Germany",
        "Berlin, Schoenefeld Arpt [SXF], Germany",
        "Berlin, Berlin Brandenburg Airport [BER], Germany",
        "Bergerac, Roumaniere Arpt [EGC], France",
        "Bergen, Flesland Airport [BGO], Norway",
        "Bergamo, Orio Al Serio Arpt [BGY], Italy",
        "Bento Goncalves, Bento Goncalves Arpt [BGV], Brazil",
        "Benin City, Benin City Airport [BNI], Nigeria",
        "Bengkulu, Padangkemiling Arpt [BKS], Indonesia",
        "Benghazi, Benina Intl Arpt [BEN], Libyan Arab Jamahiriya",
        "Bengaluru, Bengaluru International airport [BLR], India",
        "Benbecula, Benbecula Airport [BEB], United Kingdom",
        "Bemidji, Bemidji Municipal Arpt [BJI], United States of America",
        "Bembridge, Bembridge [BBP], United Kingdom",
        "Belo Horizonte, Tancredo Neves Intl Arpt [CNF], Brazil",
        "Belo Horizonte, Pampulha Arpt [PLU], Brazil",
        "Belluno, Belluno Arpt [BLX], Italy",
        "Bellingham, Bellingham Intl Arpt [BLI], United States of America",
        "Belleville, Scott AFB Mid America [BLV], United States of America",
        "Bella Coola, Bella Colla Municipal [QBC], Canada",
        "Belize City, Belize Intl Arpt [BZE], Belize",
        "Belize City, Belize City Municipal [TZA], Belize",
        "Belgrade, Belgrade Beograd Arpt [BEG], Serbia and Montenegro",
        "Belgrade, Batajnica Arpt [BJY], Serbia and Montenegro",
        "Belgorod, Belgorod Arpt [EGO], Russian Federation",
        "Belgaum, Sambre Airport [IXG], India",
        "Belfast, George Best City Apt [BFS], United Kingdom",
        "Belfast, George Best Belfast City Airport [BHD], United Kingdom",
        "Belem, Val De Cans Arpt [BEL], Brazil",
        "Bejaia, Bejaia Arpt [BJA], Algeria",
        "Beirut, Beirut Intl Arpt [BEY], Lebanon",
        "Beira, Beira Arpt [BEW], Mozambique",
        "Beijing, Nanyuan Arpt [NAY], China",
        "Beijing, Beijing Daxing International Airport [PKX], China",
        "Beijing, Beijing Capital Arpt [PEK], China",
        "Beihai, Beihai Airport [BHY], China",
        "Beef Island, Beef Island Arpt [EIS], Virgin Islands (British)",
        "Bedourie, Bedourie Arpt [BEU], Australia",
        "Bedford (MA), Bedford Arpt [BED], United States of America",
        "Bedford (IN), Virgil I Grissom Municipal [BFR], United States of America",
        "Beckley, Raleigh Cty Memorial [BKW], United States of America",
        "Beaver Falls, Beaver Falls Arpt [BFP], United States of America",
        "Beaumont, Jefferson Cty Arpt [BPT], United States of America",
        "Beaufort, Beaufort County Arpt [BFT], United States of America",
        "Bayreuth, Bindlacher Berg Arpt [BYU], Germany",
        "Bauru, Bauru Arpt [BAU], Brazil",
        "Baucau, English Madeira Arpt [BCH], Timor-Leste",
        "Batumi, Batumi Arpt [BUS], Georgia",
        "Battle Creek, WK Kellogg Regional [BTL], United States of America",
        "Batsfjord, Batsfjord Arpt [BJF], Norway",
        "Baton Rouge, Ryan Airport [BTR], United States of America",
        "Baton Rouge, Baton Rouge Downtown Arpt [EBR], United States of America",
        "Bathurst, Raglan Airport [BHS], Australia",
        "Bathurst, Bathurst Arpt [ZBF], Canada",
        "Bathurst Isl, Bathurst Island Arpt [BRT], Australia",
        "Batesville, Hillenbrand [HLB], United States of America",
        "Batesville (AR), Batesville Municipal [BVX], United States of America",
        "Batam Island, Hang Nadim Arpt [BTH], Indonesia",
        "Bastia, Poretta Airport [BIA], France",
        "Basseterre, Robert L Bradshaw Arpt [SKB], St.Kitts And Nevis",
        "Basse Terre, Baillif Arpt [BBR], Guadeloupe",
        "Basra, Basra Intl Arpt [BSR], Iraq",
        "Basel, Basel EuroAirport Swiss [BSL], Switzerland",
        "Bartow, Bartow Arpt [BOW], United States of America",
        "Bartlesville, Bartlesville Municipal Arpt [BVO], United States of America",
        "Barth, Barth Arpt [BBH], Germany",
        "Barrow, Barrow WBAS [BRW], United States of America",
        "Barrow In Furness, Walney Island [BWF], United Kingdom",
        "Barretos, Barretos Arpt [BAT], Brazil",
        "Barreiras, Barreiras Arpt [BRA], Brazil",
        "Barranquilla, E Cortissoz Arpt [BAQ], Colombia",
        "Barrancabermeja, Variguies Arpt [EJA], Colombia",
        "Barra, Barra Airport [BRR], United Kingdom",
        "Barquisimeto, Airport Barquisimeto [BRM], Venezuela",
        "Bari, Bari Airport [BRI], Italy",
        "Bardufoss, Bardufoss Arpt [BDU], Norway",
        "Barcelona-Sabadell, Juancho Yraus Quinl [SAB], Spain",
        "Barcelona, Gen J A Anzoategui Arpt [BLA], Venezuela",
        "Barcelona, Barcelona Arpt [BCN], Spain",
        "Barcaldine, Barcaldine Arpt [BCI], Australia",
        "Barbados, Grantley Adams Intl Arpt [BGI], Barbados",
        "Barahona, Barahona Arpt [BRX], Dominican Republic",
        "Bar Harbor, Hancock County [BHB], United States of America",
        "Baotou, Baotou Arpt [BAV], China",
        "Baoshan, Baoshan Arpt [BSD], China",
        "Banmethuot, Phung Duc Arpt [BMV], Vietnam",
        "Banjul, Yundum Intl Arpt [BJL], The Gambia",
        "Banjarmasin, Sjamsudin Noor Arpt [BDJ], Indonesia",
        "Banja Luka, Banja Luka Arpt [BNX], Bosnia Hercegovina",
        "Bangui, Bangui Airport [BGF], Central African Republic",
        "Bangor, Bangor Intl Arpt [BGR], United States of America",
        "Bangkok, Don Mueang International [DMK], Thailand",
        "Bangkok, Bangkok Intl Arpt [BKK], Thailand",
        "Banff, Banff Arpt [YBA], Canada",
        "Bandung, Husein Sastranegara Arpt [BDO], Indonesia",
        "Bandar Seri Begawan, Brunei Intl Arpt [BWN], Brunei",
        "Bandar Lampung, Bandar Lampung Arpt [TKG], Indonesia",
        "Banda Aceh, Blang Bintang Arpt [BTJ], Indonesia",
        "Bamako, Senou Airport [BKO], Mali",
        "Bamaga, Bamaga Arpt [ABM], Australia",
        "Baltimore, Baltimore Washington Intl Arpt [BWI], United States of America",
        "Balmaceda, Teniente Vidal Airport [BBA], Chile",
        "Bally Kelly, Bally Kelly Airport [BOL], United Kingdom",
        "Ballina, Ballina Arpt [BNK], Australia",
        "Balikpapan, Sepingan Arpt [BPN], Indonesia",
        "Balikesir, Merkez Arpt [MQJ], Turkey",
        "Bali, Ngurah Rai Arpt [DPS], Indonesia",
        "Balalae, Balalae Arpt [BAS], Solomon Islands",
        "Baku, Zabrat Arpt [ZXT], Azerbaijan",
        "Baku, Heydar Aliyev Intl Arpt [GYD], Azerbaijan",
        "Bakersfield, Meadows Field [BFL], United States of America",
        "Bairnsdale, Bairnsdale Airport [BSJ], Australia",
        "Baie Comeau, Baie Comeau Arpt [YBC], Canada",
        "Baia Mare, Baia Mare Arpt [BAY], Romania",
        "Bahrain, Bahrain Intl Arpt [BAH], Bahrain",
        "Bahia De Los Angeles, Bahia De Los Angeles Arpt [BHL], Mexico",
        "Bahia Blanca, Commandante Airport [BHI], Argentina",
        "BahawalPur, BahawalPur Arpt [BHV], Pakistan",
        "Bahar Dar, Bahar Dar Arpt [BJR], Ethiopia",
        "Baguio City, Loakan Arpt [BAG], Philippines",
        "Bagotville, Bagotville Arpt [YBG], Canada",
        "Baghdad, Al Muthana Arpt [BGW], Iraq",
        "Bagdogra, Bagdogra Arpt [IXB], India",
        "Badu Island, Badu Island Arpt [BDD], Australia",
        "Badajoz, Talaveral La Real Arpt [BJZ], Spain",
        "Bacolod, Bacolod Arpt [BCD], Philippines",
        "Bacau, Bacau Arpt [BCM], Romania",
        "Ba City, Bijie Feixiong [BFJ], Fiji",
        "Ayr, Ayr Arpt [AYR], United Kingdom",
        "Ayodhya, Maharishi Valmiki International Airport Ayodhya Dham [AYJ], India",
        "Ayers Rock, Connellan Arpt [AYQ], Australia",
        "Ayacucho, Yanamilla Arpt [AYP], Peru",
        "Avignon, Avignon TGV [XZN], France",
        "Avignon, Avignon Caumont Arpt [AVN], France",
        "Austin, Bergstrom Intl Arpt [AUS], United States of America",
        "Aurukun Mission, Aurukin Arpt [AUU], Australia",
        "Auroroa (CO), Auburn Opelika [AUO], United States of America",
        "Aurora, Aurora Municipal Arpt [AUZ], United States of America",
        "Aurillac, Tronquieres Arpt [AUR], France",
        "Aurangabad, Chikkalthana Arpt [IXU], India",
        "Aupaluk, Aupaluk Arpt [YPJ], Canada",
        "Augusta, Maine State Arpt [AUG], United States of America",
        "Augusta, Daniel Airport [DNL], United States of America",
        "Augusta, Bush Field [AGS], United States of America",
        "Augsburg, Muehlhausen Arpt [AGB], Germany",
        "Auckland, Auckland Arpt [AKL], New Zealand",
        "Atyrau, Atyrau Arpt [GUW], Kazakhstan",
        "Atuona, Atuona Arpt [AUQ], French Polynesia",
        "Atlanta, Hartsfield Jackson Intl Arpt [ATL], United States of America",
        "Atlanta, Fulton Cty Arpt [FTY], United States of America",
        "Atlanta, Dekalb Peachtree [PDK], United States of America",
        "Atikokan, Atikokan Municipal Arpt [YIB], Canada",
        "Atibaia, Atibaia Arpt [ZBW], Brazil",
        "Athens, Ohio University Arpt [ATO], United States of America",
        "Athens, Eleftherios Venizelos Intl Arpt [ATH], Greece",
        "Athens, Athens Municipal [AHN], United States of America",
        "Athens, Athens Hellinikon Arpt [HEW], Greece",
        "Atar, Mouakchott Arpt [ATR], Mauritania",
        "Aswan, Daraw Arpt [ASW], Egypt",
        "Asuncion, Salvio Pettirosse Arpt [ASU], Paraguay",
        "Astypalaia Island, Astypalaia Arpt [JTY], Greece",
        "Astrakhan, Astrakhan Arpt [ASF], Russian Federation",
        "Astoria, Astoria Arpt [AST], United States of America",
        "Astana, Astana Arpt [TSE], Kazakhstan",
        "Aspen, Pitkin Cty Arptt Sardy Fld [ASE], United States of America",
        "Asmara, Asmara Intl Arpt [ASM], Eritrea",
        "Ashgabat, Ashgabat Arpt [ASB], Turkmenistan",
        "Asheville, Asheville Municipal Arpt [AVL], United States of America",
        "Ascona, Ascona Arpt [ACO], Switzerland",
        "Asahikawa, Asahikawa Arpt [AKJ], Japan",
        "Asaba, Asaba Arpt [ABB], Nigeria",
        "Arvidsjaur, Arvidsjaur Arpt [AJR], Sweden",
        "Arviat, Arviat Arpt [YEK], Canada",
        "Arusha, Arusha Arpt [ARK], Tanzania",
        "Artigas, Artigas Arpt [ATI], Uruguay",
        "Arthurs Town, Arthurs Town Arpt [ATC], Bahamas",
        "Arrecife, Lanzarote Airport [ACE], Spain",
        "Arras, Arras Arpt [QRV], France",
        "Armidale, Armidale Arpt [ARM], Australia",
        "Arkhangelsk, Arkhangelsk Arpt [ARH], Russian Federation",
        "Arica, Chacalluta Arpt [ARI], Chile",
        "Arequipa, Rodriguez Ballon Arpt [AQP], Peru",
        "Ardmore, Ardmore Municipal Arpt [ADM], United States of America",
        "Ardabil, Ardabil Arpt [ADU], Iran",
        "Arcata/Eureka, Arcata Eureka Arpt [ACV], United States of America",
        "Araraquara, Araraquara Arpt [AQA], Brazil",
        "Arapoti, Arapoti Arpt [AAG], Brazil",
        "Araguaina, Araguaina Arpt [AUX], Brazil",
        "Arad, Arad Arpt [ARW], Romania",
        "Aracatuba, Aracatuba Arpt [ARU], Brazil",
        "Aracaju, Santa Maria Arpt [AJU], Brazil",
        "Aqtobe, Aqtobe Arpt [AKX], Kazakhstan",
        "Aqaba, Aqaba Airport [AQJ], Jordan",
        "Appleton, Outagamie Cty Arpt [ATW], United States of America",
        "Apatzingan, Apatzingan Arpt [AZG], Mexico",
        "Apartado, Apartado Arpt [APO], Colombia",
        "Apalachicola, Apalachicola Municipal Arpt [AAF], United States of America",
        "Aosta, Corrado Gex Arpt [AOT], Italy",
        "Aomori, Aomori Arpt [AOJ], Japan",
        "Antwerp, Deurne Airport [ANR], Belgium",
        "Antwerp, Antwerp Central Train Station [ZWE], Belgium",
        "Antofagasta, Cerro Moreno Arpt [ANF], Chile",
        "Antigua, V C Bird Intl Arpt [ANU], Antigua and Barbuda",
        "Antalya, Antalya Airport [AYT], Turkey",
        "Anniston, Anniston Municipal Arpt [ANB], United States of America",
        "Annecy, Annecy Meythet Arpt [NCY], France",
        "Annapolis, Lee Annapolis Arpt [ANP], United States of America",
        "Annaba, Les Salines Arpt [AAE], Algeria",
        "Ann Arbor, Ann Arbor Municipal [ARB], United States of America",
        "Ankara, Etimesgut Arpt [ANK], Turkey",
        "Ankara, Esenboga Arpt [ESB], Turkey",
        "Aniak, Aniak Arpt [ANI], United States of America",
        "Anguilla, Wallblake Municipal [AXA], Anguilla",
        "Angouleme, Brie Champniers [ANG], France",
        "Angers, Marce Arpt [ANE], France",
        "Angelholm, Angelholm Helsingborg Arpt [AGH], Sweden",
        "Angeles City, Clark International Arpt [CRK], Philippines",
        "Andover, Aeroflex–Andover Airport [ADV], United Kingdom",
        "Andors Town, Andros Town Arpt [ASD], Bahamas",
        "Anderson, Anderson Municipal Arpt [AID], United States of America",
        "Anderson, Anderson Arpt [AND], United States of America",
        "Andenes, Andenes Arpt [ANX], Norway",
        "Ancortes, Ancortes Arpt [OTS], United States of America",
        "Ancona, Falconara Arpt [AOI], Italy",
        "Anchorage, Anchorage Intl Arpt [ANC], United States of America",
        "Anapolis, Anapolis Arpt [APS], Brazil",
        "Anapa, Anapa Arpt [AAQ], Russian Federation",
        "Anadyr, Anadyr Arpt [DYR], Russian Federation",
        "Amsterdam, Schiphol Arpt [AMS], Netherlands",
        "Amritsar, Raja Sansi Arpt [ATQ], India",
        "Ampara, Kondavattavan Tank Arpt [AFK], Sri Lanka",
        "Amman, Queen Alia Intl Arpt [AMM], Jordan",
        "Amman, Civil Marka Arpt [ADJ], Jordan",
        "Ames, Ames Minicipal Arpt [AMW], United States of America",
        "Ambon, Pattimura Arpt [AMQ], Indonesia",
        "Ambler, Ambler Arpt [ABL], United States of America",
        "Amarillo, Tradewind Airport [TDW], United States of America",
        "Amarillo, Amarillo Intl Arpt [AMA], United States of America",
        "Amami O Shima, Amami O Shima Arpt [ASJ], Japan",
        "Amahai, Amahai Airport [AHI], Indonesia",
        "Altus, Altus Municipal Arpt [AXS], United States of America",
        "Altoona, Blair Cty Arpt [AOO], United States of America",
        "Altenrhein, Altenrhein Arpt [ACH], Switzerland",
        "Altenburg, Altenburg Nobitz Arpt [AOC], Germany",
        "Altamira, Altamira Arpt [ATM], Brazil",
        "Alta, Elvebakken Arpt [ALF], Norway",
        "Alta Floresta, Alta Floresta Arpt [AFL], Brazil",
        "Alpine (TX), Alpine Aprt [ALE], United States of America",
        "Alpena, Phelps Collins Arpt [APN], United States of America",
        "Alotau, Gurney Arpt [GUR], Papua New Guinea",
        "Alor Setar, Sultan Abdul Hamlin Arpt [AOR], Malaysia",
        "Almeria, Almeria Arpt [LEI], Spain",
        "Almaty, Almaty Arpt [ALA], Kazakhstan",
        "Alma, Alma Municipal Arpt [YTF], Canada",
        "Alluitsup Paa, Alluitsup Paa Arpt [LLU], Greenland",
        "Alliance (NE), Alliance Municipal [AIA], United States of America",
        "Allentown, Lehigh Valley Intl Arpt [ABE], United States of America",
        "Allahabad, Bamrauli Arpt [IXD], India",
        "Alice Springs, Alice Springs Arpt [ASP], Australia",
        "Alicante, Alicante Arpt [ALC], Spain",
        "Algiers, Houari Boumedienne Arpt [ALG], Algeria",
        "Alghero (Sardinia), Fertilia Arpt [AHO], Italy",
        "Algeciras, Algeciras Arpt [AEI], Spain",
        "Alexandroupolis, Dhmokritos Arpt [AXD], Greece",
        "Alexandria, El Nouzha Arpt [ALY], Egypt",
        "Alexandria, Alexandria Airport [AXN], United States of America",
        "Alexandria (LA), Esler Field [ESF], United States of America",
        "Alexandria (LA), Alexandria Intl Arpt [AEX], United States of America",
        "Alexander Bay, Kortdoorn Arpt [ALJ], South Africa",
        "Aleppo, Nejrab Arpt [ALP], Syrian Arab Republic",
        "Alderney, Alderney [ACI], United Kingdom",
        "Albury, Albury Airport [ABX], Australia",
        "Albuquerque, Albuquerque Intl Arpt [ABQ], United States of America",
        "Albi, Le Sequestre Arpt [LBI], France",
        "Albert Lea, Albert Lea Arpt [AEL], United States of America",
        "Albenga, Albenga Arpt [ALL], Italy",
        "Albany, Dougherty Cty Arpt [ABY], United States of America",
        "Albany, Albany Airport [ALH], Australia",
        "Albany (NY), Albany Cty Arpt [ALB], United States of America",
        "Albacete, Los Llanos Arpt [ABC], Spain",
        "Alamosa, Alamosa Municipal [ALS], United States of America",
        "Alamogordo, Alamogordo Municipal [ALM], United States of America",
        "Al Najaf, Al Najaf Intl Arpt [NJF], Iraq",
        "Al Hofuf, Alahsa Arpt [HOF], Saudi Arabia",
        "Al Hoceima, Charif Al Idrissi Arpt [AHU], Morocco",
        "Al Ghaydah, Al Ghaydah Arpt [AAY], Yemen",
        "Al Fujairah, Fujairah Intl Arpt [FJR], United Arab Emirates",
        "Al Baha, Al Aqiq [ABT], Saudi Arabia",
        "Al Ain, Al Ain Arpt [AAN], United Arab Emirates",
        "Akureyri, Akureyri [AEY], Iceland",
        "Akulivik, Akulivik Arpt [AKV], Canada",
        "Aktau, Aktau Arpt [SCO], Kazakhstan",
        "Akita, Akita Airport [AXT], Japan",
        "Ajaccio, Campo Dell Oro Arpt [AJA], France",
        "Aizawl, Lengpui Airport [AJL], India",
        "Airlie Beach, Whitsunday Airstrip [WSY], Australia",
        "Ainsworth, Ainsworth Minicipal Arpt [ANW], United States of America",
        "Aiken, Aiken Municipal Arpt [AIK], United States of America",
        "Ahmedabad, Ahmedabad Arpt [AMD], India",
        "Aguascalientes, Aguascalientes Municipal Arpt [AGU], Mexico",
        "Aguadilla, Borinquen Municipal Arpt [BQN], United States of America",
        "Agra, Kheria Arpt [AGR], India",
        "Agen, La Garenne Arpt [AGF], France",
        "Agartala, Maharaja Bir Bikram Airport [IXA], India",
        "Agadir, Agadir Almassira Arpt [AGA], Morocco",
        "Adrian, Lenawee County Arpt [ADG], United States of America",
        "Adler Sochi, Alder Sochi Arpt [AER], Russian Federation",
        "Adiyaman, Adiyaman Airport [ADF], Turkey",
        "Aden, Yemen Intl Arpt [ADE], Yemen",
        "Adelaide, Adelaide Arpt [ADL], Australia",
        "Addis Ababa, Bole Airport [ADD], Ethiopia",
        "Adana, Adana Airport [ADA], Turkey",
        "Adak Island, Adak Island Ns [ADK], United States of America",
        "Ada, Ada Municipal Arpt [ADT], United States of America",
        "Accra, Kotoka Airport [ACC], Ghana",
        "Acapulco, Alvarez Intl Arpt [ACA], Mexico",
        "Abuja, Abuja Intl [ABV], Nigeria",
        "Abu Simbel, Abu Simbel Arpt [ABS], Egypt",
        "Abu Dhabi, Zayed International Airport [AUH], United Arab Emirates",
        "Abu Dhabi, Abu Dhabi Bus Station [ZVJ], United Arab Emirates",
        "Abingdon, Virginia Highlands Arpt [VJI], United States of America",
        "Abilene, Abilene Municipal Arpt [ABI], United States of America",
        "Abidjan, Felix Houphouet Boigny Arpt [ABJ], Cote D'Ivoire",
        "Aberdeen, Aberdeen Airport [ABZ], United Kingdom",
        "Aberdeen (SD), Aberdeen Arpt [ABR], United States of America",
        "Abbotsford, Abbotsford Arpt [YXX], Canada",
        "Abakan, Abakan Arpt [ABA], Russian Federation",
        "Abadan, Abadan Arpt [ABD], Iran",
        "Aasiaat, Aasiaat Arpt [JEG], Greenland",
        "Aarhus, Tirstrup Arpt [AAR], Denmark",
        "Aalesund, Vigra Airport [AES], Norway",
        "Aalborg, Aalborg Arpt [AAL], Denmark",
        "Aachen, Aachen Merzbruck Arpt [AAH], Germany"
    ];

    const filteredAirports = airports.filter(airport => airport.toLowerCase().includes(query.toLowerCase())).slice(0, 5);

    return (
      <ul className="airport-suggestions">
        {filteredAirports.map((airport, index) => (
          <li key={index} onClick={() => onSelect(airport)}>
            {airport}
          </li>
        ))}
      </ul>
    );
  };
