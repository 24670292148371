import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

const TransactionSuccessful = () => {
    //{ transaction } in parameter
    const transaction={
        id:12,
        amount:2000,
        date:"11/11/1111"
    }
  return (
    <Container className="mt-5 mb-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="text-center">
            <Card.Body>
              <FaCheckCircle size={50} className="text-success mb-3" />
              <Card.Title>Transaction Successful!</Card.Title>
              <Card.Text>
                Your transaction has been processed successfully.
                <br />
                <strong>Transaction ID:</strong> {transaction.id}
                <br />
                <strong>Amount:</strong> {transaction.amount}
                <br />
                <strong>Date:</strong> {transaction.date}
              </Card.Text>
              <Button className='custom-color' href="#">
                Go to Dashboard
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionSuccessful;
