import React from 'react';
import { Container, Row, Col, Form, Button, Card,ProgressBar,Badge,ListGroup  } from 'react-bootstrap';
import { FaPlaneDeparture, FaCreditCard, FaTicketAlt  } from 'react-icons/fa';

const BookingForm = () => {
  return (
    <>
     <Container  className="mt-4 sm:mt-4 md:mt-8 lg:mt-8 xl:mt-8 xxl:mt-8">
      <Card className="border">
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Row className="d-flex justify-content-between align-items-center">
                <Col xs={4} className="text-center">
                  <div>
                    <FaPlaneDeparture size={32} color="#3B76B0" />
                    <div>Booking</div>
                  </div>
                </Col>
                <Col xs={4} className="text-center">
                  <div>
                    <FaCreditCard size={32} color="#6c757d" />
                    <div>Payment</div>
                  </div>
                </Col>
                <Col xs={4} className="text-center">
                  <div>
                    <FaTicketAlt size={32} color="#6c757d" />
                    <div>E-ticket</div>
                  </div>
                </Col>
              </Row>
              <ProgressBar className="mt-3">
                <ProgressBar now={33} className="bg-info" />
                <ProgressBar now={0} className="bg-light" />
                <ProgressBar now={0} className="bg-light" />
              </ProgressBar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>

    <Container className="mt-5 mb-5 d-lg-flex justify-content-between">
    <div className="details col-lg-7 col-sm-12">
      <Card>
        <Card.Body>
          <Card.Title>Contact Details</Card.Title>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" placeholder="e.g. 03320234557" />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="e.g. name@outlook.com" />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Check 
                  type="checkbox"
                  label="I agree to receive travel related information and deals"
                />
              </Form.Group>
            </Row>
            </Form>
            </Card.Body>
            </Card>

        <h2></h2>
        <Card>
        <Card.Body>
          <Card.Title>Traveler Details for Adult 1</Card.Title>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Select Title</Form.Label>
                <div className='d-flex justify-content-between border rounded p-1'>
                <Form.Check 
                  type="radio"
                  label="Mr"
                  name="title"
                />
                <Form.Check 
                  type="radio"
                  label="Mrs"
                  name="title"
                />
                <Form.Check 
                  type="radio"
                  label="Ms"
                  name="title"
                />
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="6">
                <Form.Label>Given Name</Form.Label>
                <Form.Control type="text" placeholder="Enter name as per Passport" />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Surname</Form.Label>
                <Form.Control type="text" placeholder="Enter name as per Passport" />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="12">
                <Form.Label>Frequent Flyer Number (optional)</Form.Label>
                <Form.Control type="text" placeholder="Loyalty points/miles won’t be added for incorrect entries." />
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Button variant="primary" type="submit" className='mt-2' >
              Continue
            </Button>
    </div>

    <div className='col-lg-4 col-sm-12'>


    <h5>Your Bookings</h5>
      <Card className="border mb-3">
        <Card.Body>
          <Row className="align-items-center mb-2">
            <Col xs={12}>
              <Badge bg="light" text="dark" className="mb-2">
                Departing • 27 Sep, 2024
              </Badge>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={2}>
              <img src="" alt="Emirates" style={{ width: '30px' }} />
            </Col>
            <Col xs={10}>
              <span>Emirates EK-613 (U)</span>
            </Col>
          </Row>
          <Row className="align-items-center my-2">
            <Col xs={5}>
              <h5 className="m-0">09:20 AM</h5>
            </Col>
            <Col xs={2} className="text-center">
              <Badge bg="light" text="dark">
                3h 15m
              </Badge>
            </Col>
            <Col xs={5} className="text-end">
              <h5 className="m-0">11:35 AM</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Islamabad (ISB) - Nonstop - Dubai (DXB)</span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span>🧳 Total: 30kg</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
        <Card.Text>
        <i className="bi bi-box-arrow-in-right me-2"></i>
        Sign in or create an account for faster bookings!
          </Card.Text>
          <Button variant="outline-primary" className="w-100 mb-2">
            Sign In
          </Button> 
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <Card.Title>Price Summary</Card.Title>
          <Card.Text>
            Emirates (Adult x 1): PKR 119,767<br />
            <strong>Price you pay: PKR 119,767</strong>
          </Card.Text>
        </Card.Body>
      </Card>

      

      
    </div>
    </Container>
    </>
  );
}

export default BookingForm;
