import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const TransactionForm = ({ handshakeData }) => {
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const formRef = useRef(null);

    useEffect(() => {
        const fetchRequestHash = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/generate-hash/`, {
                    auth_token: handshakeData.auth_token,
                    key1: handshakeData.KEY1,
                    key2: handshakeData.KEY1,
                    transaction_type_id: "1",
                    transaction_amount: "1000",
                    mobile_number: "1234502323",
                    email_address: "h@gmail.com",
                    order_id: handshakeData.order_id,
                    HS_ChannelId: handshakeData.HS_ChannelId,
                    HS_ReturnURL: handshakeData.HS_ReturnURL,
                    HS_MerchantId: handshakeData.HS_MerchantId,
                    HS_StoreId: handshakeData.HS_StoreId,
                    HS_MerchantHash: handshakeData.HS_MerchantHash,
                    HS_MerchantUsername: handshakeData.HS_MerchantUsername,
                    HS_MerchantPassword: handshakeData.HS_MerchantPassword
                });

                if (response.data) {
                    setFormData(response.data);
                }
            } catch (error) {
                console.error("Error generating request hash:", error);
            } finally {
                setLoading(false); // Set loading to false when API call is done
            }
        };

        fetchRequestHash();
    }, [handshakeData]);

    useEffect(() => {
        if (formData && formRef.current) {
            formRef.current.submit();
        }
    }, [formData]);

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only ">Loading...</span></Spinner>;
    }

    return (
        <form ref={formRef} id="transactionForm" action="https://sandbox.bankalfalah.com/SSO/SSO/SSO" method="post">
            <input type="hidden" name="AuthToken" value={formData.auth_token} />
            <input type="hidden" name="RequestHash" value={formData.request_hash} />
            <input type="hidden" name="ChannelId" value={formData.ChannelId} />
            <input type="hidden" name="Currency" value="PKR" />
            <input type="hidden" name="IsBIN" value="0" />
            <input type="hidden" name="ReturnURL" value={formData.ReturnURL} />
            <input type="hidden" name="MerchantId" value={formData.MerchantId} />
            <input type="hidden" name="StoreId" value={formData.StoreId} />
            <input type="hidden" name="MerchantHash" value={formData.MerchantHash} />
            <input type="hidden" name="MerchantUsername" value={formData.MerchantUsername} />
            <input type="hidden" name="MerchantPassword" value={formData.MerchantPassword} />
            <input type="hidden" name="TransactionTypeId" value={formData.TransactionTypeId} />
            <input type="hidden" name="TransactionReferenceNumber" value={formData.TransactionReferenceNumber} />
            <input type="hidden" name="TransactionAmount" value={formData.TransactionAmount} />
            <input type="hidden" name="MobileNumber" value={formData.MobileNumber} />
            <input type="hidden" name="EmailAddress" value={formData.EmailAddress} />
        </form>
    );
};

export default TransactionForm;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const TransactionForm = ({ handshakeData }) => {
//     const [formData, setFormData] = useState(null);

//     useEffect(() => {
//         const fetchRequestHash = async () => {
//             try {
//                 const response = await axios.post('https://k480x10x-8000.inc1.devtunnels.ms/payments/generate-hash/', {
//                     auth_token: handshakeData.auth_token,
//                     key1: handshakeData.KEY1,
//                     key2: handshakeData.KEY1,
//                     transaction_type_id: "1",
//                     transaction_amount: "1000",
//                     mobile_number: "1234502323",
//                     email_address: "h@gmail.com",
//                     order_id: handshakeData.order_id,
//                     HS_ChannelId: handshakeData.HS_ChannelId,
//                     HS_ReturnURL: handshakeData.HS_ReturnURL,
//                     HS_MerchantId: handshakeData.HS_MerchantId,
//                     HS_StoreId: handshakeData.HS_StoreId,
//                     HS_MerchantHash: handshakeData.HS_MerchantHash,
//                     HS_MerchantUsername: handshakeData.HS_MerchantUsername,
//                     HS_MerchantPassword: handshakeData.HS_MerchantPassword
//                 });

//                 if (response.data) {
//                     setFormData(response.data);
//                 }
//             } catch (error) {
//                 console.error("Error generating request hash:", error);
//             }
//         };

//         fetchRequestHash();
//     }, [handshakeData]);

//     if (!formData) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <form id="transactionForm" action="https://sandbox.bankalfalah.com/SSO/SSO/SSO" method="post">
//             <input type="hidden" name="AuthToken" value={formData.auth_token} />
//             <input type="hidden" name="RequestHash" value={formData.request_hash} />
//             <input type="hidden" name="ChannelId" value={formData.ChannelId} />
//             <input type="hidden" name="Currency" value="PKR" />
//             <input type="hidden" name="IsBIN" value="0" />
//             <input type="hidden" name="ReturnURL" value={formData.ReturnURL} />
//             <input type="hidden" name="MerchantId" value={formData.MerchantId} />
//             <input type="hidden" name="StoreId" value={formData.StoreId} />
//             <input type="hidden" name="MerchantHash" value={formData.MerchantHash} />
//             <input type="hidden" name="MerchantUsername" value={formData.MerchantUsername} />
//             <input type="hidden" name="MerchantPassword" value={formData.MerchantPassword} />
//             <input type="hidden" name="TransactionTypeId" value={formData.TransactionTypeId} />
//             <input type="hidden" name="TransactionReferenceNumber" value={formData.TransactionReferenceNumber} />
//             <input type="hidden" name="TransactionAmount" value={formData.TransactionAmount} />
//             <input type="hidden" name="MobileNumber" value={formData.MobileNumber} />
//             <input type="hidden" name="EmailAddress" value={formData.EmailAddress} />
//             <button type="submit">Proceed to Payment</button>
//         </form>
//     );
// };

// export default TransactionForm;