import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import carasol1 from "../Images/carasol1.png";
import carasol2 from "../Images/carasol2.png";
import carasol3 from "../Images/carasol3.png";
import whyuse1 from "../Components/assets/icons/whyUseIcon1.png";
import whyuse2 from "../Components/assets/icons/whyUseIcon2.png";
import whyuse3 from "../Components/assets/icons/whyUseIcon3.png";
import ImageSlider from "../Components/flights/ImageSlider.jsx";
import object from "../Images/object.png";
import FlightSearchForm from "../Components/flights/FlightSearchForm";
// import FlightSearchData from "../Components/flights/FlightSearchData";
// import FilterComponent from "../Components/flights/FilterComponent";
// import AboutUs from "./AbouUS";
import FrequentlyAskQuestions from "../Components/FrequentlyAskQuestions";
import MobileApp from "../Components/MobileApp";
// import CardList from "../Components/CardList.jsx";
import FlightCard from "../Components/flights/FlightCard.jsx";
import { menuItem } from "../Components/layout/Sidebar.js";
import { Link } from "react-router-dom";
import FlightReviewPage from "./FlightReviewPage/FlightReviewPage.jsx";
import FlightCardCopy from "../Components/flights/FlightCardCopy.jsx";
import FlightSearchFormCopy from "../Components/flights/FlightSearchCopy.jsx";
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const Home = ({copy}) => {
  const [showForm, setShowForm] = useState(true);
  const [showMultiCityForm, setShowMultiCityForm] = useState(false);
  const [departureOptions, setDepartureOptions] = useState([]);
  const [loadingDepartures, setLoadingDepartures] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);


  // if(copy){
  //   alert("copy page")
  // }
 

  const handleRadioChange = (event) => {
    setShowForm(
      event.target.value === "one_way" || event.target.value === "return"
    );
    setShowMultiCityForm(event.target.value === "multi_city");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle form submission
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isMobile){
    return  <div style={{backgroundColor: '#f2f4f7'}}>
      <div style={{height: '80vh', display: 'flex', justifyContent: 'center' , width: '100vw' , boxSizing: 'border-box' , flexWrap: 'wrap' , padding: '20px 8px' ,  margin: '0px'  }}>
      {menuItem?.map((item, index) => (
        <div style={{width: '33.333%' , padding: '5px' , display: 'flex' , alignItems: 'center' , justifyContent: 'center' , height: '100px' , }}>
          <Link to={item.path} style={{width: '90%' ,backgroundColor: 'white'   , borderRadius: '12px' , display: "flex" , flexDirection: 'column' , alignItems: "center" , justifyContent: 'center', padding: '20px' }}>
          <div style={{color: "#00a7e1" , fontSize: '30px' }}>{item.icon}</div>
          <div>{item.name}</div>
          </Link>
        </div>
    ))}
    </div>
    {/* slider */}
  </div>
  }
  

  return (
    <>
     
          <FlightSearchForm/>
          {/* <FlightReviewPage /> */}
          {/* {copy ? <FlightSearchFormCopy /> : <FlightSearchForm/>} */}
      
      <section className="quick-links-section pt-5 my-5 mt-0 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5 ">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="#mobile-app ">
                  <div className="d-flex align-items-center ">
                    <div className="side-icon">
                      <img src={icon1} />
                    </div>
                    <div className="content ms-3 ">
                      <h5>Download Ticketistan App</h5>
                      <p className="mb-0">Great detais on our mobile app</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/contact-us">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon2} />
                    </div>
                    <div className="content ms-3">
                      <h5>Help Center</h5>
                      <p className="mb-0">Search on mobile apps with ease</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/user/bookings">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon3} />
                    </div>
                    <div className="content ms-3">
                      <h5>Manage Bookings</h5>
                      <p className="mb-0">Explore and bookings hassel-frees</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="travel-partners-section">
        <div className="container pt-md-4 pb-lg-5 pt-lg-0 px-3 px-md-4 px-lg-5 ">
          <p className="h2 fs-3 custom-font-bold mb-1">Latest Offers</p>
          <p className="lead h5 custom-font-normal">
          Ticketistan furnishes affordable & comfortable services across
            Pakistan.{" "}
          </p>
          <div className="row carasolParent">
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol1} />
              </div>
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol2} />

          </div>
          <div className="col-lg-4 col-sm-12 mb-3">
          <img src={carasol3} />

          </div>
          </div>
          </div>
      </section>

      <section className="why-use-ticketestan bg-blue ">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <p className="h2 fs-3 custom-font-bold  mb-1 mt-3">
                Why Use Ticketistan?
              </p>
              <p className="lead h5 custom-font-normal ">
                We furnish ample of deals in Pakistan.
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-center mt-2">
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse1} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse2} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse3} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     <FrequentlyAskQuestions/>

      <MobileApp/>

      <section className="top-route-section mt-5 pb-5">
        <div className="container py-4 px-3 px-md-4 px-lg-5">
          <div className="border-top border-bottom">
            <div className="accordion accordion-flush" id="route">
              <div className="accordion-item">
                <h2 className="accordion-header" id="route-headingOne">
                  <button
                    className="accordion-button px-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#route-collapseOne"
                    aria-expanded="false"
                    aria-controls="route-collapseOne"
                  >
                    <h4 className="font-weight-600 text-capitalize">
                    Top Flights
                    </h4>
                  </button>
                </h2>
                <div
                  id="route-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="route-headingOne"
                  data-bs-parent="#route"
                >
                  <div className="accordion-body px-0">
                  <ul className="list-container ">
            <li className="list-item">
              <a href="">Lahore to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Multan to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Sialkot to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Jeddah to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Jeddah to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Multan Flights</a>
            </li>
            <li className="list-item">
              <a href="">Multan To Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Peshawar to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Faisalabad to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Quetta Flights</a>
            </li>
            <li className="list-item">
              <a href="">Quetta to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Peshawar Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Faisalabad Flights </a>
            </li>
            <li className="list-item">
              <a href="">Dubai to Sialkot Flights </a>
            </li>
            <li className="list-item">
              <a href="">Quetta to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Peshawar to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Dubai to Multan Flights</a>
            </li>
          </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Looking for Flight Booking Online?</h2>
      <p class="fs-6 fclr">
      Ticketistan provides a great opportunity for arrangement of yourair-affairs more easy. Your trust is more than anything else for us. Pakistan’slargest online ticketing as per your dreams which may not provide anywhereelse. Come and find complete solution of your online booking globally withoutany complication.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Fly Well</h2>
      <p class="fs-6 fclr">
      Pandemic COVID-19 causedhuge uncertainty around the world but Ticketistan has alternative options toprotect your booking and lay out online services more booking. Your trip of anykind important for us, therefore, you can schedule your flights online throughTicketistan without any break.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Manage Your Airline Booking</h2>
      <p class="fs-6 fclr">
      Peace of mind means changing your travel dates as often as you need. We understand your plans change, and that is why Ticketistan offers a fee-free policy to change your booking details before 48 hours of your flight. Now book your online flight ticket with Ticketistan and relax knowing that you are in safe hands.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 custom-font-bold">Fly Wherever You Want</h2>
      <p class="fs-6 fclr">
      Our online system has more satisfaction for its customers both domestic and international flights.
      </p>
      <h3 className="fs-6">Our International destinations include:</h3>
  <ul className="fclr">
    <li>Dubai</li>
    <li>Turkey</li>
    <li>Maldives</li>
    <li>United Kingdom</li>
  </ul>

  <h3 className="fs-6">Our International destinations include:</h3>
  <ul className="fclr">
    <li>Karachi</li>
    <li>Islamabad</li>
    <li>Lahore</li>
    <li>Multan</li>
    <li>Sialkot</li>
  </ul>

      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    
      <h2 class="mb-4 fs-5 custom-font-bold">How to Book Flight Tickets Online ?</h2>
      <p class="fs-6 fclr">Following steps to book your airline ticket through Ticketistan:</p>
      <ol className="fclr">
        <li>Go to the flights menu.</li>
        <li>Enter your travel details.</li>
        <li>Select your desired airline.</li>
        <li>Provide your contact details.</li>
        <li>Checkout your purchase.</li>
      </ol>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    <h2 class="mb-4 fs-5 custom-font-bold">Ticketistan – OnlineTickets Booking in Pakistan</h2>
      <p class="fs-6 fclr">If you are enthusiastic fortravelling and find difficulty in comfortable flights tickets along with movie tickets, hotel bookings across Pakistan. In this connection visit our online-ticketing system where you can find solutions of all problems hassle free. Moreover, we provide online discount with our in-app bundles.</p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    <h2 class="mb-4 fs-5 custom-font-bold">Travel through Pakistan</h2>
      <p class="fs-6 fclr">To aval mega discount on bus and domestic flights tickets, Ticketistancan browse for you with more ease and comfort</p>
      
    </div>
  </div>


</div>
        </div>
      </section>
    </>
  );
};

export default Home;
