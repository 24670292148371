import React from 'react';
import { Card, ListGroup, Row, Col, Button } from 'react-bootstrap';

const InvoiceItems = ({ items }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Items <Button variant="link" className="p-0">Customize columns</Button>
        </Card.Title>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col>Description</Col>
              <Col>QTY</Col>
              <Col>Price</Col>
              <Col>Total amount</Col>
            </Row>
          </ListGroup.Item>
          {items.map((item, index) => (
            <ListGroup.Item key={index}>
              <Row>
                <Col>{item.description}</Col>
                <Col>{item.qty}</Col>
                <Col>${item.price.toFixed(2)}</Col>
                <Col>${item.total.toFixed(2)}</Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default InvoiceItems;
