import React from 'react'

const FrequentlyAskQuestions = () => {
  return (
    <>
     <section className="Frequently-Asked-Questions" id="faq">
        <div className="container pt-5 cutom-pt-5  px-3 px-md-4 px-lg-5 pb-lg-5">
          <p className="h2 fs-3 font-weight-600 mb-3">Frequently Asked Questions</p>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What types of events does Ticketistan.com offer?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Ticketistan.com provides a diverse selection of events,
                  including concerts,sports matches, theater performances and
                  more.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How can I book tickets?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Booking is effortless!Simply use our streamlined process,
                  ensuring a hassle-free experience with justa few clicks.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Can I explore events internationally?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes! Ticketistan.com allows you to discover events both locally
                  andinternationally, offering a gateway to a global array of
                  experiences.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Are there corporate packages available?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Absolutely! Elevate yourbusiness engagements with our
                  exclusive corporate packages, perfect forimpressing clients or
                  rewarding your team.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  Is my transaction secure?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Your security is paramount. Rest assured, Ticketistan.com
                  provides securetransactions for worry-free ticket purchases.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  How quickly will I receive confirmation and e-tickets?
                </button>
              </h2>
              <div
                id="flush-collapSix"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingSix"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Swiftly! Expect instantconfirmation and e-tickets directly to
                  your inbox, ensuring no waiting for yourkey to unforgettable
                  moments.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingSiven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSiven"
                  aria-expanded="false"
                  aria-controls="flush-collapseSiven"
                >
                  Can I book tickets using a mobile device?
                </button>
              </h2>
              <div
                id="flush-collapseSiven"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingSiven"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Certainly! Ticketistan.com offers a user-friendly mobile
                  platform,allowing you to book tickets on the go, anytime,
                  anywhere.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseEight"
                  aria-expanded="false"
                  aria-controls="flush-collapseEight"
                >
                  Are there gift options available?
                </button>
              </h2>
              <div
                id="flush-collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingEight"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Absolutely! Gift theexperience with our customizable gift
                  options, perfect for any occasion andunlocking a world of live
                  entertainment.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingNine">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseNine"
                  aria-expanded="false"
                  aria-controls="flush-collapseNine"
                >
                  Are there any exclusive deals or discounts available?
                </button>
              </h2>
              <div
                id="flush-collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingNine"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Don't miss out! Keep an eyeon our Hot Offers for exclusive
                  deals and incredible discounts, making yournext experience
                  even more extraordinary.
                </div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTen"
                  aria-expanded="false"
                  aria-controls="flush-collapseTen"
                >
                  What if I have questions or need assistance?
                </button>
              </h2>
              <div
                id="flush-collapseTen"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTen"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our dedicated customer careteam is ready to assist you. If you
                  have any questions or need help, feel freeto reach out we're
                  here to ensure your Ticketistan.com experience istop-notch.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FrequentlyAskQuestions