import React from 'react'
import { Breadcrumb } from 'react-bootstrap';
import CardList from '../Components/CardList';

const Announcements = () => {
  return (
    <>
      {/* Banner with Breadcrumb */}
      <div className="Announcements-banner breadcrumb">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col">
              {/* Breadcrumb */}
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item >Announcements</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <CardList/>

    </>
  )
}

export default Announcements