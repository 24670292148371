import React from 'react';
import { Card, Button, Image } from 'react-bootstrap';

const InvoiceDetails = ({ customer }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Details</Card.Title>
        <div className="d-flex align-items-center mb-3">
          <Image src="https://via.placeholder.com/40" roundedCircle className="me-2" />
          <div>
            <strong>{customer.name}</strong><br />
            {customer.email}<br />
            {customer.invoiceDate}<br />
            <strong>#{customer.invoiceNumber}</strong>
          </div>
        </div>
        <Button variant="primary" className="w-100 mb-3">Invoice PDF</Button>
      </Card.Body>
    </Card>
  );
};

export default InvoiceDetails;
