import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import InvoiceActions from './InvoiceActions';
import InvoiceHistory from './InvoiceHistory';
import InvoiceItems from './InvoiceItems';
import InvoiceDetails from './InvoiceDetails';
import AskUs from './AskUs';

const InvoicePage = () => {
  const [invoiceData, setInvoiceData] = useState({
    invoiceNumber: '42D42-0001',
    amount: 2250.65,
    status: 'Open',
    dueDate: 'Due next month',
    history: [
      {
        icon: 'bi-envelope-fill',
        action: 'Invoice was sent to claudnew@gmail.com',
        date: 'Jul 2, 2023, 2:56 PM'
      },
      {
        icon: 'bi-file-earmark-fill',
        action: 'Invoice was finalized',
        date: 'Jul 2, 2023, 2:54 PM'
      },
      {
        icon: 'bi-file-earmark-plus-fill',
        action: 'Invoice #42D42-0001 was created',
        date: 'Jul 2, 2023, 1:32 PM'
      }
    ],
    items: [
      {
        description: 'UX/UI Design for Mobile app',
        qty: 1,
        price: 1150.52,
        total: 1150.52
      },
      {
        description: 'UX/UI Design for Landing page',
        qty: 1,
        price: 550.52,
        total: 550.52
      }
    ],
    customer: {
      name: 'Claud Newton',
      email: 'claudnew@gmail.com',
      invoiceDate: 'Aug 5, 3:16 AM',
      invoiceNumber: '42D42-0001'
    }
  });

  const handleSendInvoice = () => {
    // Logic to send the invoice
    alert('Invoice sent!');
  };

  const handleEditInvoice = () => {
    // Logic to edit the invoice
    alert('Edit invoice!');
  };

  const handleAddNote = () => {
    // Logic to add a note
    const note = prompt('Enter your note:');
    if (note) {
      setInvoiceData((prevData) => ({
        ...prevData,
        history: [
          ...prevData.history,
          {
            icon: 'bi-file-earmark-text',
            action: `Note added: ${note}`,
            date: new Date().toLocaleString()
          }
        ]
      }));
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <InvoiceActions 
        invoiceNumber={invoiceData.invoiceNumber} 
        amount={invoiceData.amount} 
        status={invoiceData.status} 
        dueDate={invoiceData.dueDate}
        onSendInvoice={handleSendInvoice}
        onEditInvoice={handleEditInvoice}
        onAddNote={handleAddNote}
      />
      <Row className="mt-3">
        <Col md={8}>
          <InvoiceHistory history={invoiceData.history} />
          <InvoiceItems items={invoiceData.items} />
        </Col>
        <Col md={4}>
          <InvoiceDetails customer={invoiceData.customer} />
          <AskUs />
        </Col>
      </Row>
    </Container>
  );
};

export default InvoicePage;
