import React, { useEffect, useState } from 'react';
import { Card, Col,Row } from 'react-bootstrap';
import MobileApp from '../Components/MobileApp';
// import './MovieSection.css';
import { Container } from "react-bootstrap";

const Movie = () => {
  // const [movies, setMovies] = useState({
  //   genres: [],
  //   popular: [],
  //   trending: [],
  //   newReleased: [],
  //   mustWatch: []
  // });

  // useEffect(() => {
  //   // Replace with your actual data fetching logic
  //   fetchMovies();
  // }, []);

  // const fetchMovies = async () => {
    // Sample data fetching, replace with your API

    // const fetchMovies = async () => {
    //   try {
    //     const response = await fetch('your-api-endpoint');
    //     const data = await response.json();
    //     setMovies({
    //       genres: data.genres,
    //       popular: data.popular,
    //       trending: data.trending,
    //       newReleased: data.newReleased,
    //       mustWatch: data.mustWatch,
    //     });
    //   } catch (error) {
    //     console.error('Error fetching movies:', error);
    //   }
    // };
    

  //   const fetchedMovies = {
  //     genres: [
  //       { title: 'Genre Movie 1', image: 'path/to/image1.jpg' },
  //       { title: 'Genre Movie 2', image: 'path/to/image2.jpg' },
  //       { title: 'Genre Movie 1', image: 'path/to/image1.jpg' },
  //       { title: 'Genre Movie 2', image: 'path/to/image2.jpg' },
  //       // More movies...
  //     ],
  //     popular: [
  //       { title: 'Popular Movie 1', image: 'path/to/image3.jpg' },
  //       { title: 'Popular Movie 2', image: 'path/to/image4.jpg' },
  //       { title: 'Popular Movie 2', image: 'path/to/image4.jpg' },
  //       { title: 'Popular Movie 2', image: 'path/to/image4.jpg' },
       
  //     ],
  //     trending: [
  //       { title: 'Trending Movie 1', image: 'path/to/image5.jpg' },
  //       { title: 'Trending Movie 2', image: 'path/to/image6.jpg' },
  //       { title: 'Trending Movie 2', image: 'path/to/image6.jpg' },
  //       { title: 'Trending Movie 2', image: 'path/to/image6.jpg' },
      
  //     ],
  //     newReleased: [
  //       { title: 'New Released Movie 1', image: 'path/to/image7.jpg' },
  //       { title: 'New Released Movie 2', image: 'path/to/image8.jpg' },
  //       { title: 'New Released Movie 2', image: 'path/to/image8.jpg' },
  //       { title: 'New Released Movie 2', image: 'path/to/image8.jpg' },
      
  //     ],
  //     mustWatch: [
  //       { title: 'Must Watch Movie 1', image: 'path/to/image9.jpg' },
  //       { title: 'Must Watch Movie 2', image: 'path/to/image10.jpg' },
  //       { title: 'Must Watch Movie 2', image: 'path/to/image10.jpg' },
  //       { title: 'Must Watch Movie 2', image: 'path/to/image10.jpg' },
       
  //     ],
  //   };
  //   setMovies(fetchedMovies);
  // };

  // const renderMovieCards = (movies) => {
  //   return movies.map((movie, index) => (
  //     <Col key={index} md={3}>
  //       <Card className="mb-3">
  //         <Card.Img variant="top" src={movie.image} />
  //         <Card.Body>
  //           <Card.Title>{movie.title}</Card.Title>
  //         </Card.Body>
  //       </Card>
  //     </Col>
  //   ));
  // };

  return (

    <>
     <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundColor: "#282c34",
        color: "white",
        fontSize: "3rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          animation: "pulse 2s infinite",
          margin: 0,
        }}
      >
        Launching Soon
      </h1>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.05);
              opacity: 0.8;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          h1 {
            animation: pulse 2s infinite;
          }
        `}
      </style>
    </Container>
    {/* <section >
    <div className="container mb-3 px-3 px-md-4 px-lg-5">
      <div className="row g-2 align-items-center">
      <div className="movie_bg">
        
      </div>
      </div>
      </div>
      </section>
    <section className="tour-listing-section">
        <div className="container pt-4 mb-3 px-3 px-md-4 px-lg-5">
      <h2>Our Genres</h2>
      <Row>{renderMovieCards(movies.genres)}</Row>
      
      <h2>Popular Top 10 in Genre</h2>
      <Row>{renderMovieCards(movies.popular)}</Row>
      
      <h2>Trending Shows Now</h2>
      <Row>{renderMovieCards(movies.trending)}</Row>
      
      <h2>New Released Shows</h2>
      <Row>{renderMovieCards(movies.newReleased)}</Row>
      
      <h2>Must Watch Shows</h2>
      <Row>{renderMovieCards(movies.mustWatch)}</Row>
    </div>
    </section>

    <MobileApp/>

    <section >
        <div className="container pt-4 mb-3 px-3 px-md-4 px-lg-5">
          
          <div class="row g-2 align-items-center">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Car Rental in Islamabad</h2>
      <p class="fs-6 fclr">
      If you are enthusiastic for travelling and find difficulty in comfortable flights tickets along with movie tickets, hotel bookings across Pakistan. In this connection visit our online e-ticketing system where you can find solutions of all problems hassle free. Moreover, we provide online discount with our in-app bundles.
      </p>
    </div>
  </div>
  
  <div class="row g-2 align-items-center">
    <div class="col-12 p-0">
      <h2 class="mb-3 fs-5 custom-font-bold">Travel through Pakistan</h2>
      <p class="fs-6 fclr">
      To aval mega discount on bus and domestic flights tickets, Ticketestancan browse for you with more ease and comfort..
      </p>
    </div>
  </div>
        </div>
      </section> */}
      
    </>
  );
};

export default Movie;
