import React from 'react';
import './Header.css'; // Assuming you have a CSS file for styling
import logo from './Logo.png';
import {FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';




const Header = ({ isOpen, toggle}) => {
  
  return (
    <>
    <header className="bg-white fixed-top pb-1 px-0 bor " id="header">
    <div className="mee alert ticketistanbro-header align-text-bottom border-0 rounded-0 font-weight-500 text-center custom-primary-background alert-dismissible fade show lh-base py-2 mb-0 text-white text-size-sm" role="alert ">
    
    <div className="mbl-res d-flex flex-wrap align-items-center justify-content-end ">
        
        {/* WhatsApp Button */}
        <a href="https://wa.me/message/https://wa.me/+923041110636?text=Hello%20Ticketistan%20Team%2C%20I%20need%20help%20booking%20a%20flightVJDZMED22Z4SJ1" target="_blank" rel="noopener noreferrer" className="text-white me-2 text-decoration-none">
            <i className="fab fa-whatsapp"></i>
            
        
        {/* WhatsApp Number */}
        <span className="me-4 text-white ps-1">+92 304 111 0636</span></a>
        
        <a href="tel:+923041110636" className="text-white me-2 text-decoration-none">
            <i className="fas fa-phone-alt"></i>
        
        
        <span className="text-white ps-1">+92 304 111 0636</span>
        </a>
        
    </div>
</div>


     <nav className="navbar navbar-expand-lg my-1 bg-white">
  <div className="container-fluid px-3 px-md-4">
    <div className="d-flex">
      <div id="sidebarToggle" className='me-4 d-none d-md-block'>
        {/* <div className='bars'><FaBars/></div> */}
        <div className='bars'><FaBars onClick={toggle}/></div>
      </div>
      <a href="/" className="navbar-brand" aria-label="ticketistan">
        <img  src={logo} alt="Logo" className="logo" />
      </a>
    </div>
    <ul className="navbar-nav ml-auto align-items-center flex-row">
      <li className="nav-item d-none d-md-block">
        <a href="/contact-us" className="nav-link p-0 mx-3 text-dark">Help</a>
      </li>
      <li className="nav-item">
        <Link to={"/login"} className="nav-link p-0 mx-3 text-dark">Login</Link>
      </li>
      <li className="nav-item d-none d-md-flex">
        <Link to={"/signUp"} className="nav-link text-white btn custom-primary-background px-4 ms-3">Sign up</Link>
      </li>
    </ul>
  </div>
</nav>


    </header>
    </>
  );
}

export default Header;



