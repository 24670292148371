import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const getSingleCard = async (flightId, ApiAccessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/vmultireisen/flights/items/select/`,
      {
      ItemId: flightId,
      },
      {
      headers: {
        "Content-Type": "application/json",
        "AccessToken": ApiAccessToken || localStorage.getItem('accessToken')
      }
      }
    );
    if (response.data && response.data.ItemId) {
      localStorage.setItem("ItemId", response.data.ItemId);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log("Axios error:", error.message);
      console.log("Error code:", error.code);
      console.log("Error config:", error.config);
      console.log("Error response:", error.response);
    } else {
      console.log("Unexpected error:", error);
    }
    throw error;
  }
};
