import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adults: "",
  children:"",
  infants:"",
};

const searchDataSlice = createSlice({
  name: "searchData",
  initialState,
  reducers: {
    UPDATE_DATA: (state, action) =>  {
        state.adults = action.payload?.adults
        state.children = action.payload?.children
        state.infants = action.payload?.infants
    }
  },
});
export const { UPDATE_DATA } = searchDataSlice.actions;
export default searchDataSlice.reducer;