import React from "react";
import { Breadcrumb } from "react-bootstrap";

const TermsandConditions = () => {
  return (
    <>
      {/* Banner with Breadcrumb */}
      <div className="terms-banner breadcrumb">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col">
              {/* Breadcrumb */}
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item>Announcements</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>

      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  1.General Agreement
                </h2>
                <p className="fs-6 fclr">
                  a. By using Ticketestan.com ticketing services, event
                  organizers agreeto comply with these terms and conditions.
                </p>
                <p className="fs-6 fclr">
                  b. Ticketestan.com reserves the right to modifythese terms
                  with notice.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">2.Event Listing</h2>
                <p className="fs-6 fclr">
                  a. Event organizers are responsible for providing accurate and
                  complete event information.
                </p>
                <p className="fs-6 fclr">
                  b. Ticketistan.com may decline or remove events that violate
                  policies or standards.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  3.Ticket Sales and Pricing
                </h2>
                <p className="fs-6 fclr">
                  a. Event organizers determine ticket prices and availability.
                </p>
                <p className="fs-6 fclr">
                  b. Ticketistan.com may charge a service fee, the details of
                  which will be communicated to the organizer.{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  4.Payment and Settlement
                </h2>
                <p className="fs-6 fclr">
                  a. Payment for tickets sold will be settled as per the
                  agreed-upon schedule.
                </p>
                <p className="fs-6 fclr">
                  b. Refunds and chargebacks are handled in accordance with
                  Ticketistan.com's policies.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  5.Promotional Activities
                </h2>
                <p className="fs-6 fclr">
                  a. Event organizers may use Ticketistan.com's promotional
                  tools within defined guidelines.
                </p>
                <p className="fs-6 fclr">
                  b. Misuse of promotional tools may result in suspension of
                  services.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">6.Data and Privacy</h2>
                <p className="fs-6 fclr">
                  a. Ticketistan.com collects and processes customer data in
                  compliance with privacy laws.
                </p>
                <p className="fs-6 fclr">
                  b. Event organizers have limited access to customer data and
                  must adhere to privacy standards.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  7.Cancellation and Refunds
                </h2>
                <p className="fs-6 fclr">
                  a. Event organizers are responsible for communicating
                  cancellation policies to ticket buyers.
                </p>
                <p className="fs-6 fclr">
                  b. Refund requests are subject to the organizer's policies and
                  Ticketistan.com's terms.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">8.Liability</h2>
                <p className="fs-6 fclr">
                  a. Ticketistan.com is not liable for any losses, damages, or
                  disputes arising from events or ticket transactions.
                </p>
                <p className="fs-6 fclr">
                  b. Event organizers indemnify Ticketistan.com against any
                  claims related to their events.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  9.Intellectual Property
                </h2>
                <p className="fs-6 fclr">
                  a. Event organizers grant Ticketistan.com the right to use
                  event-related content for promotional purposes.
                </p>
                <p class="fs-6 fclr">
                  b. Ticketistan.com's branding and intellectual property must
                  not be used without permission.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">10. Termination</h2>
                <p className="fs-6 fclr">
                  a. Either party may terminate the agreement with notice.
                </p>
                <p className="fs-6 fclr">
                  b. Termination does not release the organizer from fulfilling
                  obligations related to ongoing events.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  11. Force Majeure
                </h2>
                <p className="fs-6 fclr">
                  a. Neither party is liable for failure to perform due to
                  unforeseen circumstances beyond their control.{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  12. Governing Law
                </h2>
                <p className="fs-6 fclr">
                  a. These terms are governed by and construed in accordance
                  with the laws of [jurisdiction].
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-0">
                <h2 className="mb-4 fs-5 custom-font-bold">
                  13. Contact Information
                </h2>
                <p className="fs-6 fclr">
                  a. For inquiries related to these terms, please contact us at
                  [contact@email.com].
                </p>
                <p className="fs-6 fclr">
                  By using Ticketestan.com ticketing services, event organizers
                  agree to abide by these terms and conditions. Failure to
                  comply may result in the suspension of services or legal
                  action.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsandConditions;
