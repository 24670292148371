import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FlightCard from "../../Components/flights/FlightCard";
import { getSingleCard } from "../../api/flights";
import {
  Container,
  Row,
  Col,
  Card,
  // Button,
  ListGroup,
  Badge,
} from "react-bootstrap";
import FlightCardCopy from "../../Components/flights/FlightCardCopy";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { searchResults } from "../../Components/flights/example_search_result";

const FlightReviewPage = () => {
  const { flightId } = useParams();
  const [flightOffer, setFlightOffer] = useState();
  const ApiAccessToken = localStorage.getItem("ApiAccessToken");
  const [basePrice, setBasePrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getSingleCard(flightId, ApiAccessToken);
      // debugger;
      setFlightOffer(response?.data); // Ensure you set the response data
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect API CALL
  useEffect(() => {
    fetchData();
  }, []);

  console.log(flightOffer?.itemId, "flightOffer");

  return (
    <div className="pt-5 pb-5">
      <div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-8">
              { !flightOffer ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px"
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <FlightCardCopy flightOffer={flightOffer} inReviewPage={true} />
              )}
              {/* <div>{flightOffer?.optionalServices?.[0].id}</div> */}
            </div>
            <div className="col-3">
              <Card>
                <Card.Header>
                  <strong>Price Summary</strong>
                </Card.Header>
                <Card.Body>
                  { /* <p>Serene Air (Adult x 1)</p>*/ }
                  <h4 style={{ fontSize: "14px", paddingBottom:'5px' }}>BasePrice: {flightOffer?.price.currency} {flightOffer?.price.basePrice.toFixed(2)}</h4>
                  <h4 style={{ fontSize: "14px", paddingBottom:'5px' }}>Tax: {flightOffer?.price.currency} {flightOffer?.price.tax}</h4>
                  <h4 style={{ fontSize: "14px",  paddingBottom:'5px'}}>Total: {flightOffer?.price.currency} {flightOffer?.price.total.toFixed(2)}</h4>
                  <Link
                    to={
                      flightId
                        ? "passenger-details"
                        : `/flight/${flightOffer?.itemId}`
                    }
                    className="btn btn-primary"
                  >
                    Continue Booking
                  </Link>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {/* <Container className="mt-5">
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Header>
              <strong>24 Aug, 2024</strong>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2}>
                  <img src="serene-air-logo.png" alt="Serene Air" />
                </Col>
                <Col md={10}>
                  <h5>Serene Air ER-701 (OW)</h5>
                  <p>
                    <strong>05:00 PM</strong> - Islamabad (ISB) - <strong>Nonstop</strong> - <strong>07:35 PM</strong> - Dubai (DXB)
                  </p>
                  <p>Economy Regular</p>
                  <ListGroup horizontal>
                    <ListGroup.Item>Total: 40kg Pcs: 2</ListGroup.Item>
                    <ListGroup.Item>Meal</ListGroup.Item>
                  </ListGroup>
                  <Button variant="outline-primary" className="mt-2">Change</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <strong>27 Sep, 2024</strong>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2}>
                  <img src="serene-air-logo.png" alt="Serene Air" />
                </Col>
                <Col md={10}>
                  <h5>Serene Air ER-702 (LB)</h5>
                  <p>
                    <strong>06:15 PM</strong> - Dubai (DXB) - <strong>Nonstop</strong> - <strong>10:25 PM</strong> - Islamabad (ISB)
                  </p>
                  <p>Free Baggage</p>
                  <ListGroup horizontal>
                    <ListGroup.Item>Total: 20kg Pcs: 1</ListGroup.Item>
                    <ListGroup.Item>Meal</ListGroup.Item>
                  </ListGroup>
                  <Button variant="outline-primary" className="mt-2">Change</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Header>
              <strong>Price Summary</strong>
            </Card.Header>
            <Card.Body>
              <p>Serene Air (Adult x 1)</p>
              <h3>PKR 108,183</h3>
              <Button variant="primary" block>Continue Booking</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Container className="mt-5">
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Header className="bg-light">
              <strong>24 Aug, 2024</strong>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2} className="d-flex align-items-center">
                  <img src="serene-air-logo.png" alt="Serene Air" style={{ width: '100%' }} />
                </Col>
                <Col md={10}>
                  <Row className="align-items-center">
                    <Col>
                      <h5 className="mb-0">05:00 PM</h5>
                      <small>Islamabad (ISB)</small>
                    </Col>
                    <Col className="text-center">
                      <Badge bg="secondary">3h 35m</Badge>
                    </Col>
                    <Col>
                      <h5 className="mb-0">07:35 PM</h5>
                      <small>Dubai (DXB)</small>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <p className="mb-0">Economy Regular</p>
                      <ListGroup horizontal className="mb-2">
                        <ListGroup.Item className="border-0 ps-0">Total: 40kg Pcs: 2</ListGroup.Item>
                        <ListGroup.Item className="border-0">Meal</ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col className="text-end">
                      <Button variant="outline-primary">Change</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="bg-light text-end">
              <a href="#">Details</a>
            </Card.Footer>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Header className="bg-light">
              <strong>Price Summary</strong>
            </Card.Header>
            <Card.Body>
              <p>Serene Air (Adult x 1)</p>
              <h3>PKR 108,183</h3>
              <Button variant="primary" block>Continue Booking</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>  
    <Container>
    <Card className="mb-3">
      <Card.Header as="h5">24 Aug, 2024</Card.Header>
      <Card.Body>
        <Row className="align-items-center">
          <Col xs={2} className="text-center">
            <img src="serene-air-logo.png" alt="Serene Air" style={{ width: '50px' }} />
          </Col>
          <Col xs={10}>
            <Row>
              <Col xs={12} className="d-flex justify-content-between">
                <span>5:00 PM</span>
                <span>3h 35m</span>
                <span>7:35 PM</span>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-between">
                <span>Islamabad (ISB)</span>
                <span>Nonstop</span>
                <span>Dubai (DXB)</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} className="d-flex justify-content-between">
                <span>Total: 40kg Pcs: 2</span>
                <span>Meal</span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}>
                <span>Economy Regular</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col xs={12} className="text-end">
            <Button variant="light">Change</Button>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
    </Container> */}
    </div>
  );
};

export default FlightReviewPage;
