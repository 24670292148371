import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./FlightSearchForm.css";
import FlightOffersSection from "./FlightOffersSection";
import Loader from "../Loader";
import { AirportData } from "./AirportData"; // Correctly import AirportData
import arrival from "../assets/svg/arrival.svg";
import departure from "../assets/svg/departure.svg";
import DatePickerIcon from "../assets/svg/Datepicker.svg";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function FlightSearchFormCopy() {
    const navigate = useNavigate()
    const { body } = useParams();
    // alert("2nd page")
  const [flightType, setFlightType] = useState("oneWay");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [departureDate, setDepartureDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [multiCitySegments, setMultiCitySegments] = useState([
    { departure: "", arrival: "", date: getCurrentDate() },
  ]);
  const [flightOffers, setFlightOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [departureQuery, setDepartureQuery] = useState("");
  const [arrivalQuery, setArrivalQuery] = useState("");
  const [showDepartureSuggestions, setShowDepartureSuggestions] =
    useState(false);
  const [showArrivalSuggestions, setShowArrivalSuggestions] = useState(false);

  function getCurrentDate() {
    return new Date().toISOString().split("T")[0];
  }

  useEffect(() => {
    const urlbody = JSON.parse(body)
    fetchCards(urlbody)
  } , [])


  const handleMultiCityChange = (index, field, value) => {
    const newSegments = [...multiCitySegments];
    newSegments[index][field] = value;
    setMultiCitySegments(newSegments);
  };

  const fetchCards = async (body) => {
    try {
        setLoading(true)
          const searchResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/flight-searchs/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "AccessToken": localStorage.getItem('accessToken')
              },
              body: JSON.stringify(body),
            }
          );
    
          if (searchResponse.ok) {
            const result = await searchResponse.json();
            setFlightOffers(result.data.entities);
            localStorage.setItem("ApiAccessToken", result.accessToken);
            const SeatsCount =  { adult : adultCount , child : childCount , infant : infantCount}
            localStorage.setItem('SeatsCount' , JSON.stringify(SeatsCount))
            console.log("Flight search results:", result.data.entities);
    
          } else {
            console.error("Error searching flights:", searchResponse.statusText);
          }
        } catch (error) {
          console.error("Error searching flights:", error);
        } finally {
          setLoading(false);
          // setFlightOffers(searchResults?.data?.entities)
        }
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/get-token/`);
      const data = await response.json();
      const accessToken = data.response.accessToken;

      // Store the access token in local storage
      localStorage.setItem('accessToken', accessToken);

      if(response.ok){
        
    // setLoading(true);

    // Helper function to get the airport code
    const getAirportCode = (query) => {
      const match = query.match(/\[(.*?)\]/);
      return match ? match[1] : "";
    };

    let body;
    if (flightType === "oneWay") {
      body = {
        adults: adultCount.toString(),
        child: childCount.toString(),
        infant: infantCount.toString(),
        segments: [
          {
            originCode: getAirportCode(departureQuery),
            destinationCode: getAirportCode(arrivalQuery),
            departureDate: departureDate,
            departureTime: "0",
          },
        ],
        flexSearch: "0",
        stops: "-1",
        class: e.target.class.value,
      };
    } else if (flightType === "roundTrip") {
      body = {
        adults: adultCount.toString(),
        children: [],
        segments: [
          {
            originCode: getAirportCode(departureQuery),
            destinationCode: getAirportCode(arrivalQuery),
            departureDate: departureDate,
            departureTime: "0",
          },
          {
            originCode: getAirportCode(arrivalQuery),
            destinationCode: getAirportCode(departureQuery),
            departureDate: arrivalDate,
            departureTime: "0",
          },
        ],
        flexSearch: "0",
        stops: "-1",
        class: e.target.class.value,
      };
    } else if (flightType === "multiCity") {
      body = {
        adults: adultCount.toString(),
        children: multiCitySegments.map((segment) => segment.age),
        segments: multiCitySegments.map((segment) => ({
          originCode: getAirportCode(segment.departure),
          destinationCode: getAirportCode(segment.arrival),
          departureDate: segment.date,
          departureTime: "0",
        })),
        flexSearch: "0",
        stops: "-1",
        class: e.target.class.value,
      };
    }

    const bodyString = JSON.stringify(body);
    // navigate(`/${bodyString}`)
    fetchCards(body)
        // try {
        //   const searchResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vmultireisen/flight-searchs/`,
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json",
        //         "AccessToken": localStorage.getItem('accessToken')
        //       },
        //       body: JSON.stringify(body),
        //     }
        //   );
    
        //   if (searchResponse.ok) {
        //     const result = await searchResponse.json();
        //     setFlightOffers(result.data.entities);
        //     localStorage.setItem("ApiAccessToken", result.accessToken);
        //     const SeatsCount =  { adult : adultCount , child : childCount , infant : infantCount}
        //     localStorage.setItem('SeatsCount' , JSON.stringify(SeatsCount))
        //     console.log("Flight search results:", result.data.entities);
    
        //   } else {
        //     console.error("Error searching flights:", searchResponse.statusText);
        //   }
        // } catch (error) {
        //   console.error("Error searching flights:", error);
        // } finally {
        //   setLoading(false);
        //   // setFlightOffers(searchResults?.data?.entities)
        // }
      }

      console.log('Access token stored in local storage');
    } catch (error) {
      console.error('Error fetching the token:', error);
    }
  };

  const addMultiCitySegment = () => {
    setMultiCitySegments([
      ...multiCitySegments,
      { departure: "", arrival: "", date: getCurrentDate() },
    ]);
  };

  const decreaseCount = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (type) {
      case "adult":
        if (adultCount > 1) setAdultCount(adultCount - 1);
        break;
      case "child":
        if (childCount > 0) setChildCount(childCount - 1);
        break;
      case "infant":
        if (infantCount > 0) setInfantCount(infantCount - 1);
        break;
      default:
        break;
    }
  };

  const increaseCount = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (type) {
      case "adult":
        if (adultCount < 3) setAdultCount(adultCount + 1);
        break;
      case "child":
        if (childCount < 3) setChildCount(childCount + 1);
        break;
      case "infant":
        if (infantCount < 3) setInfantCount(infantCount + 1);
        break;
      default:
        break;
    }
  };

  const handleFlightTypeChange = (e) => {
    setFlightType(e.target.value);
  };

  const handleDepartureQueryChange = (e) => {
    setDepartureQuery(e.target.value);
    setShowDepartureSuggestions(true);
  };

  const handleArrivalQueryChange = (e) => {
    setArrivalQuery(e.target.value);
    setShowArrivalSuggestions(true);
  };

  const selectDeparture = (airport) => {
    setDepartureQuery(airport);
    setShowDepartureSuggestions(false);
  };

  const selectArrival = (airport) => {
    setArrivalQuery(airport);
    setShowArrivalSuggestions(false);
  };

  const handleDepartureBlur = (e) => {
    setTimeout(() => {
      setShowDepartureSuggestions(false);
    }, 100);
  };

  const handleArrivalBlur = (e) => {
    setTimeout(() => {
      setShowArrivalSuggestions(false);
    }, 100);
  };

  const handleDepartureMouseDown = (e) => {
    e.preventDefault();
    selectDeparture(e.target.innerText);
  };

  const handleArrivalMouseDown = (e) => {
    e.preventDefault();
    selectArrival(e.target.innerText);
  };

  const totalPassengers = adultCount + childCount + infantCount;

  

  return (
    <div>
      <section className="bg-dark search-form-section pt-lg-5 pb-lg-5">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
          <div className="search-form">
            <p className="h2 text-white font-weight-600 mb-3">
              Search for Flights
            </p>
            <p className="lead h5 text-white font-weight-400">
              Find the best and most affordable flights across the globe
            </p>
            <form id="flightSearch" className="mt-4" onSubmit={handleSubmit}>
              <div className="row gx-2 gy-3 position-relative">
                <div className="col-md-6 mb-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input bg-transparent border border-white"
                      type="radio"
                      name="type"
                      id="one_way"
                      value="oneWay"
                      checked={flightType === "oneWay"}
                      onChange={handleFlightTypeChange}
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="one_way"
                    >
                      One Way
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input bg-transparent border border-white"
                      type="radio"
                      name="type"
                      id="roundway"
                      value="roundTrip"
                      checked={flightType === "roundTrip"}
                      onChange={handleFlightTypeChange}
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="roundway"
                    >
                      Round Trip
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input bg-transparent border border-white"
                      type="radio"
                      name="type"
                      id="multicity"
                      value="multiCity"
                      checked={flightType === "multiCity"}
                      onChange={handleFlightTypeChange}
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="multicity"
                    >
                      Multi-City
                    </label>
                  </div>
                </div>
              </div>

              {/* One Way Form */}
              {flightType === "oneWay" && (
                <div className="row gx-2 gy-3 mb-3 position-relative">
                  <div className="col-lg-4 col-md-6">
                    <div className="input-group position-relative">
                      <span className="input-group-text">
                        <img src={arrival} alt="" />
                      </span>

                      <input
                        name="departureOneWay"
                        type="text"
                        className="form-control departure-icon"
                        placeholder="Departure"
                        aria-controls="listbox-null"
                        required
                        value={departureQuery}
                        onChange={handleDepartureQueryChange}
                        onFocus={() => setShowDepartureSuggestions(true)}
                        onBlur={handleDepartureBlur}
                      />
                      {showDepartureSuggestions && (
                        <div
                          className="suggestions-container"
                          onMouseDown={handleDepartureMouseDown}
                        >
                          <AirportData
                            query={departureQuery}
                            onSelect={selectDeparture}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="input-group position-relative">
                      <span className="input-group-text">
                        <img src={departure} alt="" />
                      </span>
                      <input
                        name="arrivalOneWay"
                        type="text"
                        className="form-control arrival-icon"
                        placeholder="Arrival"
                        aria-controls="listbox-null"
                        required
                        value={arrivalQuery}
                        onChange={handleArrivalQueryChange}
                        onFocus={() => setShowArrivalSuggestions(true)}
                        onBlur={handleArrivalBlur}
                      />
                      {showArrivalSuggestions && (
                        <div
                          className="suggestions-container"
                          onMouseDown={handleArrivalMouseDown}
                        >
                          <AirportData
                            query={arrivalQuery}
                            onSelect={selectArrival}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="input-group">
                      <span className="input-group-text">
                        <img src={DatePickerIcon} alt="" />
                      </span>
                      <DatePicker
                        selected={departureDate}
                        onChange={(date) => setDepartureDate(date)}
                        placeholderText="Departure Date"
                        className="form-control arrival-icon"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Round Trip Form */}
              {flightType === "roundTrip" && (
                <div>
                  <div className="row gx-2 gy-3 mb-3 position-relative">
                    <div className="col-lg-3 col-md-6">
                      <div className="input-group position-relative">
                        <span className="input-group-text">
                          <img src={arrival} alt="" />
                        </span>
                        <input
                          name="departureRoundTrip"
                          type="text"
                          className="form-control departure-icon"
                          placeholder="Departure"
                          aria-controls="listbox-null"
                          required
                          value={departureQuery}
                          onChange={handleDepartureQueryChange}
                          onFocus={() => setShowDepartureSuggestions(true)}
                          onBlur={handleDepartureBlur}
                        />
                        {showDepartureSuggestions && (
                          <div
                            className="suggestions-container"
                            onMouseDown={handleDepartureMouseDown}
                          >
                            <AirportData
                              query={departureQuery}
                              onSelect={selectDeparture}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="input-group position-relative">
                        <span className="input-group-text">
                          <img src={departure} alt="" />
                        </span>
                        <input
                          name="arrivalRoundTrip"
                          type="text"
                          className="form-control arrival-icon"
                          placeholder="Arrival"
                          aria-controls="listbox-null"
                          required
                          value={arrivalQuery}
                          onChange={handleArrivalQueryChange}
                          onFocus={() => setShowArrivalSuggestions(true)}
                          onBlur={handleArrivalBlur}
                        />
                        {showArrivalSuggestions && (
                          <div
                            className="suggestions-container"
                            onMouseDown={handleArrivalMouseDown}
                          >
                            <AirportData
                              query={arrivalQuery}
                              onSelect={selectArrival}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">
                          <img src={DatePickerIcon} alt="" />
                        </span>
                        <DatePicker
                          selected={departureDate}
                          onChange={(date) => setDepartureDate(date)}
                          placeholderText="Departure Date"
                          className="form-control arrival-icon"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">
                          <img src={DatePickerIcon} alt="" />
                        </span>
                        <DatePicker
                          selected={arrivalDate}
                          onChange={(date) => setArrivalDate(date)}
                          placeholderText="Arrival Date"
                          className="form-control arrival-icon"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Multi-City Form */}
              {flightType === "multiCity" && (
                <div>
                  {multiCitySegments.map((segment, index) => (
                    <div
                      className="row gx-2 gy-3 mb-3 position-relative"
                      key={index}
                    >
                      <div className="col-lg-4 col-md-6">
                        <div className="input-group position-relative">
                          <span className="input-group-text">
                            <img src={arrival} alt="" />
                          </span>
                          <input
                            name={`departureMultiCity${index + 1}`}
                            type="text"
                            className="form-control"
                            placeholder="Departure"
                            aria-controls="listbox-null"
                            required
                            value={segment.departure}
                            onChange={(e) =>
                              handleMultiCityChange(
                                index,
                                "departure",
                                e.target.value
                              )
                            }
                            onFocus={() => setShowDepartureSuggestions(true)}
                            onBlur={handleDepartureBlur}
                          />
                          {showDepartureSuggestions && (
                            <div
                              className="suggestions-container"
                              onMouseDown={handleDepartureMouseDown}
                            >
                              <AirportData
                                query={segment.departure}
                                onSelect={(airport) =>
                                  handleMultiCityChange(
                                    index,
                                    "departure",
                                    airport
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="input-group position-relative">
                          <span className="input-group-text">
                            <img src={departure} alt="" />
                          </span>
                          <input
                            name={`arrivalMultiCity${index + 1}`}
                            type="text"
                            className="form-control arrival-icon"
                            placeholder="Arrival"
                            aria-controls="listbox-null"
                            required
                            value={segment.arrival}
                            onChange={(e) =>
                              handleMultiCityChange(
                                index,
                                "arrival",
                                e.target.value
                              )
                            }
                            onFocus={() => setShowArrivalSuggestions(true)}
                            onBlur={handleArrivalBlur}
                          />
                          {showArrivalSuggestions && (
                            <div
                              className="suggestions-container"
                              onMouseDown={handleArrivalMouseDown}
                            >
                              <AirportData
                                query={segment.arrival}
                                onSelect={(airport) =>
                                  handleMultiCityChange(
                                    index,
                                    "arrival",
                                    airport
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6" id="departure-date">
                        <div className="input-group position-relative">
                          <input
                            type="date"
                            className="form-control date-icon"
                            placeholder="Departure Date"
                            required
                            autoComplete="off"
                            value={segment.date}
                            onClick={(e) => e.target.showPicker()}
                            onChange={(e) =>
                              handleMultiCityChange(
                                index,
                                "date",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary text-white w-20 btn-clr"
                    onClick={addMultiCitySegment}
                  >
                    Add City
                  </button>
                </div>
              )}

              {/* Departure, Arrival, Date, etc. Fields */}
              <div className="row gx-2 gy-3">
                <div className="col-lg-5 col-md-6">
                  <div className="dropdown">
                    {/* Passengers input */}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="No of Passengers"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      value={`Total Passengers: ${totalPassengers}`}
                    />
                    <div className="dropdown-menu p-3 w-100" id="passengers">
                      {/* Passengers dropdown */}
                      <div className="row g-3 align-items-center">
                        {/* Adults */}
                        <div className="col-4">
                          <label htmlFor="adult" className="mb-0">
                            Adult(s)
                          </label>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={(e) => decreaseCount("adult", e)}
                          >
                            <i className="fa fa-minus text-gray-500"></i>
                          </button>
                          <input
                            type="number"
                            className="form-control text-center w-25"
                            id="adultCount"
                            value={adultCount}
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={(e) => increaseCount("adult", e)}
                          >
                            <i className="fa fa-plus text-gray-500"></i>
                          </button>
                        </div>

                        {/* Children */}
                        <div className="col-4">
                          <label htmlFor="child" className="mb-0">
                            Children
                          </label>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            disabled={childCount === 0}
                            onClick={(e) => decreaseCount("child", e)}
                          >
                            <i className="fa fa-minus text-gray-500"></i>
                          </button>
                          <input
                            type="number"
                            className="form-control text-center w-25"
                            id="childCount"
                            max="3"
                            value={childCount}
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={(e) => increaseCount("child", e)}
                          >
                            <i className="fa fa-plus text-gray-500"></i>
                          </button>
                        </div>

                        {/* Infants */}
                        <div className="col-4">
                          <label htmlFor="infant" className="mb-0">
                            Infant(s)
                          </label>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            disabled={infantCount === 0}
                            onClick={(e) => decreaseCount("infant", e)}
                          >
                            <i className="fa fa-minus text-gray-500"></i>
                          </button>
                          <input
                            type="number"
                            className="form-control text-center w-25"
                            id="infantCount"
                            max="3"
                            value={infantCount}
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={(e) => increaseCount("infant", e)}
                          >
                            <i className="fa fa-plus text-gray-500"></i>
                          </button>
                        </div>

                        {/* Done Button */}
                        <div className="col-12">
                          <button className="btn btn-primary w-100 btn-clr">
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  {/* Flight class selector */}
                  <select
                    className="form-control"
                    id="class"
                    defaultValue="ECONOMY"
                  >
                    <option value="">Class</option>
                    <option value="ECONOMY">ECONOMY</option>
                    <option value="PremiumEconomy">Premium Economy</option>
                    <option value="Business">Business</option>
                  </select>
                </div>
                <div className="col-md-12 col-lg-2">
                  <button
                    type="submit"
                    className="btn btn-secondary text-white w-100 btn-clr"
                  >
                    <i className="bi bi-search pe-2"></i>
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {loading ? (
        <Loader />
      ) : (
        <FlightOffersSection flightOffers={flightOffers} />
      )}
    </div>
  );
}

export default FlightSearchFormCopy;
