// import React from 'react';
// import Card from './Card';
// import image_1 from "../assets/imgs/AnnoucCardImg.jpg"

// const CardList = () => {
//   const cardsData = [
//     {
//       id: 1,
//       title: 'Ticketistan X Roots Millennium',
//       description: 'Discover innovative ways we can work together to bring vibrant...',
//       imageUrl: 'https://example.com/image1.jpg'
//     },
//     {
//       id: 2,
//       title: 'Ticketistan X Roots Millennium',
//       description: 'Another milestone as we work day and night to bring a discount...',
//       imageUrl: 'https://example.com/image2.jpg'
//     },
//     {
//       id: 3,
//       title: 'Ticketistan X Roots Millennium',
//       description: 'Yet another milestone on our works on the education sector in Pakistan...',
//       imageUrl: 'tikestan/src/assets/imgs/AnnoucCardImg.jpg'
//     },
//     {
//         id: 4,
//         title: 'Ticketistan X Roots Millennium',
//         description: 'Yet another milestone on our works on the education sector in Pakistan...',
//         imageUrl: 'https://example.com/image3.jpg'
//       },
//       {
//         id: 5,
//         title: 'Ticketistan X Roots Millennium',
//         description: 'Yet another milestone on our works on the education sector in Pakistan...',
//         imageUrl: 'https://example.com/image3.jpg'
//       },
//     // Add more card data as needed
//   ];

//   return (
//     <div className="container mt-4 px-3 px-md-4 px-lg-5">
//       <div className="row">
//         {cardsData.map(card => (
//           <Card key={card.id} title={card.title} description={card.description} imageUrl={card.imageUrl} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CardList;


// CardList.js
import React from 'react';
import Card from './Card';
import image from '../assets/imgs/AnnoucCardImg.jpg'

const CardList = () => {
  const cardsData = [
    {
      id: 1,
      title: 'Ticketistan X Roots Millennium',
      description: 'Yet another milestone as we work day and night to bring a discounted travel revolution across the Education Sector in Pakistan. Bookme signs another companionship with one of the leading private education systems in Pakistan.',
      imageUrl: 'https://via.placeholder.com/150'
    },
    {
      id: 2,
      title: 'Ticketistan X Roots Millennium',
      description: 'Yet another milestone as we work day and night to bring a discounted travel revolution across the Education Sector in Pakistan. Bookme signs another companionship with one of the leading private education systems in Pakistan.',
      imageUrl: 'https://via.placeholder.com/150'
    },
    {
      id: 3,
      title: 'Ticketistan X Roots Millennium',
      description: 'Yet another milestone on our works on the education...',
      imageUrl: 'https://via.placeholder.com/150'
    },
    {
      id: 4,
      title: 'Ticketistan X Roots Millennium',
      description: 'Celebrating milestones and enhancing partnerships...',
      imageUrl: 'https://via.placeholder.com/150'
    },
    {
        id: 5,
        title: 'Ticketistan X Roots Millennium',
        description: 'Celebrating milestones and enhancing partnerships...',
        imageUrl: 'https://via.placeholder.com/150'
      },
      {
        id: 6,
        title: 'Ticketistan X Roots Millennium',
        description: 'Celebrating milestones and enhancing partnerships...',
        imageUrl: 'https://via.placeholder.com/150'
      },
    // Add more card data as needed
  ];

  return (
    <div className="container mt-4">
      <div className="row">
        {cardsData.map(card => (
          <Card key={card.id} imageUrl={card.imageUrl} title={card.title} description={card.description} />
        ))}
      </div>
    </div>
  );
};

export default CardList;
