import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"

const AuthRoute = ({ children }) => {

    const redirectUrl = localStorage.getItem("redirectUrl")
    // const isAuthenticated = localStorage.getItem("token")
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated )
    const navigate = useNavigate()

    if (isAuthenticated) {
        window.location.href = redirectUrl || "/";
        return null; // Ensure nothing is rendered while redirecting
    }

  return <>{children}</> ;
};

export default AuthRoute;
