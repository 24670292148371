// import React from 'react';

// const Card = ({ title, description, imageUrl }) => {
//   return (
    
        
          
//     <div className="col-md-4 mb-4 d-flex">
//       <div className="card">
//         <img src={imageUrl} className="card-img-top" alt={title} />
//         <div className="card-body">
//           <h5 className="card-title">{title}</h5>
//           <p className="card-text">{description}</p>
//           <a href="#" className="btn btn-primary">Read post</a>
//         </div>
//       </div>
//     </div>
    
   
//   );
// };

// export default Card;


// Card.js
import React from 'react';

const Card = ({ imageUrl, title, description }) => {
  return (
    <div className="col-md-6 mb-4">
      <div className="card">
        <img src={imageUrl} className="card-img-top" alt="Post" />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
          <a href="#" >Read post</a>
        </div>
      </div>
    </div>
  );
};

export default Card;
