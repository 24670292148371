import { createSlice } from "@reduxjs/toolkit";

import {
  destroyToken,
  getToken,
  getUser,
  saveToken,
  saveUser,
} from "./authService";
// import ApiService from "../../services/apiService";

const token = getToken();
// token && ApiService.setAuthToken(token);
const userData = getUser();
const initialState = {
  user: userData,
  isAuthenticated: token ? true : false,
  // accessToken: userData?.accessToken || '',
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    LOGIN: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      saveUser(action.payload);
      // accessToken: action.payload?.access_token,
    //   ApiService.setAuthToken(action.payload?.token);
      saveToken(action.payload?.access);
    },
    LOGOUT: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    //   ApiService.setHeader("Authorization", "");
      destroyToken();
    },
    REGISTER: (state, action) => {
      state.isLoading = false;
      state.apiError = null;
      state.user = action.payload;
    },
  },
});
export const { LOGIN, LOGOUT, REGISTER } = authSlice.actions;
export default authSlice.reducer;