import React from 'react'

const FaqHotel = () => {
  return (
    <>
    <section>
        <div className="container px-3 mb-5 px-md-4 px-lg-5 ">
          <p className="h2 fs-3 font-weight-600 mb-4">
            Frequently Asked Questions
          </p>

          <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Why Ticketistan.com ?
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        We are Pakistan's no.1 E-ticketing platform. We let you pre-book the best deals on your tickets for Movies, Bus Travel, Flights, Train journey and Events. You no longer have to stand in lines and can purchase your tickets from the comfort of your ‘phones’ by using a variety of digital payment methods. Get your ticket confirmations instantly via SMS and Email.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        What information will I be able to see during my ticket purchase?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        Using Ticketistan to purchase your tickets, you will be able to view:
        <ul>
          <li>The timings and schedules for the occasion or journey you’re trying to book.</li>
          <li>Availability and seating plans so you know exactly what you’re purchasing and that you’re paying the best price for it.</li>
          <li>A set of options for you to lock your ticket and pay using your preferred digital payment method.</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="false"
        aria-controls="collapseThree"
      >
        How do I know if my purchase was successful?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Upon successful completion of your ticket purchase, you will be directed to an order confirmation page which will contain an order confirmation number. With a few minutes of your purchase you will receive confirmation Email that will contain summary of your order along with E-ticket. 
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFour"
        aria-expanded="false"
        aria-controls="collapseFour"
      >
        Can I choose my own seat? 
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Absolutely! The initial step for ticket booking will allow you to view the available seats. You will be able to select from these available options and book your seat immediately. 
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseFive"
        aria-expanded="false"
        aria-controls="collapseFive"
      >
        How can I purchase E-Tickets once I have selected my seats? 
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      You can purchase E-Tickets using a variety of popular online payment methods mentioned below: 
      <ul>
        <li>Debit/Credit Cards (Local &amp; International)</li>
        <li>Easypaisa</li>
        <li>Jazz Cash</li>
        <li>Bank Transfer</li>
        </ul>
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSix"
        aria-expanded="false"
        aria-controls="collapseSix"
      >
        If I have a query regarding my E- Ticket, who can I contact for assistance? 
      </button>
    </h2>
    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Our Customer Service support is available 24/7 via phone (042-111-266563) and email support (contact@bookme.pk). 
      </div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseSeven"
        aria-expanded="false"
        aria-controls="collapseSeven"
      >
         What if I do not receive my confirmation email? 
      </button>
    </h2>
    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      If for some reason you did not receive your confirmation email, please check your “junk” and/or “spam” folder. In case there is another issue, please feel free to contact our 24/7 support (0304 111 0636, contact@ticketistan) 
      </div>
    </div>
  </div>   

<div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseEight"
        aria-expanded="false"
        aria-controls="collapseEight"
      >
         What are the requirements for booking a ticket? 
      </button>
    </h2>
    <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      After you have selected your desired seat/booking you may be required to fill in some details for verification and validation such as, Name, Phone number, Email and CNIC. 
      </div>
    </div>
  </div>


 
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseNine"
        aria-expanded="false"
        aria-controls="collapseNine"
      >
        What are the rewards for using Ticketistan.com? 
      </button>
    </h2>
    <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Apart from being able to easily pre-book your desired seat and ticket within a few clicks sitting at home with our platform, there are a couple of more exciting rewards that come with using the Bookme app, such as: 
      <ul>
        <li>You will get redeemable points on your bookings.</li>
        <li>Send your referral code to your friends to win exciting prizes.</li>
        <li>Achieve your milestones and claim gifts and prizes.</li>
        <li>Win exciting badges on your bookings.</li></ul>
      </div>
    </div>
  </div>


  
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTen"
        aria-expanded="false"
        aria-controls="collapseTen"
      >
         What if I have forgotten my password? 
      </button>
    </h2>
    <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      If you forget your password, just click on "Forgot Password" and provide your registered email address. An Email will be sent to you with an option to reset your password. 
      </div>
    </div>
  </div>
</div>
</div>

      </section>
    </>
  )
}

export default FaqHotel