import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import whyuse1 from "../Components/assets/icons/whyUseIcon1.png";
import whyuse2 from "../Components/assets/icons/whyUseIcon2.png";
import whyuse3 from "../Components/assets/icons/whyUseIcon3.png";
import MobileApp from "../Components/MobileApp";
import FaqHotel from "../Components/hotel/FaqHotel.jsx";
import TrainSearchForm from "../Components/TrainSearchForm.jsx";
import { Container } from "react-bootstrap";
const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const Trains = () => {
  // const [showForm, setShowForm] = useState(true);
  // const [showMultiCityForm, setShowMultiCityForm] = useState(false);
  // const [departureOptions, setDepartureOptions] = useState([]);
  // const [loadingDepartures, setLoadingDepartures] = useState(true);

  // useEffect(() => {
  //   // Fetch departure options when component mounts
  //   fetchDepartureOptions();
  // }, []);

  // const fetchDepartureOptions = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/flight/airports?`); // Replace "https://api.example.com/departures" with your actual API endpoint
  //     setDepartureOptions(response.data);
  //     setLoadingDepartures(false);
  //   } catch (error) {
  //     console.error("Error fetching departure options:", error);
  //     setLoadingDepartures(false);
  //   }
  // };
 

  return (
    <>
       <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        backgroundColor: "#282c34",
        color: "white",
        fontSize: "3rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          animation: "pulse 2s infinite",
          margin: 0,
        }}
      >
        Launching Soon
      </h1>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
              opacity: 1;
            }
            50% {
              transform: scale(1.05);
              opacity: 0.8;
            }
            100% {
              transform: scale(1);
              opacity: 1;
            }
          }

          h1 {
            animation: pulse 2s infinite;
          }
        `}
      </style>
    </Container>
     
          {/* <TrainSearchForm/>
      
      
      <section className="quick-links-section pt-5 my-5 mt-0 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5 ">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="#mobile-app ">
                  <div className="d-flex align-items-center ">
                    <div className="side-icon">
                      <img src={icon1} />
                    </div>
                    <div className="content ms-3 ">
                      <h5>Download Ticketistan App</h5>
                      <p className="mb-0">Great detais on our mobile app</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/contact-us">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon2} />
                    </div>
                    <div className="content ms-3">
                      <h5>Help Center</h5>
                      <p className="mb-0">Search on mobile apps with ease</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/user/bookings">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon3} />
                    </div>
                    <div className="content ms-3">
                      <h5>Manage Bookings</h5>
                      <p className="mb-0">Explore and bookings hassel-frees</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="travel-partners-section">
        <div className="container pt-md-4 pb-lg-5 pt-lg-0 px-3 px-md-4 px-lg-5 ">
          <p className="h2 fs-3 custom-font-bold mb-1">Train Timings</p>
          <div className="row trains_timming">
            <div className="col-lg-6 col-sm-12">115Up - Musa Pak Express ( Multan Cantt - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">214 Down Mohenjo Daro ( Rohri Jn. - Kotri Jn. )</div>
            <div className="col-lg-6 col-sm-12">213 Up Mohenjo Daro ( Kotri Jn. - Rohri Jn. )</div>
            <div className="col-lg-6 col-sm-12">7Up - Tezgam ( Karachi Cantt - Rawalpindi )</div>
            <div className="col-lg-6 col-sm-12">8Dn - Tezgam ( Rawalpindi - Karachi Cantt )</div>
            <div className="col-lg-6 col-sm-12">103Up - Subak Kharam ( Lahore Jn. - Rawalpindi )</div>
            <div className="col-lg-6 col-sm-12">104Dn - Subak Kharam ( Rawalpindi - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">27Up - Shalimar Express ( Karachi Cantt - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">28Dn - Shalimar Express ( Lahore Jn. - Karachi Cantt )</div>
            <div className="col-lg-6 col-sm-12">105Up - Rawal Express ( Lahore Jn. - Rawalpindi )</div>
            <div className="col-lg-6 col-sm-12">106Dn - Rawal Express ( Rawalpindi - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">37 Up (Fareed Express) ( Karachi City - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">116Dn - Musa Pak Express ( Lahore Jn. - Multan Cantt )</div>
            <div className="col-lg-6 col-sm-12">41Up - Karakoram Express ( Karachi Cantt - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">42Dn - Karakoram Express ( Lahore Jn. - Karachi Cantt )</div>
            <div className="col-lg-6 col-sm-12">15Up - Karachi Express ( Karachi Cantt - Lahore Jn. )</div>
            <div className="col-lg-6 col-sm-12">16Dn - Karachi Express ( Lahore Jn. - Karachi Cantt )</div>
            <div className="col-lg-6 col-sm-12">152 Down Shah Latif ( Mirpur Khas - Karachi City )</div>
            <div className="col-lg-6 col-sm-12">151 Up Shah Latif ( Karachi City - Mirpur Khas )</div>
            <div className="col-lg-6 col-sm-12">128 Down(Mehar Express) ( Rawalpindi - Multan Cantt )</div>
            <div className="col-lg-6 col-sm-12">127 Up(Mehar Express) ( Multan Cantt - Rawalpindi )</div>
            <div className="col-lg-6 col-sm-12">38 Down (Fareed Express) ( Lahore Jn. - Karachi City )</div>
          </div>
          </div>
      </section>

      <section className="why-use-ticketestan bg-blue mb-5">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <p className="h2 fs-3 custom-font-bold  mb-1 mt-3">
                Why Use Ticketistan?
              </p>
              <p className="lead h5 custom-font-normal ">
                We furnish ample of deals in Pakistan.
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-center mt-2">
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse1} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse2} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse3} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Convenient mb-5">
    <div className="container py-lg-4 px-lg-3 px-md-4 px-lg-5">
    <div className="Convenient-bg px-3 ">
        <div className="row">
            <div className="col-lg-6 col-sm-12 Convenient-col d-flex flex-column justify-content-center pe-5">
                <h2>Convenient Train Bookings</h2>
                <p>Train travel was not so easy before now at your door steps. Ticketestan offers a simple and easy way to book your train tickets. Enjoy book procedure, reliable schedules, and well verse customer service. Now you ready for your journey and booking.</p>
            </div>
            <div className="col-6"></div>
        </div>
    </div>
    </div>
</section>
  
     <MobileApp/>


     <section className="top-route-section pb-5">
        <div className="container py-4 px-3 px-md-4 px-lg-5">
          <div className="border-top border-bottom">
            <div className="accordion accordion-flush" id="route">
              <div className="accordion-item">
                <h2 className="accordion-header" id="route-headingOne">
                  <button
                    className="accordion-button px-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#route-collapseOne"
                    aria-expanded="false"
                    aria-controls="route-collapseOne"
                  >
                    <h4 className="font-weight-600 text-capitalize">
                    Ticketestan railway Services
                    </h4>
                  </button>
                </h2>
                <div
                  id="route-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="route-headingOne"
                  data-bs-parent="#route"
                >
                  <div className="accordion-body px-0">
                    <ul className="row gy-3 list-unstyled">
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Lahore to Rawalpindi Train
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Peshawar to Karachi Train 
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rawalpindi to Lahore Train
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Karachi to Peshawar Train
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rehman Baba Express
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Fareed Express
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Subak Kharam
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Rawal Express
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Mehr Express
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Faiz Ahmad Faiz Express Train
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Lahore to Multan Train Timing
                        </a>
                      </li>
                      <li className="col-6 col-md-4 col-lg-3">
                        <a href="" className="text-primary">
                        Multan to Rawalpindi Train Timing
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      

     <div className="pt-lg-5">
     <FaqHotel/>
     </div>
      <section className="instruction">
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
        <div class="row">
          <div class="col-12 p-0"> <h2 class="mb-4 fs-5 custom-font-bold">How to Book Your Train Online Through Ticketistan?</h2>
            <p className="fclr">Visit train section on our web or mobile app.</p>
            <p className="fclr">Choose departure and arrival with date.</p>
            <p className="fclr">Choose seats your wishful class.</p>
            <p className="fclr">Provide your contact details.</p>
            <p className="fclr">Make payment with your desired method and confirm your booking. </p>
         </div>
       </div>
      </div>
      </div>
      </section> */}
    </>
  );
};

export default Trains;
