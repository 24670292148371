import { FaPlane, FaBus, FaTrain, FaFilm, FaCar, FaHotel, FaCalendarAlt, FaMapSigns } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import './Sidebar.css'


export const menuItem=[
    {
        path:"/flight",
        name:"Flight",
        icon:<FaPlane/>
    },
    {
        path:"/hotel",
        name:"Hotel",
        icon:<FaHotel/>
    },
    {
        path:"/bus",
        name:"Bus",
        icon:<FaBus/>
    },
    {
        path:"/trains",
        name:"Trains",
        icon:<FaTrain/>
    },
    {
        path:"/movies",
        name:"Movies",
        icon:<FaFilm/>
    },
    {
        path:"/carRental",
        name:"CarRental",
        icon:<FaCar/>
    },
    
    {
        path:"/events",
        name:"Events",
        icon:<FaCalendarAlt/>
    },
    {
        path:"/tours",
        name:"Holidays",
        icon:<FaMapSigns/>
    }
]


const Sidebar = ({children,isOpen}) => {  
  const handleClick = () => {
    window.scrollTo(0, 0);
};
  return (
    <div className='container1'>
         <div className='container1'>
        <div style={{ width: isOpen ? "250px" : "80px" }} className='sidebar d-none d-sm-block'>
          {menuItem.map((item, index) => (
            <NavLink to={item.path} key={index} className="link" activeClassName="active" onClick={handleClick}>
              <div className='icon'>{item.icon}</div>
              <div style={{ display: isOpen ? "block" : "none" }} className='link_text'>{item.name}</div>
            </NavLink>
          ))}
        </div>
      </div>
        <main style={{marginLeft: isOpen ? '' : "80px"}}>{children}</main>
    </div>
  )
}


export default Sidebar

