import React, { useState } from 'react';
import HandshakeForm from './HandshakeForm';
import TransactionForm from './TransactionForm';

const PaymentPage = () => {
    const [handshakeData, setHandshakeData] = useState(null);

    const handleHandshakeSuccess = (data) => {
        setHandshakeData(data);
    };

    return (
        <div>
            {!handshakeData ? (
                <HandshakeForm onHandshakeSuccess={handleHandshakeSuccess} />
            ) : (
                <TransactionForm handshakeData={handshakeData} />
            )}
        </div>
    );
};

export default PaymentPage;




// import React, { useState } from 'react';
// import HandshakeForm from './HandshakeForm';
// import TransactionForm from './TransactionForm';

// const PaymentPage = () => {
//     const [handshakeData, setHandshakeData] = useState(null);

//     const handleHandshakeSuccess = (data) => {
//         setHandshakeData(data);
//     };

//     return (
//         <div>
//             {!handshakeData ? (
//                 <HandshakeForm onHandshakeSuccess={handleHandshakeSuccess} />
//             ) : (
//                 <TransactionForm handshakeData={handshakeData} />
//             )}
//         </div>
//     );
// };

// export default PaymentPage;