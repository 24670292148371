import React, { useState } from "react";
import "./FlightCard.css";
import "./FlightOffersSection.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const FlightCardCopy = ({ flightOffer, inReviewPage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showStops, setShowStops] = useState(false);
  const { flightId } = useParams();
  const [flightCardDetails, setFlightCardDetails] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  if (!flightOffer) {
    return null;
  }

  const {
    price: { currency, total },
    segments,
    supplierInfo,
    optionalServices
  } = flightOffer;

  const totalStops = segments.length - 1;

  const handleToggleStops = () => {
    setShowStops(!showStops);
  };

  const renderStopDetails = () => {
    return segments.map((segment, index) => {
      if (index === 0) return null; // Skip the first segment as it's the departure
      const {
        origin: {
          name: originName,
          code: originCode,
          date: originDate,
          time: originTime,
        },
        destination: {
          name: destinationName,
          code: destinationCode,
          date: destinationDate,
          time: destinationTime,
        },
      } = segment;

      return (
        <div key={index} className="stopDetails">
          <p>
            Stop {index}: {originName} ({originCode}) to {destinationName} (
            {destinationCode})
          </p>
          <p>
            Departure: {originDate} {originTime}
          </p>
          <p>
            Arrival: {destinationDate} {destinationTime}
          </p>
        </div>
      );
    });
  };

  const {
    carrier: { name: carrierName, flightNumber },
    origin: {
      name: originName,
      code: originCode,
      date: originDate,
      time: originTime,
    },
    // destination: { name: destinationName, code: destinationCode, date: destinationDate, time: destinationTime, terminal },
    // baggageAllowance: { amount: baggageAmount, unitName: baggageUnit }, // TODO::have to unComment
    baggageAllowance: {
      amount: baggageAmount = 0,
      unitName: baggageUnit = "",
    } = {},

    class: { name: className, availableSeats: seats },
  } = segments[0]; // Assuming you display details for the first segment

  const destinationName = segments[segments.length - 1]?.destination?.name;
  const destinationCode = segments[segments.length - 1]?.destination?.code;
  const destinationDate = segments[segments.length - 1]?.destination?.date;
  const destinationTime = segments[segments.length - 1]?.destination?.time;
  const terminal = segments[segments.length - 1]?.destination?.terminal;

  const img =
    "https://1000logos.net/wp-content/uploads/2023/05/Airblue-Logo-thumb.png";

  const buttonInDetails = [
    { name: "Itinerary" },
    { name: "Baggage" },
    { name: "Cancelation" },
    { name: "Date Change" },
  ];

  const firstOption =optionalServices[0].options[0];
  const maxWeight = firstOption.maxweight;
  const price = firstOption.price;

  const [selectedDetailBtn, setSelectedDetailBtn] = useState("Itinerary");

  return (
    <>
   
      <section className="my-5 mt-0 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5">
          <div className="flightCard">
            {/* CARD STARTS HERE */}
            <div
              className="cards"
              style={{
                borderBottomLeftRadius: showStops || showDetails ? 0 : "",
                borderBottomRightRadius: showStops || showDetails ? 0 : "",
              }}
            >
              {/* img section */}
              <div className="flightLogo">
                <img
                  src={img}
                  style={{ maxWidth: "80%", maxHeight: "70px" }}
                  alt="Airline Logo"
                />
                <div className="flightName">{carrierName}</div>
                <div className="flightNumber">{flightNumber}</div>
              </div>
              {/* mid section */}
              <div className="midSection" style={{ position: "relative" }}>
                <div className="originTime">
                  {originTime} <span className="totalTime">7h 5m</span>{" "}
                  {destinationTime}{" "}
                  <span
                    style={{
                      marginTop: "-25px",
                      color: "#00A7E1",
                      fontSize: "13px",
                      padding: "5px",
                    }}
                  >
                    + 1D
                  </span>
                </div>
                <div className="originName">
                  {originName}&nbsp;- {originCode}
                  <span
                    style={{
                      color: "#00A7E1",
                      borderBottom: "1px solid #00A7E1",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={handleToggleStops}
                  >
                    {totalStops} Stop{totalStops > 1 ? "s" : ""}
                  </span>{" "}
                  -&nbsp;&nbsp;&nbsp; {destinationName}&nbsp;- {destinationCode}{" "}
                  {terminal && "Terminal " + terminal}
                </div>
                <div
                  style={{
                    color: "#6D6D6D",
                    fontSize: "14px",
                    marginTop: "5px",
                  }}
                >
                  Baggage Allowance : {maxWeight} {}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{seats} &nbsp;seats Available
                </div>
                <div
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    bottom: "0px",
                    right: "10px",
                  }}
                  onClick={() => setShowDetails(!showDetails)}
                >
                  Details
                </div>
                {/* {showStops && renderStopDetails()} */}
              </div>

              {/* last section */}
              <div className="flightCardLastSection">
                <Link
                  to={
                    inReviewPage
                      ? ""
                      : flightId
                      ? "passenger-details"
                      : `/flight/${flightOffer?.itemId}`
                  }
                  className="flightCardButton"
                  onClick={() => navigate(-1)}
                >
                  Flight Details
                </Link>
              </div>
            </div>
          </div>
          {flightCardDetails && (
            <div>
              {/* flight details */}
              <div>
                {flightOffer?.price.currency}
                {flightOffer?.supplierInfo[0].name} <br />
                {flightOffer?.supplierInfo[0].value}
              </div>
            </div>
          )}
          {/* DETAILS */}
          {showDetails && (
            <div
              style={{
                border: "1px solid #C8C8C8",
                borderTop: "none",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid #C8C8C8",
                  padding: "10px 0px",
                }}
              >
                {buttonInDetails?.map((item, index) => (
                  <div
                    onClick={() => setSelectedDetailBtn(item?.name)}
                    style={{
                      display: "inline-block",
                      width: "25%",
                      textAlign: "center",
                      textDecoration:
                        selectedDetailBtn === item?.name ? "underline" : "",
                      color: selectedDetailBtn === item?.name ? "#00A7E1" : "",
                      cursor: "pointer",
                    }}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>

              {/* first detail section */}
              {selectedDetailBtn === "Itinerary" ? (
                <div style={{ padding: "20px" }}>
                  <div>
                    Thursday, 24th October{" "}
                    <span className="totalTime">7h 5m</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src={img}
                      style={{ maxWidth: "50%", maxHeight: "50px" }}
                      alt="Airline Logo"
                    />
                    <div
                      style={{ display: "inline-block", marginLeft: "10px" }}
                    >
                      {carrierName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    {" "}
                    <div
                      style={{ display: "inline-block", textAlign: "center" }}
                    >
                      <p> {originTime}</p> <p>{originName}</p>{" "}
                    </div>{" "}
                    <span style={{ margin: "0px 20px" }}>--</span>{" "}
                    <div
                      style={{ display: "inline-block", textAlign: "center" }}
                    >
                      <p> {destinationTime}</p> <p>{destinationName}</p>{" "}
                    </div>
                  </div>
                  {/* link section */}
                 {/* Itinerary  */}
                  <div
                    style={{
                      borderLeft: "1px solid black",
                      padding: "0px 10px",
                    }}
                  >
                    <div>Lahore (LHI) to Karachi (KHI)</div>
                    <div>PA-402</div>
                    <div>Economy (Flexi), Air blue</div>
                    <div>Total: 20kg</div>
                  </div>
                 {/* //supplierInfo  */}
                {/* {supplierInfo.map((item, index) => (
                    <li key={index}>
                        <strong>{item.name}:</strong>{' '}
                        {item.value.startsWith('http') ? (
                            <a href={item.value} target="_blank" rel="noopener noreferrer">
                                {item.value}
                            </a>
                        ) : (
                            <span>{item.value}</span>
                        )}
                    </li>
                ))} */}
           
                </div>
              ) : selectedDetailBtn === "Baggage" ? (
                <div className="pt-5 pb-5">
                  
                  
                {/* <h2>Baggage Options</h2> */}
                <div className="ps-2 pe-2">
            <h2>Luggage Options</h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Option</TableCell>
                            <TableCell>Number of Bags</TableCell>
                            <TableCell>Max Weight</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {optionalServices?.[0]?.options?.map((option) => (
                            <TableRow key={option.value}>
                                <TableCell>{option.value}</TableCell>
                                <TableCell>{option.bags}</TableCell>
                                <TableCell>{option.maxweight}</TableCell>
                                <TableCell>{option.price === 0 ? 'Free' : `$${option.price}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
                </div>
              ) : selectedDetailBtn === "Cancelation" ? (
                <div>Cancelation div</div>
              ) : (
                <div>Date and time div</div>
              )}
            </div>
          )}
          {/* STOP CARDS */}
          {showStops && (
            <>
              {segments?.map((segment, index) => (
                <div
                  style={{
                    border: "1px solid #C8C8C8",
                    borderTop: "none",
                    padding: "5px 10px",
                    width: "100%",
                    borderBottomLeftRadius:
                      index === segments?.length - 1 ? "12px" : "",
                    borderBottomRightRadius:
                      index === segments?.length - 1 ? "12px" : "",
                  }}
                >
                  {/* line 1 */}
                  <div
                    style={{
                      width: "fit-content",
                      backgroundColor: "#cce3f7",
                      border: "1px solid #00A7E1",
                      padding: "2px",
                      borderRadius: "5px",
                    }}
                  >
                    {segment.origin.date}
                  </div>
                  {/* line 2 */}
                  <div style={{ display: "flex", width: "75%" }}>
                    {/* colums */}
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>
                        <img src="" style={{ maxWidth: "100%" }} alt="pia" />
                      </div>
                      <div>{segment.carrier.code}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.origin.time}</div>
                      <div>{segment.origin.name}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.destination.time}</div>
                      <div>{segment.destination.name}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>{segment.carrier.name}</div>
                      <div>{segment.carrier.flightNumber}</div>
                    </div>
                    <div style={{ ...stopColumnStyle }}>
                      {/* rows */}
                      <div>Class</div>
                      <div>{segment.class.name}</div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default FlightCardCopy;

const stopColumnStyle = {
  width: "20%",
  textAlign: "center",
};
